export const MUI_COLORS = {
    PRIMARY: "primary",
    SECONDARY: "secondary",
    DEFAULT: "default",
    INHERIT: "inherit",
    SUCCESS: "success",
    ERROR: "error",
}

export const MUI_BTN_VARIANTS = {
    TEXT: "text",
    OUTLINED: "outlined",
    CONTAINED: "contained",
}