import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import SupportOverview from './areas/support/SupportOverview';
// import Dashboard from './Dashboard';
import Login from './Login';
import store from './redux/store';
import { Provider } from 'react-redux';
import Dashboard from './Dashboard';
import UserManagement from './areas/usermanagement/UserManagement';
import OrderManagement from './areas/ordermanagement/OrderManagement';
import PartnerManagement from './areas/partnermanagement/PartnerManagement';
import ActiveCasesBar from './areas/support/Elements/ActiveCaseBottomBar/ActiveCasesBar';
import SupportChatDialog from './areas/support/Elements/SupportChatDialog';
import Test from './areas/Test';
import AlertDialog from './components/AlertDialog';
import Partner from './areas/partnermanagement/Partner';
import Shop from './areas/partnermanagement/Shop';
import ContentManagementView from './areas/partnermanagement/ContentManagementView';

const root = ReactDOM.createRoot(document.getElementById('root'));
export const ROUTES = {
    DASHBOARD: "/dashboard",
    SUPPORT: "/support",
    USERS: "/users",
    ORDERS: "/orders",
    PARTNERS: "/partners",
    PARTNER: (companyId) => companyId != null ? `/partner/${companyId}` : "/partner/:companyId",
    SHOP: (companyId, shopId) =>  companyId != null && shopId != null ? `/partner/${companyId}/shop/${shopId}` : "/partner/:companyId/shop/:shopId", 
}
const router = createBrowserRouter([
    {
        path: "/",
        // element: <App />,
        element: <Login />,
        errorElement: <p>Error 404 not found...</p>
    },
    {
        // path: 
        path: "dashboard",
        // element: <Dashboard />
        element: <Dashboard />
    },
    {
        path: "support",
        element: <SupportOverview />
    },
    {
        path: "users",
        element: <UserManagement />
    },
    {
        path: "orders",
        element: <OrderManagement />
    },
    {
        path: "partners",
        element: <PartnerManagement />,
    },
    {path: '/partner/:companyId', element: <Partner />},
    {path: '/partner/:companyId/shop/:shopId', element: <Shop />},
    {path: '/partner/:companyId/shop/:shopId/contentmanagement', element: <ContentManagementView />},
    {
        path: "test",
        element: <Test />,
    }
])

root.render(
    <Provider store={store}>
        {/* <React.StrictMode> */}
            <RouterProvider router={router} />
            <ActiveCasesBar /> 
            <SupportChatDialog />
            <AlertDialog />
            {/* <AlertDisplay /> */}
        {/* </React.StrictMode> */}
    </Provider>
    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
