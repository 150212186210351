import React, { Component } from 'react'
import CollapsibleTile from '../../../components/CollapsibleTile'
import NestedGenericTable from '../../../components/GenericTable/NestedGenericTable'
import { APIData } from '../../../constants/APIAdresses'
import { Button } from '@mui/material'
import { ALERT_SEVERITY } from '../../../components/AlertDisplay2'
import { GD_MODE, GENERIC_DRAWER_TYPE } from '../../../components/GenericDrawer'
const mainColumns = [
    {
        width: 50,
        label: 'ID',
        dataKey: 'id',
    },
    {
        // width: 2,
        width: 200,
        label: 'Title',
        dataKey: 'title',
    },
    {
        width: 200,
        label: 'Info',
        dataKey: 'info',
    },
    {
        width: 200,
        label: 'Optional',
        dataKey: 'isOptional',
        format: (value) => value ? "Ja" : "Nein"
    },
    {
        width: 200,
        label: 'Multiselektion',
        dataKey: 'isMultiSelection',
        format: (value) => value ? "Ja" : "Nein"
    },

]
const subColumns = [
    {
        width: 200,
        headerName: 'ID',
        field: 'cdid',
    },
    {
        flex: 1,
        headerName: 'Beschreibung',
        field: 'description',
    },
    {
        // width: 2,
        flex: 1,
        headerName: 'Preis',
        field: 'price',
        valueGetter: (value) => value.toFixed(2).replace(".", ",") + " €"
    },
]
export class CollectionsTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collections: []
        }
        this.shopId = this.props.shopId;
    }
    openCollectionEditDrawer(index){
        let baseDrawerConfig = {
            index: index,
            options: [
                { label: "Speichern", onClick: (configData) => { this.updateCategory(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "id",
                    disabled: true,
                    value: this.state.collections[index].id,
                },
                {
                    label: "Titel",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "title",
                    // isNumeric: true,
                    // minValue: 0,
                    // maxValue: this.state.maxSortKey,
                    value: this.state.collections[index].title,
                },
                {
                    label: "Info",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "info",
                    value: this.state.collections[index].info,
                },
                {
                    label: "Optional",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    dbKey: "isOptional",
                    value: this.state.collections[index].isOptional,
                },
                {
                    label: "Multiselektion",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    dbKey: "isMultiSelection",
                    value: this.state.collections[index].isMultiSelection,
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    openCollectionDataEditDrawer(mainIndex, row){
        let baseDrawerConfig= {
            index: mainIndex,
            cdid: row.cdid,
            options: [
                { label: "Speichern", onClick: (configData) => { this.updateCategoryData(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "cdid",
                    disabled: true,
                    value: row.cdid,
                },
                {
                    label: "Beschreibung",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "description",
                    value: row.description,
                },
                {
                    label: "Preis",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "price",
                    isNumeric: true,
                    minValue: 0,
                    value: row.price,
                },
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    openCollectionAddDrawer(){
        let baseDrawerConfig = {
            options: [
                { label: "Speichern", onClick: (configData) => { this.addCategory(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "Titel",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "title",
                    // isNumeric: true,
                    // minValue: 0,
                    // maxValue: this.state.maxSortKey,
                    value: "",
                },
                {
                    label: "Info",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "info",
                    value: "",
                },
                {
                    label: "Optional",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    dbKey: "isOptional",
                    value: true,
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.NEW);
    };
    openCollectionDataAddDrawer(mainIndex){
        let baseDrawerConfig = {
            index: mainIndex,
            options: [
                { label: "Speichern", onClick: (configData) => { this.addCategoryData(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "Beschreibung",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "description",
                    value: "",
                },
                {
                    label: "Preis",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "price",
                    isNumeric: true,
                    minValue: 0,
                    value: 0,
                },
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.NEW);
    }
    render() {
        return (
            <CollapsibleTile
                defaultExpanded
                title="Kollektionen"
                onCollapseChange={(expanded, firstExpand) => { }}
                icon={<img src={require('../../../assets/icons/box.png')} width={20} height={20} />}
            >
                <NestedGenericTable
                    config={{ columns: [...mainColumns], subColumns: [...subColumns], subTableKey: 'collectionData' }}
                    tableData={this.state.collections}
                    setTableData={(data) => { this.setState({ collections: data }) }}
                    apiEndPoint={APIData.SHOP + `/shop/${this.shopId}/collections/getPaged`}
                    getSubRowId={(row) => row.cdid}
                    // onEdit={(index, rowId) => { console.log("Edit: ", index, rowId) }}
                    onDelete={(index, rowId) => { console.log("Delete: ", index, rowId) }}
                    addBtnLabel={"Kollektion Hinzufügen"}
                    addSubBtnLabel={"Kollektionselement Hinzufügen"}
                    onEdit={(index, rowId) => { this.openCollectionEditDrawer(index) }}
                    onSubEdit={(mainIndex, row) => {
                        console.log("ONSUBEDIT: ", mainIndex, row);
                        this.openCollectionDataEditDrawer(mainIndex, row);
                    }}
                    addBtnOnClick={() => { this.openCollectionAddDrawer() }}
                    addSubBtnOnClick={(index) => { this.openCollectionDataAddDrawer(index) }}
                />
            </CollapsibleTile>
        )
    }
}

export default CollectionsTile