import { configureStore } from "@reduxjs/toolkit";
import permissionSlice from "./permissionSlice";
import supportSlice from "./supportSlice";
import generalSlice from "./generalSlice";
import userManagementSlice from "./userManagementSlice";

export default configureStore({
    reducer: {
        permission: permissionSlice,
        support: supportSlice,
        general: generalSlice,
        userManagement: userManagementSlice,
    }
})