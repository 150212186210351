import React, { Component } from 'react'
import Header from '../../components/Header'
import { connect } from 'react-redux'
// import { PermissionAreaComponent } from '../../hookInjection/PermissionAreaComponent'
import { setRole } from '../../redux/permissionSlice'
import DefaultFrame from '../../components/DefaultFrame'
// import PermissionAreaComponent from '../../hookInjection/PermissionAreaComponent'


export class OrderManagement extends Component {
    render() {
        return (
            <DefaultFrame title='Bestellverwaltung'>

            </DefaultFrame>
        )
    }
}
function mapStateToProps(state){
    return {
        role: state.permission.role
    }
}
function mapDispatchToProps(dispatch){
    return {
        setRole: (role) => {
            dispatch(setRole(role));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderManagement);
