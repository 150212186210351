import { Button, Typography } from '@mui/material'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'


export class TileButton extends Component {
    render() {
        return (
            <React.Fragment>
                <Link to={this.props.path} style={{textDecoration:"none", flexGrow: 1, flexDirection: "column", maxWidth: 250, height: "10vh", minHeight:100, borderRadius: 2, display: "flex", justifyContent: "center", alignItems: "center", margin: 10, flexBasis: 200, backgroundColor: "#E5E5E5" }}>
                    <Button style={{flexDirection:"column", width:"100%", height:"100%"}}>
                        {/* {this.props.icon} */}
                        {this.props.icon ?
                        this.props.icon:
                        this.props.iconSrc &&
                        <img src={this.props.iconSrc} width={30} height={30} />
                        }
                        <Typography style={{ marginTop: 10 }}>{this.props.title}</Typography>
                    </Button>
                </Link>
            </React.Fragment>
        )
    }
}
function mapStateToProps(state){
    return {
        loggedIn: state.permission.loggedIn,
        role: state.permission.role,
    }
}
export default connect(mapStateToProps, null)(TileButton)
