import { Button, Divider, FormControl, InputLabel, MenuItem, Select, SwipeableDrawer, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import { ROLES } from '../../../redux/permissionSlice';
import { addressComponent } from '../../support/ChatDialogInfoArea/Tabs/UserInfoTab';
import { Add, Save } from '@mui/icons-material';
import { APIData } from '../../../constants/APIAdresses';
import AddressDisplay from '../../../components/AddressDisplay';

export const USEREDITORDRAWERMODE = {NEW: 0, DISPLAY: 1}
export class EditorDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: this.props.data,
        }
        this.loaded=false;
    }
    onFieldChange(field, value){
        // this.setState({userInfo: {...this.state.userInfo, [field]: value}});
    }
    componentWillUnmount(){
        this.loaded=false;
    }
    componentDidUpdate(){
        if(this.props.isOpen && this.props.mode != USEREDITORDRAWERMODE.NEW){
            this.loadUserInfo();
        }
    }
    loadUserInfo(){
        if(this.props.data?.id != null && !this.loaded && !this.isLoading){
            this.loaded=true;
            fetch( APIData.USER+`/user/${this.props.data.id}/getUserInfo`, {credentials:"include"})
            .then(response => {
                if(response.ok){
                    return response.json();
                }else{
                    return null;
                }
            }).then((result)=> {
                if(result != null){
                    this.alreadyLoaded=true;
                    if(result.userCompanyRelation?.length > 0){
                        result.userCompanyRelation = JSON.parse(result.userCompanyRelation);
                    }
                    if(result.addressData?.length > 0){
                        result.addressData = JSON.parse(result.addressData);
                    }
                    // this.initialData = {...result}; // that's a value to track changes when saving.
                    // this.props.setUserInfo(this.props.currentCaseIndex, result);

                    this.setState({userInfo: {...result}});
                    // this.props.addUserInfoToActiveCase(this.props.currentCaseIndex,result);
                }
                console.log("Result: ",result);
            })
            .catch(e => {
                console.log("Error while loading data...");
            })
        }
    }
    onClose(){
        this.loaded=false;
        this.props.closeDrawer();
    }
    render() {
        return (
            <SwipeableDrawer
                // style={{width:"40%", minWidth:450}}
                PaperProps={{ style: { width: "40%", minWidth: 450, display: "flex", justifyContent:"center", alignItems:"center" } }}
                open={this.props.isOpen}
                anchor='right'
                onClose={() => {this.onClose()}}
                onOpen={this.props.openDrawer}
            >
                <div style={{ flex: 20, padding:15 }}>

                    {this.state.userInfo?.externalId != null &&
                        <TextField label="Externe-ID (Social Login):" variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={this.state.userInfo?.externalId} disabled />
                    }
                    {this.state.userInfo?.externalAPType != null &&
                        <TextField label="Social-Login Art:" variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={this.state.userInfo?.externalAPType} disabled />
                    }
                    <TextField label="ID" variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={this.state.userInfo?.id} disabled />
                    <TextField label="Benutzername" variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={this.state.userInfo?.username != null ? this.state.userInfo.username : ""} disabled={this.props.mode !== USEREDITORDRAWERMODE.NEW} />
                    <FormControl style={{ marginTop: 15, width: "100%" }}>
                        <InputLabel id="role-simple-select-label">Rolle</InputLabel>
                        <Select value={this.state.userInfo?.role != null ? this.state.userInfo?.role : ""} labelId='role-simple-select-label' label="Rolle" onChange={(evt) => { let myUserInfo = { ...this.state.userInfo }; myUserInfo.role = evt.target.value.toString(); this.setState({ userInfo: { ...myUserInfo } }) }}>
                            {Object.keys(ROLES).map(key => {
                                return <MenuItem key={key} value={ROLES[key]}>{key}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <TextField label="Telefonnummer" onChange={(evt) => { this.onFieldChange("phoneNumber", evt.target.value); }} variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={this.state.userInfo?.phoneNumber != null ? this.state.userInfo.phoneNumber : ""} />
                    <TextField label="Name" onChange={(evt) => { this.onFieldChange("name", evt.target.value); }} variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 30 }} value={this.state.userInfo?.name} />
                    <TextField label="E-Mail" onChange={(evt) => { this.onFieldChange("email", evt.target.value); }} variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 30 }} value={this.state.userInfo?.email} />
                    <Divider style={{ marginTop: 25, marginBottom: 25, color: "#676767" }}>Adressen</Divider>
                    {this.state.userInfo?.addressData?.length > 0 ?
                        this.state.userInfo?.addressData.map(adData => {
                            return <AddressDisplay addressData={adData} editActive={true}/>
                        })
                        :
                        <Typography style={{ color: "#676767", textAlign:"center" }}>Keine Adressen</Typography>
                    }
                    <div style={{padding:10, display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <Button variant='outlined' style={{width:"80%", marginTop:25}}  startIcon={<Add/>}>Adresse Hinzufügen</Button>
                    </div>
                    {this.state.userInfo?.userCompanyRelation?.length > 0 &&
                        <>
                            <Divider style={{ marginTop: 25, marginBottom: 25, color: "#676767" }}>Rollen | Shop</Divider>
                            <Typography style={{ fontSize: 10 }}>Rollenlegende:
                                {Object.keys(ROLES).map((key, index) => {
                                    return <Typography style={{ fontSize: 10 }} key={"UserRoles_" + key}>{key} = {ROLES[key]} {index != 0 && index < Object.keys(ROLES).length - 1 && ", "}</Typography>
                                })}
                            </Typography>
                            {this.state.userInfo?.userCompanyRelation.map(relation => {
                                return (
                                    <>
                                        <TextField label="Shop-ID" variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "48%", marginTop: 15 }} defaultValue={relation.shopId} />
                                        <TextField label="ROLLE" variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "48%", marginLeft: "4%", marginTop: 15 }} defaultValue={relation.role} />
                                    </>
                                )
                            })}
                        </>
                    }
                    <div style={{padding:10, display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <Button variant='outlined' style={{width:"80%", marginTop:25}}  startIcon={<Add/>}>Rolle Hinzufügen</Button>
                    </div>
                </div>
                <div style={{flex:1, display:"flex", justifyContent:"center", padding:15}}>
                    <Button variant='contained' startIcon={<Save/>}>Speichern</Button>
                </div>
            </SwipeableDrawer>
        )
    }
}

export default EditorDrawer
