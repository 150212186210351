import { Button, Link, Typography } from '@mui/material'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { addCaseToActiveCaseListAndSetIndex, setChatDialogOpen, setCurrentChatCaseIndex } from '../../../redux/supportSlice'

export class SupportCaseButton extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <Link style={{textDecoration:"none", flexGrow: 1, flexDirection: "column", maxWidth: 250, height: 50, borderRadius: 2, display: "flex", justifyContent: "center", alignItems: "center", margin: 10, flexBasis: 200, backgroundColor: "#E5E5E5" }}>
                    {/* <Button onClick={() => {console.log("OPENING!!!"); this.props.openCase(this.props.id)}} style={{flexDirection:"column", width:"100%", height:"100%"}}> */}
                    <Button onClick={() => { this.props.openChatForCase(this.props.data) }} style={{flexDirection:"column", width:"100%", height:"100%"}}>
                        <Typography>Fall {this.props.id}</Typography>
                    </Button>
                </Link>
            </React.Fragment>
        )
    }
}
function mapDispatchToProps(dispatch){
    return {
        openChatForCase: (data) => {
            dispatch(setChatDialogOpen(true));
            // dispatch(setCurrentChatCaseIndex(caseId));
            dispatch(addCaseToActiveCaseListAndSetIndex({...data, unreadItems: 0, msgList: [], loadOffset: 0, userInfo: {}, orderList: [], orderOffset: 0, supportCases: [], joined: false }));
        }
    }
}
export default connect(null, mapDispatchToProps)(SupportCaseButton)
