import React, { Component } from 'react'
import TileComponent from '../../components/TileComponent'
import { Business, Delete, Edit, Tune } from '@mui/icons-material'
import { CircularProgress, Typography } from '@mui/material'
import { ALERT_SEVERITY } from '../../components/AlertDisplay2';
import { APIData } from '../../constants/APIAdresses';
import { MUI_BTN_VARIANTS, MUI_COLORS } from '../../constants/MUIConstants';

export class PartnerTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        }
    }
    componentDidMount() {
        if (this.props.id != null) {
            const showErr = () => {
                this.setState({
                    alertDisplayMsg: "Fehler beim Laden der Partnerdaten.",
                    alertDisplayVisible: true,
                    alertSeverity: ALERT_SEVERITY.ERROR
                })
            }
            let uri = APIData.SHOP + `/company/${this.props.id}/getInformation`;
            console.log("CompanyURI: ", uri);
            fetch(uri, { credentials: "include", headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return null;
                    }
                }).then(result => {

                    this.setState({isLoading: false});
                    if (result) {
                        console.log("COMPANYDATA: ", result);
                        this.props.onChange(result);
                        // this.setState({companyData: result});
                    } else {
                        showErr();
                    }
                })
                .catch(e => {

                    this.setState({isLoading: false});
                    console.log("Error while loading partner data: ", e);
                    showErr();
                })
        }
    }
    render() {
        return (
            <TileComponent title="Partnerinformationen" icon={<Business />}
                actions={[
                    { label: "Löschen", icon: <Delete />, variant: MUI_BTN_VARIANTS.OUTLINED, color: MUI_COLORS.ERROR, onClick: () => { this.props.onDelete() } },
                    { label: "Editieren", icon: <Edit />, onClick: () => { this.props.onEdit(); } },
                ]}
            >
                {!this.state.isLoading ?
                    <div style={{ display: "flex", flexDirection: "row", minWidth: 900,  }}>
                        <div style={{ flex: 1, overflow: "hidden" }}>
                            <Typography><b>Aktiv:</b> {this.props.data.isActive ? "Ja" : "Nein"}</Typography>
                            <Typography><b>ID:</b> {this.props.data.id}</Typography>
                            <Typography><b>Name:</b> {this.props.data.companyName}</Typography>
                            <Typography><b>Inhaber:</b> {this.props.data.ownerName}</Typography>
                            <Typography><b>Adresse:</b> {this.props.data.street} {this.props.data.houseNumber}</Typography>
                            <Typography><b>PLZ / Ort:</b> {this.props.data.zip} {this.props.data.city}</Typography>
                        </div>
                        <div style={{ flex: 1, overflow: "hidden" }}>
                            <Typography><b>Telefon:</b> {this.props.data.phone}</Typography>
                            <Typography><b>Fax:</b> {this.props.data.fax}</Typography>
                            <Typography><b>E-Mail:</b> {this.props.data.mailAddress}</Typography>
                        </div>
                        <div style={{ flex: 1, overflow: "hidden" }}>
                            <Typography><b>Amtsgericht:</b> {this.props.data.compotentCourtDescription}</Typography>
                            <Typography><b>Handelsregisternummer:</b> {this.props.data.compotentCourtId}</Typography>
                            <Typography><b>Steuer-ID.:</b> {this.props.data.taxId}</Typography>
                            <Typography><b>USt-IdNr.:</b> {this.props.data.taxId}</Typography>
                            <Typography><b>Bevollmächtigter / Vertretungsberechtigter:</b> {this.props.data.authorizedRepresentative}</Typography>
                            <Typography><b>Zusatzinfo:</b> {this.props.data.additionalInfo}</Typography>
                        </div>
                    </div>
                    : 
                    <div style={{ height:50, justifyContent:"center", alignItems:"center", display:"flex"}}>
                        <CircularProgress size={25} />
                    </div>
                    }
            </TileComponent>
        )
    }
}

export default PartnerTile