import { TextField, Typography } from '@mui/material'
import React, { Component } from 'react'

export class EditableText extends Component {
    render() {
        return (
            <div style={{height:40, display:"flex", alignItems:"center", width:"100%", margin: this.props.margin && this.props.margin}}>
                {
                    this.props.editing ?
                        <TextField style={{width:"100%"}} size='small' label={this.props.label} value={this.props.value}></TextField>
                        :
                        <Typography style={{width:"100%"}}><b>{this.props.label}:</b> {this.props.value}</Typography>
                }
            </div>

        )
    }
}

export default EditableText