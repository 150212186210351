import { createSlice } from "@reduxjs/toolkit";
export const userManagementSlice = createSlice({
    name:'userManagement',
    initialState: {
        userTableData: [],
        currentUserDataIndex: -1,
    },
    reducers: {
        setUserTableData: (state, arg) => {state.userTableData=arg.payload},
        setCurrentDataIndex: (state, arg) => {state.currentUserDataIndex=arg.payload},
    }
})

export const { setUserTableData, setCurrentDataIndex } = userManagementSlice.actions

export default userManagementSlice.reducer