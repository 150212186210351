import React, { Component } from 'react'
import DefaultFrame from '../../components/DefaultFrame'
import { Button, Typography } from '@mui/material'
import { withParams } from '../../hookInjection/withParams'
import { withNavigate } from '../../hookInjection/withNavigate'
import GenericTable from '../../components/GenericTable/GenericTable'
import { APIData } from '../../constants/APIAdresses'
import { ROUTES } from '../..'
import { GD_MODE, GD_STATUS_TYPE, GENERIC_DRAWER_TYPE } from '../../components/GenericDrawer'
import { Business, BusinessCenter, Close, Delete, Edit, Save } from '@mui/icons-material'
import { ALERT_SEVERITY } from '../../components/AlertDisplay2'
import TileComponent from '../../components/TileComponent'
import { color } from '@mui/system'
import { MUI_BTN_VARIANTS, MUI_COLORS } from '../../constants/MUIConstants'
import EditableField from '../../components/Elements/EditableField'
import EditableText from '../../components/EditableText'
import PartnerTile from './PartnerTile'
const shopColumns = [
    {
        width: 100,
        label: 'ID',
        dataKey: 'id',
    },
    {
        // width: 2,
        flex: 1,
        label: 'Name',
        dataKey: 'name',
    },
    {
        // width: 3,
        flex: 1,
        label: 'Straße',
        dataKey: 'street',
    },
    {
        // width: 4,
        width: 100,
        label: 'Hausnummer',
        dataKey: 'houseNumber',
    },
    {
        // width: 3,
        flex: 1,
        label: 'Ort',
        dataKey: 'city',
    },
    {
        width: 80,
        label: 'PLZ',
        dataKey: 'zip',
    },
    {
        flex: 1,
        label: 'Telefon',
        dataKey: 'phone',
    },
    {
        flex: 1,
        label: 'E-Mail',
        dataKey: 'mail',
    }
]
export class Partner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            tableData: [],
            companyData: {},
            tileEdit: false,
        }
        this.companyId = this.props.params.companyId;
    }
    
    uploadImage(file, uploadType) {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("image", file);
            console.log("UPLOADTYPE: ", uploadType);
            fetch(APIData.MEDIA + "/upload?type=" + uploadType, { body: formData, method: "POST", credentials: "include" })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        reject("Error while uploading image");
                    }
                }).then(result => {
                    console.log("UPLOADRESULT: ", result);
                    if (result?.path) {
                        resolve(result.path);
                    } else {
                        reject("Error while uploading image");
                    }
                }).catch(e => {
                    reject("Error while uploading image: " + e);
                })
            // })
        })
    }
    checkUpdateImages(configData) {
        return new Promise((resolve, reject) => {
            let imgPromises = [];
            var updatedConfigData = { ...configData };
            configData.fields.forEach((field, index) => {
                // .filter(field => field.status === GD_STATUS_TYPE.UPDATE && field.type === GENERIC_DRAWER_TYPE.IMAGE)
                console.log("Going through fields: ", field);
                if (field.status === GD_STATUS_TYPE.UPDATE && field.type === GENERIC_DRAWER_TYPE.IMAGE) {
                    imgPromises.push(
                        this.uploadImage(field.value, field.uploadType).then(result => {
                            updatedConfigData.fields[index].value = result;
                        }).catch(e => {
                            console.log("Error while uploading image: ", e);
                        })
                    );
                }
            })
            console.log("Updating files: ", imgPromises);
            Promise.all(imgPromises).then(() => {
                resolve({ ...updatedConfigData });
            }).catch(e => {
                console.log("PROMISE_Error while updating images: ", e);
            })
        })
    }
    addShop(mainIndex, configData) {
        this.checkUpdateImages(configData).then(updatedConfigData => {
            configData = { ...updatedConfigData };
            console.log("Adding Shop (company=" + this.state.tableData[mainIndex].companyName + "): ", configData);
            let body = {
                fields: {}
            }
            let lon = "";
            let lat = "";
            configData.fields.forEach((field) => {
                if (field.value?.length > 0) {
                    if (field.dbKey === "gpsPosition") {
                        if (field.label === "Breitengrad") {
                            lat = field.value;
                        } else {
                            lon = field.value;
                        }
                    } else {
                        body.fields[field.dbKey] = field.value;
                    }
                }
                if (field.type === GENERIC_DRAWER_TYPE.SWITCH) {
                    body.fields[field.dbKey] = field.value;
                }
            });
            body.fields["companyId"] = this.companyId;
            body.fields["gpsPosition"] = `POINT (${lat} ${lon})`;
            console.log("SHOP BODY: ", body);
            const showErr = () => {
                this.props.closeDrawer();
                this.props.showAlertDisplay("Beim Hinzufügen vom Shop ist ein Fehler aufgetreten.", ALERT_SEVERITY.ERROR);
            }
            fetch(APIData.SHOP + "/shop/add", { body: JSON.stringify(body), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    if (response.ok) {
                        this.props.closeDrawer();
                        this.props.showAlertDisplay("Shop wurde erfolgreich hinzugefügt.", ALERT_SEVERITY.SUCCESS);
                        // return response.json();
                    } else {
                        showErr();
                        return null;
                    }
                }).catch(e => {
                    console.log("Error while adding shop: ", e);
                    showErr();
                })
        }).catch(e => {
            console.log("Error while update shop (checkUpdateImage): ", e);
        })
    }
    updateShop(configData) {
        this.checkUpdateImages(configData)
            .then(updatedConfigData => {
                console.log("UPDATEDCONFIGDATA: ", updatedConfigData);
                configData = { ...updatedConfigData };
                let body = {
                    fields: {}
                }
                let lat = "";
                let lon = "";
                const showErr = () => {
                    this.setState({
                        alertDisplayMsg: "Beim Aktualisieren der Daten ist ein Fehler aufgetreten.",
                        alertDisplayVisible: true,
                        alertSeverity: ALERT_SEVERITY.ERROR
                    })
                }
                configData.fields.filter(field => field.status === GD_STATUS_TYPE.UPDATE).forEach((field, index) => {
                    if (field.value?.length > 0 || field.type === GENERIC_DRAWER_TYPE.SWITCH) {
                        if (field.dbKey === "gpsPosition") {
                            if (field.label === "Breitengrad") {
                                lat = field.value;
                            } else {
                                lon = field.value;
                            }
                        } else {
                            body.fields[field.dbKey] = field.value;
                        }
                    }
                });
                if (lat.length > 0 && lon.length > 0) {
                    body.fields["gpsPosition"] = `POINT (${lat} ${lon})`;
                }
                console.log("SHOP BODY: ", body);
                if (Object.keys(body.fields).length > 0) {
                    fetch(APIData.SHOP + "/shop/" + configData.id + "/updateInformation", { body: JSON.stringify(body), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
                        .then(response => {
                            if (!response.ok) {
                                showErr();
                            } else {
                                this.props.closeDrawer();
                                this.props.showAlertDisplay("Shopdaten wurden erfolgreich aktualisiert.", ALERT_SEVERITY.SUCCESS);
                                let myData = [...this.state.tableData];
                                configData.fields.filter(field => field.status === GD_STATUS_TYPE.UPDATE).forEach((field) => {
                                    // let index = myData[configData.index].shops.findIndex(shop => shop.id === configData.id);
                                    if (configData.index) {
                                        if (field.dbKey === "gpsPosition") {
                                            if (field.label === "Breitengrad") {
                                                myData[configData.index].lat = field.value;
                                            } else {
                                                myData[configData.index].lon = field.value;
                                            }
                                        } else {
                                            myData[configData.index][field.dbKey] = field.value;
                                        }
                                    }
                                })
                                this.setState({ tableData: [...myData] });
                                this.props.closeDrawer();
                            }
                        }).catch(e => {
                            console.log("Error while update shop \nbody: ", body, "\nerror: ", e);
                            showErr();
                        })
                } else {
                    this.props.closeDrawer();
                    this.props.showAlertDisplay("Es wurden keine Änderungen vorgenommen.", ALERT_SEVERITY.INFO);
                }
            }).catch(e => {
                console.log("Error while update shop (checkUpdateImage): ", e);
            })
    }
    openNewShopDrawer(index) {
        let baseDrawerConfig = {
            options: [
                {
                    label: "Hinzufügen",
                    icon: <Save />,
                    doRequiredFieldCheck: true,
                    onClick: (configData) => { this.addShop(configData) }
                }
            ],
            fields: [
                {
                    label: "Titelbild",
                    type: GENERIC_DRAWER_TYPE.IMAGE,
                    value: "",
                    baseUri: APIData.MEDIA,
                    uploadType: APIData.MEDIA_TYPES.HEADERS,
                    dimensions: { width: 800, height: 200 },
                    dbKey: "imgSource"
                },
                {
                    label: "Logo",
                    type: GENERIC_DRAWER_TYPE.IMAGE,
                    value: "",
                    baseUri: APIData.MEDIA,
                    uploadType: APIData.MEDIA_TYPES.ICONS,
                    dimensions: { width: 500, height: 500 },
                    dbKey: "iconSource"
                },
                {
                    label: "Name",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "name",
                    isRequired: true
                },
                {
                    label: "Straße",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "street",
                    isRequired: true
                },
                {
                    label: "Hausnummer",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "houseNumber",
                    isRequired: true
                },
                {
                    label: "Ort",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "city",
                    isRequired: true
                },
                {
                    label: "PLZ",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "zip",
                    isRequired: true
                },
                {
                    label: "Telefon",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "phone",
                    isRequired: true
                },
                {
                    label: "GPS-Koordinaten",
                    type: GENERIC_DRAWER_TYPE.DIVIDER,
                },
                {
                    label: "Breitengrad",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "gpsPosition",
                    isRequired: true
                },
                {
                    label: "Längengrad",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "gpsPosition",
                    isRequired: true
                },
                {
                    label: "",
                    type: GENERIC_DRAWER_TYPE.DIVIDER,
                },
                {
                    label: "E-Mail",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "mail"
                },
                {
                    label: "Lieferzeit",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    isNumeric: true,
                    value: "",
                    dbKey: "deliveryTime"
                },
                {
                    label: "Abholrabatt",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "pickupDiscount",
                    isNumeric: true,
                    maxChar: 2,
                },
                {
                    label: "Zusatzinfo",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "additionalInfo"
                },
                {
                    label: "Kartenzahlung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: false,
                    dbKey: "debitAvailable"
                },
                {
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    label: "Zahlungen Deaktiviert",
                    value: false,
                    dbKey: "paymentsDisabled"
                },
                {
                    label: "Großhandelshop",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: false,
                    dbKey: "partnerDealShop"
                },
                {
                    label: "Automatische Externe Lieferung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: false,
                    dbKey: "extDeliveryByDefault"
                },
                {
                    label: "Externe Lieferung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: false,
                    dbKey: "extDeliveryActive"
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.NEW);
    }
    openShopEditDrawer(index) {
        let shop = this.state.tableData[index];
        let baseDrawerConfig = {
            id: shop.id,
            index: index,
            options: [
                { label: "Speichern", onClick: (configData) => { this.updateShop(configData) } },
                { label: "Abbrechen", onClick: () => { this.closeDrawer(); } }
            ],
            fields: [
                {
                    label: "Aktiv",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: shop.isActive,
                    dbKey: "isActive",
                },
                {
                    label: "Titelbild",
                    type: GENERIC_DRAWER_TYPE.IMAGE,
                    value: shop.imgSource,
                    baseUri: APIData.MEDIA,
                    uploadType: APIData.MEDIA_TYPES.HEADERS,
                    dimensions: { width: 800, height: 200 },
                    dbKey: "imgSource"
                },
                {
                    label: "Logo",
                    type: GENERIC_DRAWER_TYPE.IMAGE,
                    value: shop.iconSource,
                    baseUri: APIData.MEDIA,
                    uploadType: APIData.MEDIA_TYPES.ICONS,
                    dimensions: { width: 500, height: 500 },
                    dbKey: "iconSource"
                },
                {
                    label: "ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: true,
                    value: shop.id,
                    dbKey: "id"
                }, {
                    label: "Name",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.name,
                    dbKey: "name"
                },
                {
                    label: "Straße",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.street,
                    dbKey: "street"
                },
                {
                    label: "Hausnummer",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.houseNumber,
                    dbKey: "houseNumber"
                },
                {
                    label: "Ort",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.city,
                    dbKey: "city"
                },
                {
                    label: "PLZ",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.zip,
                    dbKey: "zip"
                },
                {
                    label: "Telefon",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.phone,
                    dbKey: "phone"
                },
                {
                    label: "GPS-Koordinaten",
                    type: GENERIC_DRAWER_TYPE.DIVIDER,
                },
                {
                    label: "Breitengrad",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.lat,
                    dbKey: "gpsPosition",
                    isRequired: true
                },
                {
                    label: "Längengrad",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.lon,
                    dbKey: "gpsPosition",
                    isRequired: true
                },
                {
                    label: "",
                    type: GENERIC_DRAWER_TYPE.DIVIDER,
                },
                {
                    label: "E-Mail",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.mail,
                    dbKey: "mail"
                },
                {
                    label: "Zusatzinfo",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.additionalInfo,
                    dbKey: "additionalInfo"
                },
                {
                    label: "Abholrabatt",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.pickupDiscount,
                    dbKey: "pickupDiscount"
                },
                {
                    label: "Kartenzahlung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: shop.debitAvailable,
                    dbKey: "debitAvailable"
                },
                {
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    label: "Zahlungen Deaktiviert",
                    value: shop.paymentsDisabled,
                    dbKey: "paymentsDisabled"
                },
                {
                    label: "Großhandelshop",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: shop.partnerDealShop,
                    dbKey: "partnerDealShop"
                },
                {
                    label: "Automatische Externe Lieferung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: shop.extDeliveryByDefault,
                    dbKey: "extDeliveryByDefault"
                },
                {
                    label: "Externe Lieferung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: shop.extDeliveryActive,
                    dbKey: "extDeliveryActive"
                }

            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    updateCompany(configData) {
        console.log("UPDATE COMPANY DATA: ", configData);
        let body = {
            fields: {}
        }
        const showErr = () => {
            this.props.closeDrawer();
            this.props.showAlertDisplay("Beim Aktualisieren der Daten ist ein Fehler aufgetreten.", ALERT_SEVERITY.ERROR);
        }
        configData.fields.filter(field => field.status === GD_STATUS_TYPE.UPDATE).forEach((field) => {
            if (field.value?.length > 0 || field.type === GENERIC_DRAWER_TYPE.SWITCH) {
                body.fields[field.dbKey] = field.value;
            }
        });
        console.log("COMPANY BODY: ", body);
        let uri = APIData.SHOP + "/company/" + configData.id + "/updateInformation";
        console.log("companyupdateuri: ",uri);
        if (Object.keys(body.fields).length > 0) {
            fetch(uri, { body: JSON.stringify(body), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    if (!response.ok) {
                        showErr();
                    } else {
                        let myData = {...this.state.companyData};
                        configData.fields.filter(field => field.status === GD_STATUS_TYPE.UPDATE).forEach((field) => {
                            myData[field.dbKey] = field.value;
                        })
                        this.setState({
                            companyData: {...myData},
                        })
                        this.props.closeDrawer();
                        this.props.showAlertDisplay("Partnerdaten wurden erfolgreich aktualisiert.", ALERT_SEVERITY.SUCCESS);
                    }
                }).catch(e => {
                    console.log("Error while updating company data: ", e);
                    showErr();
                })
        } else {
            this.props.closeDrawer();
            this.props.showAlertDisplay("Es wurden keine Änderungen vorgenommen.", ALERT_SEVERITY.INFO);
        }
    }
    openCompanyEditDrawer() {
        let baseDrawerConfig = {
            id: this.state.companyData.id,
            options: [
                { label: "Speichern", onClick: (configData) => { this.updateCompany(configData) } },
                { label: "Abbrechen", onClick: () => { this.closeDrawer() } }
            ],
            fields: [
                {
                    label: "Aktiv",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: this.state.companyData.isActive,
                    dbKey: 'isActive'
                },
                {
                    label: "ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: true,
                    value: this.state.companyData.id,
                    dbKey: "id"
                }, {
                    label: "Firma",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.companyName,
                    dbKey: "companyName"
                },
                {
                    label: "Inhaber",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.ownerName,
                    dbKey: "ownerName"
                },
                {
                    label: "Straße",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.street,
                    dbKey: "street"
                },
                {
                    label: "Hausnummer",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.houseNumber,
                    dbKey: "houseNumber"
                },
                {
                    label: "Ort",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.city,
                    dbKey: "city"
                },
                {
                    label: "PLZ",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.zip,
                    dbKey: "zip",
                    isNumeric: true,
                    isRequired: true,
                    maxChar: 5
                },
                {
                    label: "Telefon",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.phone,
                    dbKey: "phone"
                },
                {
                    label: "Fax",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.fax,
                    dbKey: "fax"
                },
                {
                    label: "E-Mail",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.mailAddress,
                    dbKey: "mailAddress"
                },
                {
                    label: "Amtsgericht",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.compotentCourtDescription,
                    dbKey: "compotentCourtDescription"
                },
                {
                    label: "Handelsregisternummer",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.compotentCourtId,
                    dbKey: "compotentCourtId"
                },
                {
                    label: "Steuer-ID.",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.taxId,
                    dbKey: "taxId"
                },
                {
                    label: "USt-IdNr.",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.vatId,
                    dbKey: "vatId"
                },
                {
                    label: "Bevollmächtigter / Vertretungsberechtigter",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.authorizedRepresentative,
                    dbKey: "authorizedRepresentative"
                },
                {
                    label: "Zusatzinfo",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.companyData.additionalInfo,
                    dbKey: "additionalInfo"
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    componentDidMount() {
        // LOAD COMPANY DATA
        
    }
    render() {
        return (
            <>
                <PartnerTile id={this.companyId} data={this.state.companyData} onChange={(companyData) => {this.setState({companyData: {...companyData}})}} onEdit={() => {this.openCompanyEditDrawer()}} onDelete={()=>{ console.log("Delete CLICKED"); }}/>
                <GenericTable
                margin="20px 0px 0px 0px"
                config={{ columns: [...shopColumns] }} 
                apiEndPoint={APIData.SHOP + `/company/${this.companyId}/getPagedShopData`} 
                tableData={this.state.tableData} 
                setTableData={(data) => { this.setState({ tableData: data }) }} 
                // onClickRow={(index, rowData) => {console.log("CLICKEDUSER (index="+index+"): ",rowData); this.openDrawer(rowData); this.setAlertDisplayVisible(); }}
                onClickRow={(index) => { 
                    console.log("clickedrowdata: ", this.state.tableData[index]);
                    this.props.navigate(ROUTES.SHOP(this.companyId, this.state.tableData[index].id));
                    // this.openDrawer(index, this.state.tableData[index]);
                    // this.setAlertDisplayVisible();
                }} 
                activeKey="isActive" 
                onSetActive={(index, active) => { 
                    this.onSetActive(index, active);
                }} 
                onEdit={(index) => {
                    this.openShopEditDrawer(index);
                    // console.log("ONEDIT CLICKED: ", this.state.tableData[index]);
                    // this.openDrawer(index, this.state.tableData[index]);
                    // this.setAlertDisplayVisible();
                }}
                onDelete={(index) => {
                    console.log("ONDELETE CLICKED: ", this.state.tableData[index]);
                }}
                // onSelectRow={(data) => { console.log("SELECTED USER: ",data); this.openDrawer(data); this.setAlertDisplayVisible(); }}
                addBtnOnClick={() => { this.openNewShopDrawer() }}
                addBtnLabel="Shop Hinzufügen"
                />
            </>
        )
    }
}

class defaultWrapper extends React.Component {
    render(){
        return (
            <DefaultFrame>
                <Partner {...this.props} />
            </DefaultFrame>
        )
    }
}
export default withNavigate(withParams(defaultWrapper))

