import { TabPanel } from '@mui/lab'
import { Typography } from '@mui/material'
import React, { Component } from 'react'
import SupportCaseButton from '../Elements/SupportCaseButton'

export class SearchSupporCaseContent extends Component {
    componentDidUpdate(){
        console.log("PROPSDATA: ",this.props.data);
    }
    render() {
        return (
            <div style={{margin:25, height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                {this.props.data != null && Object.keys(this.props.data).length > 0 ?
                    <SupportCaseButton id={this.props.data.id} data={this.props.data}/>
                :
                <Typography style={{width:"100", textAlign:"center"}}>Keine Ergebnisse für '{this.props.searchValue}' gefunden...</Typography>
                }
            </div>
        )
    }
}

export default SearchSupporCaseContent
