import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { Component } from 'react'

// <TextField inputRef={(ref) => { this.passwordFieldRef = ref }} value={this.state.secret} onChange={(evt) => { this.setState({ secret: evt.target.value, loginError: false }) }} onKeyDown={(evt) => { if (evt.key === "Enter") { evt.target.blur(); evt.preventDefault(); this.login() } }} type={this.state.passwordVisible ? "text" : 'password'} style={{ minWidth: "300px", width: "10%", backgroundColor: "#fff", marginTop: 15 }} variant='outlined' label='Passwort'
export class SecretTextField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordVisible: false
        }
    }
    togglePasswordVisibility() {
        this.setState({ passwordVisible: !this.state.passwordVisible });
    }
    render() {
        return (
            <TextField inputRef={(ref) => {typeof this.props.inputRef === "function" && this.props.inputRef(ref)}} value={this.props.value} onChange={(evt) => { typeof this.props.onChange === "function" && this.props.onChange(evt.target.value) }} onKeyDown={(evt) => { if (evt.key === "Enter") { evt.target.blur(); evt.preventDefault(); typeof this.props.onEnterPressed === "function" && this.props.onEnterPressed() } }} type={this.state.passwordVisible ? "text" : 'password'} style={{ width: "100%", backgroundColor: "#fff" }} variant='outlined' label={this.props.label ? this.props.label : 'Passwort'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton aria-label='toggle password visibility' onMouseDown={() => { this.togglePasswordVisibility() }} onClick={() => { this.togglePasswordVisibility() }}>
                                {this.state.passwordVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>)
                }} />
        )
    }
}
export default SecretTextField