
import React, { Component } from 'react'

import TableBody from '@mui/material/TableBody';
import { CELLFORMAT, StyledTableCell, StyledTableRow } from './GenericTable';
import { Collapse, IconButton, Switch, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/system';
import { ArrowDownward, ArrowUpward, Delete, Edit, North, South } from '@mui/icons-material';
import PagedDataGrid from './PagedDataGrid';
// 

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [subTableColSpan,setSubTableColSpan] = React.useState(0);
    React.useEffect(() => {
        // setSubTableColSpan(props.columns.length + 1);
        setSubTableColSpan(
            props.columns.length + 
            (props.onSetActive != null && props.activeKey != null ? 1 : 0) + 
            (props.onDelete != null ? 1 : 0) + 
            (props.onEdit != null ? 1 : 0) +
            (props.onPositionChange != null ? 2 : 0)
        );
    }, []); 
    
    return (
        <React.Fragment>
            <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={row.id}
                style={{ cursor: 'pointer', }}>
                <StyledTableCell onClick={() => setOpen(!open)}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                    // onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                {props.onSetActive != null && props.activeKey != null &&
                    <StyledTableCell sx={{ width: 25, textAlign: "center !important" }} onClick={(event) => { event.stopPropagation(); props.onSetActive(!row[props.activeKey]); }} >
                        <Switch size='small' color="primary" checked={row[props.activeKey]} />
                    </StyledTableCell>
                }
                
                {props.columns.map((column) => {
                    return <StyledTableCell onClick={() => { props.onClick(); }} key={column.dataKey} >{column.format ? column.format(row[column.dataKey]) : row[column.dataKey]}</StyledTableCell>
                })}
                {props.onDelete != null &&
                    <StyledTableCell sx={{ width: 25 }} onClick={(event) => { event.stopPropagation(); props.onDelete(row.id); }} >
                        <IconButton size="small" color='primary' >
                            <Delete />
                        </IconButton>
                    </StyledTableCell>
                }
                {props.onEdit != null &&
                    <StyledTableCell sx={{ width: 25 }} onClick={(event) => { event.stopPropagation(); props.onEdit(row.id); }} >
                        <IconButton size="small" color='primary' >
                            <Edit />
                        </IconButton>
                    </StyledTableCell>
                }
                {props.onPositionChange != null &&
                    <StyledTableCell sx={{ maxWidth: 10 }} onClick={(event) => { event.stopPropagation(); props.onPositionChange(row, -1); }} >
                        <IconButton size="small" color='primary' >
                            <ArrowUpward style={{height:20, width:20}} />
                        </IconButton>
                    </StyledTableCell>
                }
                {props.onPositionChange != null &&
                    <StyledTableCell sx={{ maxWidth: 10 }} onClick={(event) => { event.stopPropagation(); props.onPositionChange(row, 1); }} >
                        <IconButton size="small" color='primary' >
                            <ArrowDownward style={{height:20, width:20}} />
                        </IconButton>
                    </StyledTableCell>
                }
                
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell style={{padding:0 }} colSpan={subTableColSpan+1}>
                    <Collapse key={row[props.subTableKey].length} in={open} timeout="auto" unmountOnExit>
                            <div style={{width:"100%"}}>
                            {/* <Typography>{Object.values().flat().join(", ")}</Typography> */}
                            {/* {row.shops.map((shop, index) => {
                                return <Typography key={row.id + "_SUBCOL_" + shop.id + "_" + index}>{Object.values(shop).join(", ")}</Typography>
                            })} */}
                            {/* <Typography></Typography> */}
                            
                                <PagedDataGrid
                                    subTableSortModel={props.subTableSortModel}
                                    onSubPositionChange={props.onSubPositionChange ? (subrow, direction) => { props.onSubPositionChange(row, subrow, direction) } : null}
                                    getSubRowId={props.getSubRowId}
                                    rows={row[props.subTableKey]}
                                    columns={props.subColumns}
                                    mainIndex={props.index}  // Pass the mainIndex here
                                    activeKey={props.subActiveKey}
                                    addBtnLabel={props.addSubBtnLabel}
                                    addBtnOnClick={() => { props.addSubBtnOnClick(props.index) }}
                                    addBtnIcon={props.addSubBtnIcon}
                                    onClick={(myRow) => { props.onSubClick(myRow) }}
                                    onSetActive={( row) => { props.onSubSetActive( row) }}
                                    onEdit={(myRow) => { props.onSubEdit(myRow) }}
                                    onDelete={(myRow) => {props.onSubDelete(myRow)}}
                                />
                            </div>
                    </Collapse>
                </StyledTableCell>

            </StyledTableRow>
        </React.Fragment>
    );
}
export class NTTableBody extends Component {
    render() {
        return (
            <TableBody>
                {this.props.tableData?.map((row, index) => {
                    return <Row
                        key={row.id}
                        row={row}
                        getRowId={this.props.getRowId}
                        getSubRowId={this.props.getSubRowId}
                        onPositionChange={this.props.onPositionChange}
                        onSubPositionChange={this.props.onSubPositionChange}
                        subTableSortModel={this.props.subTableSortModel}
                        subColumns={this.props.subColumns}
                        subTableKey={this.props.subTableKey}
                        onEdit={(rowId) => { this.props.onEdit(index, rowId) }}
                        onDelete={(rowId) => { this.props.onDelete(index, rowId) }}
                        onSetActive={(checked) => { this.props.onSetActive(index, checked) }}
                        onSubSetActive={(row) => {
                            this.props.onSubSetActive(index, row)
                        }}
                        addSubBtnIcon={this.props.addSubBtnIcon}
                        addSubBtnLabel={this.props.addSubBtnLabel}
                        addSubBtnOnClick={this.props.addSubBtnOnClick}
                        //     this.props.onSubSetActive(index, subIndex, checked) }}
                        activeKey={this.props.activeKey}
                        subActiveKey={this.props.subActiveKey}
                        columns={this.props.columns}
                        index={index}
                        onClick={() => { this.props.onClickRow(index) }}
                        onSubClick={(myRow) => { this.props.onClickSubRow(index, myRow) }}
                        onSubEdit={(myRow) => { this.props.onSubEdit(index, myRow) }}
                        onSubDelete={(myRow) => { this.props.onSubDelete(index, myRow) }}
                    />
                })}
            </TableBody>
        )
    }
}

export default NTTableBody



{/* <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right">Total price ($)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.history.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.date}
                                            </TableCell>
                                            <TableCell>{historyRow.customerId}</TableCell>
                                            <TableCell align="right">{historyRow.amount}</TableCell>
                                            <TableCell align="right">
                                                {Math.round(historyRow.amount * row.price * 100) / 100}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow> */}
