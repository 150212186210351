import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import React, { Component } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { ArrowBack, Logout } from '@mui/icons-material'
import { withNavigate } from '../hookInjection/withNavigate'
import { APIData } from '../constants/APIAdresses'
import { setLoggedIn, setRole } from '../redux/permissionSlice'
import { connect } from 'react-redux'

export class Header extends Component {

    goBack() {
        if (window.history.length > 0) {
            this.props.navigate(-1);
        }
    }
    logout() {
        fetch(APIData.USER + "/auth/session/destroy", { credentials: "include" })
            .then(response => {

                if (response.ok) {
                    console.log("Error")
                    this.props.setLoggedIn(false);
                    this.props.setUserRole(null);
                    this.props.navigate("/");
                }
            }).catch(e => {
                console.log("Error while logout: ", e);
            })
    }
    render() {
        return (
            <AppBar style={{}} position='static'>
                {/* <Toolbar style={{backgroundColor: "#1bb5cd"}}> */}
                <Toolbar style={{ backgroundColor: "#353535" }}>
                    {!this.props.noMenu &&
                        <IconButton
                            size='large' edge="start" color='inherit' aria-label='menu' sx={{ mr: 2 }}>
                            <MenuIcon />
                        </IconButton>
                    }
                    <Typography component="div" sx={{ flexGrow: 1, fontSize: 18 }} >{this.props.title}</Typography>
                    {!this.props.noBack &&
                        <IconButton onClick={() => { this.goBack() }} size='large' aria-label='arrow' color='inherit' sx={{ mr: 2 }}>
                            <ArrowBack />
                        </IconButton>
                    }
                    <img height={32} width={32} src={require("../assets/icons/viveel_logo128x128.png")} alt='Logo' />
                    {!this.props.noLogout &&
                        <IconButton onClick={() => { this.logout() }} size='large' aria-label='logout' color='inherit' sx={{ ml: 2 }}>
                            <Logout />
                        </IconButton>
                    }
                </Toolbar>
            </AppBar>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setLoggedIn: () => {
            dispatch(setLoggedIn(true));
        },
        setUserRole: (role) => {
            dispatch(setRole(role));
        }
    }
}
export default connect(null, mapDispatchToProps)(withNavigate(Header))
