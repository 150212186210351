import React, { Component } from 'react'
import CollapsibleTile from '../../components/CollapsibleTile';
import GenericTable from '../../components/GenericTable/GenericTable';

const columns = [
    { dataKey: 'id', label: 'ID', width: 120 },
    { dataKey: 'uniqueKey', label: 'Eindeutige Bezeichnung', width: 150 },
    { dataKey: 'defaultLabel', label: 'Standardbezeichnung', },
    { dataKey: 'unit', label: 'Einheit', type: 'number', width: 70 }
]
export class PromotionsTile extends Component {
    constructor(props){
        super(props);
        this.state={
            nutritonData: []
        }
    }
    loadData (){
        // Load data from API
    }
    render() {
        return (
            <CollapsibleTile 
            onCollapseChange={(expanded) => { this.loadData()}}
            title="Verkaufsaktionen"
            icon = {<img src={require('../../assets/icons/discount_orange.png')} width={20} height={20} />}
            >
                
            </CollapsibleTile>
        )
    }
}

export default PromotionsTile