
import { Button, CircularProgress, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, SwipeableDrawer, Switch, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EntityAdd from './Elements/EntityAdd'
import EditableField from './Elements/EditableField'
import { APIData } from '../constants/APIAdresses'
import ImageField from './Elements/ImageField'
export const GENERIC_DRAWER_TYPE = {
    DIVIDER: 0, TEXT: 1, ENUM: 2, EDITABLE: 3, ENTITY_ADD: 4, SWITCH: 5, IMAGE: 6,
}
export const GD_STATUS_TYPE = {
    IDLE: 0, NEW: 1, UPDATE: 2, DELETE: 3,
}
export const GD_MODE = {
    NEW: 0, UPDATE: 1,
}
export const GD_MAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const GD_PHONE_REGEX = /^(?:00|\+|0)\d{1,3}[\s-]?\d{1,14}$/;

export const GenericDrawer = (props) => {
    const [config, setConfig] = useState({...props.config});
    
    useEffect(() => {
        console.log("###conf: ", props.config);
        setConfig({ ...props.config });
    }, [props.config])
    function updateField(option, index, value, maxChar) {
        if (isNaN(parseInt(maxChar)) || value.toString().length <= maxChar) {
            if(option.isNumeric) {
                if(option.maxValue != null && parseInt(value) > option.maxValue){
                    setFieldError(index, `Der Wert darf nicht größer als ${option.maxValue} sein.`);
                    return;
                }
                if(option.minValue != null && parseInt(value) < option.minValue){
                    setFieldError(index, `Der Wert darf nicht kleiner als ${option.minValue} sein.`);
                    return;
                }
            }
                let c = { ...config };
                c.fields[index].value = value;
                delete c.fields[index].error;
                delete c.fields[index].helperText;
                c.fields[index].status = c.fields[index].status != GD_STATUS_TYPE.NEW ? GD_STATUS_TYPE.UPDATE : GD_STATUS_TYPE.NEW;
                setConfig({ ...c });
        }
    }
    function setFieldLoading(index, value) {
        let c = { ...config };
        c.fields[index].loading = value;
        setConfig({ ...c });
    }
    function setFieldError(index, errMsg) {
        let c = { ...config };
        c.fields[index].error = true;
        console.log("Setting error message: ", errMsg);
        c.fields[index].helperText = errMsg;
        setConfig({ ...c });
    }
    // function resetFieldError(index) {
    //     let c = { ...config };
    //     delete c.fields[index].error;
    //     delete c.fields[index].helperText;
    //     setConfig({ ...c });
    // }
    function updateEditableFieldValue(mainIndex, fieldIndex, value) {
        let c = { ...config };
        // c.fields[mainIndex].values[fieldIndex] = value;
        c.fields[mainIndex].fields[fieldIndex].value = value;
        delete c.fields[mainIndex].fields[fieldIndex].error;
        delete c.fields[mainIndex].fields[fieldIndex].helperText;

        if (c.fields[mainIndex].status !== GD_STATUS_TYPE.NEW) {
            c.fields[mainIndex].status = GD_STATUS_TYPE.UPDATE;
            c.fields[mainIndex].fields[fieldIndex].status = GD_STATUS_TYPE.UPDATE;
        }
        setConfig({ ...c });
    }
    function setEditableFieldError(mainIndex, fieldIndex, errorMsg) {
        let c = { ...config };
        c.fields[mainIndex].fields[fieldIndex].error = true;
        c.fields[mainIndex].fields[fieldIndex].helperText = errorMsg;
        setConfig({ ...c });
    }
    function setEditableFieldLoading(mainIndex, fieldIndex, isLoading) {
        let c = { ...config };
        c.fields[mainIndex].fields[fieldIndex].loading = isLoading;
        setConfig({ ...c });
    }
    function setEditModeForEditable(index, value) {
        let c = { ...config };
        c.fields[index].isEditMode = value;
        setConfig({ ...c });
    }

    function deleteEntity(index) {
        console.log("Deleting...: ", config.fields[index]);
        let c = { ...config };
        if (c.fields[index].status === GD_STATUS_TYPE.NEW) { // if the entity was a newly added entity then delete it
            c.fields.splice(index, 1);
        } else { // otherwise set a flag for delete 
            c.fields[index].status = GD_STATUS_TYPE.DELETE;
        }
        setConfig({ ...c });
    }

    function addEntity(entityKey, fields) {
        let c = { ...config };
        // this finds the last item created with the entityKey
        console.log("EntityKey: ", entityKey);
        console.log("fields: ", fields);
        let fieldIndex = c.fields.map(tItem => {
            // we're filtering for the type ENTITY_ADD to ensure, that the new data is
            // inserted before the button.
            return tItem.type != GENERIC_DRAWER_TYPE.ENTITY_ADD ? tItem.entityKey : undefined
        }).lastIndexOf(entityKey);
        console.log("FIELDINDEX: ", fieldIndex);
        if (fieldIndex != -1) {
            let editableField = {
                type: GENERIC_DRAWER_TYPE.EDITABLE,
                isEditMode: false,
                status: GD_STATUS_TYPE.NEW,
                entityKey: entityKey,
                fields: [...fields],
            }
            if (fieldIndex == c.fields.length - 1) {
                c.fields.push(editableField);
            } else {
                c.fields.splice(fieldIndex + 1, 0, editableField);
            }
            console.log("CONFIG: ", c);
            setConfig({ ...c });
        }
    }
    function onClose() {
        // this.loaded = false;
        props.closeDrawer();
    }
    // todo this is only done for form or textfields for now no entities
    function doFinalChecksForFields() {
        console.log("Checking???")
        let fieldsAreValid = true;
        // checking overall validity.
        props.config?.fields?.forEach((element, index) => {
            if (!fieldIsValid(index, element, element.value)) {
                fieldsAreValid = false;
            }
        });
        return fieldsAreValid;
    }
    function fieldIsValid(index, field, value) {
        if (field.type !== GENERIC_DRAWER_TYPE.DIVIDER) {
            console.log("FIELD: ",field);
            console.log("VALUE: ", typeof value);
            if (value ? value.length > 0 || typeof value === 'object' && value != null : // object for files...
                field.value.length > 0) {
                if (field.isMail && !GD_MAIL_REGEX.test(value ? value : field.value)) {
                    setFieldError(index, "E-Mail Adresse ist ungültig!");
                    return false;
                } else if (field.isPhone && !GD_PHONE_REGEX.test(value ? value : field.value)) {
                    setFieldError(index, "Ungültige Telefonnummer!")
                    return false;
                } else if ((value ? value.length : field.value.length) < field.minChar) {
                    setFieldError(index, `Dieses Feld muss mindestens ${field.minChar} Zeichen haben.`);
                    return false;
                } else if (field.check != null) {
                    let errorMsg = field.check(value ? value : field.value);
                    if (errorMsg != null) {
                        setFieldError(index, errorMsg);
                        return false;
                    }
                }
            } else if (field.isRequired) {

                setFieldError(index, "Das ist ein Pflichtfeld")
                return false;
            }
        }
        return true;
    }

    return (
        <SwipeableDrawer
            // style={{width:"40%", minWidth:450}}
            PaperProps={{ style: { width: "40%", minWidth: 450, msOverflowStyle: "none", scrollbarWidth: "none", } }}
            open={props.isOpen}
            anchor='right'
            onClose={() => { onClose() }}
            onOpen={props.openDrawer}
        >
            <div style={{ flex: 20, padding: 15, }}>
                {props.config?.fields?.map((option, index) => {
                    switch (option.type) {
                        case GENERIC_DRAWER_TYPE.DIVIDER:
                            return <Divider key={(option.label != null ? option.label : option.value) + "_" + index} style={{ marginTop: 25, marginBottom: 25, color: "#676767" }}>{option.label != null ? option.label : option.value}</Divider>
                        case GENERIC_DRAWER_TYPE.TEXT:
                            if (option.asyncCheck != null) {
                                return <TextField error={option.error} 
                                    slotProps={{input: { max: 100, min: 0} } }
                                 InputProps={{
                                    endAdornment: option.loading && (
                                        <InputAdornment position="end">
                                            <CircularProgress size={15} />
                                        </InputAdornment>
                                    ),
                                }} /*inputProps={{maxLength: option.maxChar != null ? option.maxChar : undefined}}*/ helperText={option.helperText} type={option.isNumeric ? 'number' : option.isPhone ? 'tel' : option.passwordField ? 'password' : option.isPhone ? 'tel' : 'text'} key={option.label + "_" + index}
                                    onBlur={(evt) => {
                                        console.log(evt.target.value);
                                        setFieldLoading(index, true);
                                        option.asyncCheck().then((result) => { if (!result.success) { setFieldError(index, result.errorMsg) } }).catch(() => { setFieldLoading(index, false); }).finally(() => { setFieldLoading(index, false); });
                                    }} onChange={(evt) => { 
                                        updateField(option, index, evt.target.value, ) }
                                    } label={option.label} variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={option.value} disabled={option.disabled} />;
                                // setting maxLength through inputProps solves it for text, however fails on type=number! Therefore we prevent it on the updateField method, which is called upon the onchange event of the textfield.
                            } else {
                                return <TextField  /*inputProps={{maxLength: option.maxChar != null ? option.maxChar : undefined}}*/ error={option.error} helperText={option.helperText} type={option.isNumeric ? 'number' : option.isPhone ? 'tel' : option.isMail ? 'email' : option.passwordField ? 'password' : option.isPhone ? 'tel' : 'text'} key={option.label + "_" + index}
                                    onBlur={(evt) => {
                                        // console.log(evt.target.value)
                                        fieldIsValid(index, option, evt.target.value);
                                    }} onChange={(evt) => {  
                                        updateField(option, index, evt.target.value, option.maxChar) }} label={option.label} variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={option.value} disabled={option.disabled} />;
                            }
                        case GENERIC_DRAWER_TYPE.ENUM:
                            return (<FormControl error={option.error} key={option.label + "_" + index} style={{ marginTop: 15, width: "100%" }}>
                                <InputLabel id={option.label + "_" + index}>{option.label}</InputLabel>
                                <Select value={option?.value} labelId={option?.label + "_" + index} label={option?.label} onChange={(evt) => { updateField(option, index, evt.target.value) }}>
                                    {Object.keys(option.options).map(key => {
                                        return <MenuItem key={key} value={option.options[key]}>{key}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>)
                        case GENERIC_DRAWER_TYPE.EDITABLE:
                            // return <Typography>{JSON.stringify(option.fields) }</Typography>
                            return option.status != GD_STATUS_TYPE.DELETE && (<EditableField key={"GD_EDITABLE_" + index} fields={option.fields} isEditMode={option.isEditMode} setEditMode={setEditModeForEditable} deleteEntity={deleteEntity} updateFieldValue={updateEditableFieldValue} setEditableFieldError={setEditableFieldError} setFieldLoading={setEditableFieldLoading} optionData={option} editableIndex={index} />)
                        // return option.status != GD_STATUS_TYPE.DELETE && (<EditableField key={"GD_EDITABLE_"+index} fields={option.fields} values={option.values} fieldOptions={option.fieldOptions} isEditMode={option.isEditMode} setEditMode={setEditModeForEditable} deleteEntity={deleteEntity} updateFieldValue={updateEditableFieldValue} editableIndex={index}/>)
                        // editableField = a(fields, values, fieldOptions, isEditMode, setEditMode, deleteEntity, updateFieldValue, editableIndex)
                        // return option.status != GD_STATUS_TYPE.DELETE && (editableField(option.fields,option.values, option.fieldOptions, option.isEditMode, setEditModeForEditable, deleteEntity, updateEditableFieldValue, index ))
                        case GENERIC_DRAWER_TYPE.ENTITY_ADD:
                            return <EntityAdd key={"GD_EA_" + index} data={option} onSave={(entitykey, values) => { addEntity(entitykey, values); console.log("Entity (key=" + entitykey + ") add save data: ", values) }} />
                        case GENERIC_DRAWER_TYPE.SWITCH:
                            return <div style={{}}>
                                <Typography style={{ marginTop: 15, marginRight: 15 }}>{option.label}</Typography>
                                <Switch key={option.label + "_" + index} checked={option.value} onChange={(evt) => { updateField(option, index, evt.target.checked) }} />
                            </div>
                        case GENERIC_DRAWER_TYPE.IMAGE:
                            // The props.isOpen forces the ImageField to be rendered only if the drawer is open, thus rerendering the ImageField component everytime it opens...
                            // This is a small hack to ensure that the ImageField is rerendered everytime the drawer is opened, thus ensuring that the image is displayed correctly.
                            return props.isOpen && <ImageField helperText={option.helperText} myKey={"GD_IMG_"+index} baseUri={option.baseUri} relPath={option.value} label={option.label} dimensions={option.dimensions} onSelect={(blobUrl) => {updateField(option, index, blobUrl)}}/>
                        default:
                            return null;
                    }
                })}
            </div>
            <div style={{ flex: 1, display: "flex", width: "95%", paddingLeft: "2.5%", paddingLeft: "2.5%", justifyContent: "center", padding: 15 }}>
                {props.config?.options?.map((option, index) => {
                    if (index < 3) { // can display maximum three buttons / actions
                        return (option.icon != null ?
                            <Button onClick={() => { console.log("DO REQUIREDFIELDCHECK: ", option.doRequiredFieldCheck); if (option.onClick != null) { if (option.doRequiredFieldCheck == null) { option.onClick(config) } else if (doFinalChecksForFields()) { option.onClick(config) } } }} style={{ flex: 1, marginLeft: 10, marginRight: 10 }} key={"OPT_" + option.label + "_" + index} variant={option.variant != null ? option.variant : 'contained'} color={option.color != null ? option.color : 'primary'} startIcon={option.icon}>{option.label}</Button>
                            :
                            <Button onClick={() => {console.log("DO REQUIREDFIELDCHECK: ", option.doRequiredFieldCheck); if (option.onClick != null) { if (option.doRequiredFieldCheck == null) { option.onClick(config) } else if (doFinalChecksForFields()) { option.onClick(config) } } }} style={{ flex: 1, marginLeft: 10, marginRight: 10 }} key={"OPT_" + option.label + "_" + index} variant={option.variant != null ? option.variant : 'contained'} color={option.color != null ? option.color : 'primary'}>{option.label}</Button>
                        )
                    }
                })}
            </div>

        </SwipeableDrawer>
    )
}
