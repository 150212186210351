import { TableHead, TableRow } from '@mui/material'
import React, { Component } from 'react'
import { StyledTableCell } from './GenericTable'


export class NTTableHeader extends Component {
    render() {
        return (
            <TableHead>
                <TableRow >
                    <StyledTableCell sx={{ width: 50 }} >{/* THIS IS FOR THE COLLAPSE ARROW BUTTON*/}</StyledTableCell>
                    {this.props.onSetActive != null && this.props.activeKey != null &&
                        <StyledTableCell sx={{ width: 25, textAlign: "center !important" }}>Aktiv</StyledTableCell>
                    }
                    
                    {this.props.columns.map((column) => {
                        return <StyledTableCell sx={column.width != null ? { width: column.width } : {}} key={column.dataKey}  >{column.label}</StyledTableCell>
                    })}
                    {this.props.onDelete != null &&
                        <StyledTableCell sx={{ width: 25 }} >{/* THIS IS FOR THE DELETE BUTTON*/}</StyledTableCell>
                    }
                    {this.props.onEdit != null &&
                        <StyledTableCell sx={{ width: 25 }} >{/* THIS IS FOR THE EDIT BUTTON*/}</StyledTableCell>
                    }
                    {this.props.onPositionChange != null &&
                        <StyledTableCell sx={{ width:15, textAlign: "center !important" }}></StyledTableCell>
                    }
                    {this.props.onPositionChange != null &&
                        <StyledTableCell sx={{ width:15,}} >{/* THIS IS FOR THE POSITION CHANGE BUTTON*/}</StyledTableCell>
                    }
                </TableRow>
            </TableHead>
        )
    }
}

export default NTTableHeader
