export const APIData = {
    "SHOP": "https://viveel.de:3000",
    "USER": "https://viveel.de:3001",
    "REALTIME": "https://viveel.de:3002",
    "MEDIA": "https://viveel.de:3003",
    "MEDIA_TYPES" : 
        {
            PRODUCTS: "products", 
            CATEGORIES: "categories",
            BACKGROUNDS: "backgrounds", 
            HEADERS: "headers", 
            ICONS: "icons"
        }
}