import { Delete } from '@mui/icons-material'
import { Button, Divider, IconButton, Typography } from '@mui/material'
import React, { Component } from 'react'

export class OpeningHoursButton extends Component {
    render() {
        return (
            <div style={{ textDecoration: "none", flexGrow: 1, flexDirection: "column", maxWidth: 250,  borderRadius: 4, display: "flex", border:'0.5px solid #dadfe3', justifyContent: "center", alignItems: "center", flexBasis: 250, backgroundColor: "#ebf1f5" }}>
                <div style={{display:"flex", flexDirection:"column", padding:10}}>
                    <Typography style={{textAlign:"center", fontSize:"14px"}}><b>Von: </b>{this.props.config.opensAt.substring(0,5)}</Typography>
                    <Typography style={{textAlign:"center", fontSize:"14px"}}><b>Bis: </b>{this.props.config.closesAt.substring(0,5)}</Typography>
                </div>
                <Button sx={{borderTop:'0.5px solid #dadfe3'}} style={{width:"100%"}} onClick={() => { this.props.onDelete() }} startIcon={<Delete style={{color:'#cc6056'}}/>}>Löschen</Button>
            </div>
        )
    }
}

export default OpeningHoursButton