import { ImageNotSupported } from '@mui/icons-material';
import { Button, Typography } from '@mui/material'
import React, { Component } from 'react'

export class ImageField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgLoadErr: false,
            localSelection: null,
            borderColor: '#C4C4C4'
        }
        this.errColor = '#d3302f';
        // #d3302f
    }
    handleFileChange(e) {
        const file = e.target.files[0];
        // const resizedFile = await resizeImage(file, 500, 500);
        const width = this.props.dimensions?.width != null ? this.props.dimensions.width : 500;
        const height = this.props.dimensions?.height != null ? this.props.dimensions.height : 500;

        // const width = this.props.dimensions.width;
        this.resizeImage(file, width, height).then((resizedFile) => {
            this.setState({ localSelection: resizedFile });
        });
        // setFile(resizedFile);
    }
    componentDidMount() {
        console.log("LOCALSELECTION: ", this.state.localSelection);
    }
    resizeImage(file, targetWidth, targetHeight) {
        const targetAspectRatio = targetWidth / targetHeight;

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const imgAspectRatio = img.width / img.height;
                    let cropWidth, cropHeight;

                    // Determine the crop dimensions to maintain the aspect ratio
                    if (imgAspectRatio > targetAspectRatio) {
                        // Image is wider than the target aspect ratio
                        cropHeight = img.height;
                        cropWidth = cropHeight * targetAspectRatio;
                    } else {
                        // Image is taller than the target aspect ratio
                        cropWidth = img.width;
                        cropHeight = cropWidth / targetAspectRatio;
                    }

                    // Calculate the position to start the crop from the center
                    const cropX = (img.width - cropWidth) / 2;
                    const cropY = (img.height - cropHeight) / 2;

                    // Create a canvas and resize the image
                    const canvas = document.createElement('canvas');
                    canvas.width = targetWidth;
                    canvas.height = targetHeight;
                    const ctx = canvas.getContext('2d');

                    // Draw the cropped image, then resize it to target dimensions
                    ctx.drawImage(
                        img,
                        cropX, cropY,             // Start cropping from (cropX, cropY)
                        cropWidth, cropHeight,     // Crop size (width and height)
                        0, 0,                     // Start drawing on the canvas at (0, 0)
                        targetWidth, targetHeight  // Resize to target width and height
                    );

                    // Convert the canvas back to a Blob and resolve the Promise with the new file
                    canvas.toBlob((blob) => {
                        // console.log("Blobfile: ", file.name);
                        // console.log("Blobtype: ", file.type);
                        // console.log("Blob: ", new File([blob], file.name, { type: file.type }));
                        this.props.onSelect(new File([blob], file.name, { type: file.type }));
                        resolve(new File([blob], file.name, { type: file.type }));
                    }, file.type);
                };
                img.src = event.target.result;
            };
            reader.onerror = (err) => reject(err);
            reader.readAsDataURL(file);
        });
    };

    render() {
        return (
            <div onMouseEnter={() => {this.setState({borderColor: '#353535'})}} onMouseLeave={() => {this.setState({borderColor:'#c4c4c4'})}} style={{ marginTop: 15, display:"flex", flexDirection:"column", gap:5 }}>
                <div onClick={() => { this.fileInput.click() }} style={{ display: "flex", flexDirection: "row", border: '1px solid '+(this.props.helperText?.length > 0 ? this.errColor : this.state.borderColor), borderRadius: 4, padding: 10,}}>
                    <div style={{ width: 60, display: "flex", alignItems: "center" }}>
                        {this.state.imgLoadErr || ((this.props.baseUri?.length == 0 || this.props.relPath?.length == 0) && this.state.localSelection == null) ?
                            <ImageNotSupported style={{ width: 40, height: 40 }} /> :
                            <img onError={() => { this.setState({ imgLoadErr: true }) }} src={this.state.localSelection != null ? URL.createObjectURL(this.state.localSelection) : (this.props.baseUri + this.props.relPath)} alt={"Bild!"} style={{ width: 40, height: 40, objectFit: 'cover', borderRadius: 20 }} />
                        }
                    </div>
                    <div style={{ flex: 5, display: "flex", alignItems: "center" }}>
                        <Typography style={{ color: "#353535" }}>{this.props.label}</Typography>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Button >Ändern</Button>
                        <input type="file" ref={(input) => this.fileInput = input} onChange={(e) => { this.handleFileChange(e) }} style={{ display: "none" }} />
                        {/* {this.state.localSelection && (
                        <img src={URL.createObjectURL(file)} alt="Resized Preview" />
                        )} */}
                    </div>
                </div>
                {this.props.helperText?.length > 0 &&
                    <Typography style={{ color: this.errColor, fontSize: 12, marginLeft: 14 }}>{this.props.helperText}</Typography>
            }   
            </div>
        )
    }
}

export default ImageField