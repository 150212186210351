import { Component } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, CircularProgress, Divider, Typography } from '@mui/material';
import { MUI_BTN_VARIANTS, MUI_COLORS } from '../constants/MUIConstants';

const DEFAULTMARGIN = 20;

class CollapsibleTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAccordionOpen: this.props.defaultExpanded || false, // Initialize based on defaultExpanded prop
        };
        this.firstExpand= true;
    }

    handleAccordionChange = (event, isExpanded) => {
        this.setState({ isAccordionOpen: isExpanded });
        
        console.log("FIRSTEXPAND: ", this.firstExpand);
        this.props.onCollapseChange(isExpanded, this.firstExpand);
        if(isExpanded && this.firstExpand){
            this.firstExpand = false;
        }
    };

    render() {
        const { isAccordionOpen } = this.state;
        const { isLoading } = this.props;

        return (
            <div style={{ border: "0.5px solid #cecece",  borderRadius: 5, }}>
                <Accordion
                    expanded={isAccordionOpen}
                    onChange={this.handleAccordionChange}
                    style={{ padding: DEFAULTMARGIN  }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ padding: '0px' }} // Removing padding from AccordionSummary
                    >
                        <div style={{ width: "100%" }}>
                            <div style={{ display: "flex", alignItems:"center", flexDirection: "row" }}>
                                {this.props.icon != null &&
                                    <div style={{ marginRight: 15 }}>
                                        {this.props.icon}
                                    </div>
                                }
                                <Typography style={{ fontSize: 18, fontWeight: "500", color: '#353535' }}>{this.props.title}</Typography>
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: '0px' }}>
                        <Divider style={{ width: "100%" }} />
                        <div style={{ width: "100%",overflowX:'scroll', scrollbarWidth:'none' }}>
                            <div style={{ marginTop: DEFAULTMARGIN, marginBottom: DEFAULTMARGIN, padding:2 }}>
                                {this.props.children}
                            </div>
                            {this.props.actions != null &&
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: 10, marginTop: DEFAULTMARGIN }}>
                                    {this.props.actions?.map((action, index) => (
                                        <Button
                                            key={index}
                                            startIcon={action.icon}
                                            disabled={action.disabled}
                                            onClick={action.onClick}
                                            variant={action.variant ? action.variant : MUI_BTN_VARIANTS.CONTAINED}
                                            color={action.color ? action.color : MUI_COLORS.PRIMARY}
                                        >
                                            {action.label}
                                        </Button>
                                    ))}
                                </div>
                            }
                        </div>
                    </AccordionDetails>
                    {isLoading && isAccordionOpen && (
                        <div onClick={() => {this.setState({isAccordionOpen: false})}} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", borderRadius: 4, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(0,0,0,0.2)" }}>
                            <CircularProgress size={25} />
                        </div>
                    )}
                </Accordion>
            </div>
        );
    }
}

export default CollapsibleTile;
