import React, { Component } from 'react'
import { SENDERTYPE } from '../../../redux/supportSlice'
import { Paper, Typography } from '@mui/material'
// createdAt, content, senderType
export class SupportMessageDisplay extends Component {
    // shouldComponentUpdate(){
    //     return false;
    // }
    render() {
        return (
            <div style={{width:"100%", display:"flex", justifyContent: this.props.data.senderType === SENDERTYPE.USER ? "left" : "right"}}>
                <Paper elevation={3} style={{ width: "70%", maxWidth:400, padding: 15, marginTop: 25, borderRadius: 10, display: "flex", flexDirection: "column", backgroundColor: this.props.data.senderType === SENDERTYPE.USER ? "#4888ff" : "#FFFFFF" }}>
                    <Typography style={{ color: (this.props.data.senderType === SENDERTYPE.USER ? "#ffffff" : "#1D1D1D") }} fontSize="small">{this.props.data.content}</Typography>
                    <Typography style={{ color: (this.props.data.senderType === SENDERTYPE.USER ? "#e0e0e0" : "#828282"), textAlign: "right" }} fontSize="small">{new Date(this.props.data.createdAt).toLocaleTimeString("de", { hour: "2-digit", minute: "2-digit", hour12: false })}</Typography>
                </Paper>
            </div>
        )
    }
}

export default SupportMessageDisplay
