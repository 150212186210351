import { Check, Close, Info } from '@mui/icons-material'
import { Alert } from '@mui/material'
import React, { useEffect } from 'react'
export const ALERT_SEVERITY = {
    ERROR: "error",
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning"
}
export const AlertDisplay2 = (props) => {
    // const [isVisible, setIsVisible] = useState(true);

    // useEffect(() => {
    //     const timeoutId = setTimeout(() => {
    //         setIsVisible(false);
    //     }, 3000);
    //     return () => clearTimeout(timeoutId);
    // }, []);
    useEffect(() => {
        // console.log("PropsVisibleHasChanged: ",props.isVisible);
        if (props.isVisible == true) {

            setTimeout(() => {
                props.hide();
            }, 3000);

        }
        // return () => {
        //     clearTimeout(timeoutToHide);
        // }
    }, [props.isVisible])
    return (
        <div style={{ position: 'absolute', left: 20, bottom: 20, opacity: props.isVisible ? 1 : 0, transition: 'opacity 0.5s ease-in-out', }}>
            <Alert icon={ props.severity == null ? <Info fontSize='inherit'/> : props.severity == ALERT_SEVERITY.ERROR ? <Close fontSize='inherit'/> : <Check fontSize="inherit" />} severity={ props.severity == null ? "info" : props.severity}>
                {props.label}
            </Alert>
            
        </div>
    )
}
