import { TabPanel } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { Component, useRef } from 'react'
import { APIData } from '../../../../constants/APIAdresses';
import { connect } from 'react-redux';
import { addressComponent } from './UserInfoTab';
import { SUPPORTCHAT_INFOTAB, addOrderPositionToOrderListAndIncreaseOffset, addToOrderListAndIncreaseOffset, setOrderOffset } from '../../../../redux/supportSlice';
import OrderPositionDisplay from '../OrderPositionDisplay';
import { Save } from '@mui/icons-material';
import OrderStatusSelector from './Elements/OrderStatusSelector';

export class OrdersTab extends Component {
    constructor(props) {
        super(props);
        this.offset = 0;
        this.state = {
            expandedId: -1,
            loadMoreEnabled: true,
            orderPositions: [],
            discountToAdd: 0.0,
            discountTxtField: "",
        }
    }
    loadOrders() {
        let body = {
            userId: this.props.data?.userId,
            sessionId: this.props.data.sessionId,
            offset: this.props.data?.orderOffset
        }
        fetch(APIData.USER + "/order/getAll", { credentials: "include", method: "post", body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            }).then(result => {
                if (result != null) {
                    result = result.map(order => { return { ...order, orderPositions: [], oPosOffset: 0 } })
                   
                }
                this.props.addToOrderListAndIncreaseOffset(this.props.activeCaseIndex, result);
            }).catch(e => {
                console.log("Error while loadOrders from OrdersTab: ", e);
            })
    }
    loadOrderPositions(index, oid, shopId, offset) {
        fetch(APIData.USER + `/order/${oid}/getPositions?shopId=${shopId}&offset=${offset == null ? 0 : offset}`, { credentials: "include" })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            }).then(result => {
                console.log("OPOSRES: ",result);
                this.props.addOrderPositionToOrderListAndIncreaseOffset(this.props.activeCaseIndex, index, result);
            }).catch(e => {
                console.log("Error while loading orderPositions...")
            })
    }
    componentDidUpdate() {
        if (this.props.tabActive && this.props.data?.orderOffset == 0) {
            this.loadOrders();
        }
    }
    onDiscountKeyDown(e) {
        if (e.key === "Enter") {
            // this.setState({discountTxtField: this.state e.target.value})
            e.target.blur();
            // this.discountTxtFieldRef.blur();
            if (!isNaN(parseFloat(this.state.discountTxtField))) {
                let myDiscount = parseFloat(this.state.discountTxtField.replace("-", "").replace("e", ""))
                myDiscount = Math.sqrt(myDiscount * myDiscount);
                this.setState({ discountToAdd: myDiscount, discountTxtField: "" });
            }
        }
    }
    onDiscountChange(e) {

        console.log("TARGETVALUE: ", e.nativeEvent.target.value);
        if (!e.target.value.includes("e") && !e.target.value.includes("-")) {
            this.setState({ discountTxtField: e.target.value })
        }
    }
    save(orderIndex) {
        console.log("PROPSDATA: ", this.props.data);
        let hasUpdates = false;
        let body = {
            id: this.props.data.orderList[orderIndex].id,
            shopId: this.props.data.orderList[orderIndex].shopId,
            // status : null,
            // discount: null, 
            quantities: {/* position: qty */ },
        }
        this.props.data.orderList[orderIndex]?.orderPositions.forEach(oPos => {
            if (oPos.update?.quantity != null && !isNaN(parseInt(oPos.update?.quantity)) && parseInt(oPos.update?.quantity) != 0) {
                hasUpdates = true;
                body.quantities[oPos.position] = oPos.quantity + oPos.update?.quantity;
            }
        })
        if (this.state.discountToAdd > 0.0) {
            hasUpdates = true;
            body.discount = this.state.discountToAdd;
        }
        if(this.props.data.orderList[orderIndex].update?.status != null && this.props.data.orderList[orderIndex].update?.status != this.props.data.orderList[orderIndex].status){
            body.status = this.props.data.orderList[orderIndex].update.status;
            hasUpdates=true;
        }
        if(hasUpdates){
            // console.log("There are changes. Sending to server.");
            fetch(APIData.USER+"/order/update", {credentials:"include", method:"POST", body: JSON.stringify(body), headers:{"Content-Type": "application/json"}})
            .then(response=> {
                if(response.ok){
                    alert("Die Änderungen wurden übernommen.");
                }else{
                    alert("Es ist etwas schiefgelaufen.");
                }
            }).catch(()=>{
                // console.log("Error while")
                alert("Beim Speichern der Daten für die Bestellung ist ein Fehler aufgetreten.");
            })
        }
    }
    render() {
        return (
            <TabPanel value={SUPPORTCHAT_INFOTAB.ORDERS.toString()}>

                {this.props.data?.orderList?.map((order, index) => {
                    return (
                        <Accordion expanded={this.state.expandedId === order.id} onChange={() => { if (this.state.expandedId !== order.id) { this.setState({ expandedId: order.id }) } else { this.setState({ expandedId: -1 }) } }} key={"TabOrderDetailDisplay_" + order.id}>
                            <AccordionSummary>
                                <Typography>Bestellung {order.id} <Typography style={{ fontSize: 12, color: '#676767' }}>{new Date(order.createDate).toLocaleDateString("de", { year: "numeric", month: "2-digit", day: "2-digit" })} -  {new Date(order.createDate).toLocaleTimeString("de", { hour: "2-digit", minute: "2-digit", hour12: false })}</Typography></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {addressComponent(order, 0)}
                                <OrderStatusSelector index={index} value={order.update?.status != null ? order.update.status : order.status} deliveryOption={order.deliveryOption}/>
                                <Typography style={{ marginTop: 20, fontSize: 12 }}>Zahlungsart: {order.paymentMethod}</Typography>
                                <Typography style={{ marginTop: 10, fontSize: 12 }}>Gesamtbetrag: {parseFloat(order.totalAmount).toFixed(2)} €</Typography>
                                <Typography style={{ marginTop: 10, fontSize: 12 }}>Lieferart: {order.deliveryOption === "0" ? "Lieferung" : "Abholung"} </Typography>
                                <Typography style={{ marginTop: 10, fontSize: 12 }}>Rabatt: {order.discount?.toString()?.length > 0 ? order.discount.toFixed(2).replace(".", ",") : this.state.discountToAdd > 0 ? this.state.discountToAdd.toFixed(2).replace(".", ",") : "-"} €</Typography>
                                <div style={{ display: "flex", marginTop: 10 }}>
                                    <TextField ref={(ref) => { this.discountTxtFieldRef = ref }} type='number' variant="outlined" min={0} onChange={(e) => { this.setState({ discountTxtField: e.target.value }) }} value={this.state.discountTxtField} label="Rabatt Hinzufügen" onKeyDown={(e) => { this.onDiscountKeyDown(e) }} style={{ flex: 3 }} />
                                    {/* <Button variant="outlined" style={{flex:1}} onClick={()=> {this.onDiscountKeyDown({key:"Enter", target: this.discountTxtFieldRef})}}>Speichern</Button> */}
                                </div>

                                {order.deliveryDate != null &&
                                    <Typography style={{ marginTop: 10, fontSize: 12 }}>Vorbestellung: {new Date(order.deliveryDate).toLocaleDateString("de", { year: "numeric", month: "2-digit", day: "2-digit" })} -  {new Date(order.deliveryDate).toLocaleTimeString("de", { hour: "2-digit", minute: "2-digit", hour12: false })}</Typography>
                                }
                                {order.hasConflict !== 0 &&
                                    <Typography style={{ marginTop: 10, fontSize: 12 }}>Bestellung hat einen Konflikt ⚠️</Typography>
                                }
                                {order.note != null &&
                                    <Typography style={{ marginTop: 10, fontSize: 12 }}>Notiz: {order.note}</Typography>
                                }
                                {order?.orderPositions?.length > 0 &&
                                    <>
                                        <Divider sx={{ borderStyle: "dashed" }} style={{ color: "#DADADA", fontSize: 12, marginTop: 15, marginBottom: 15 }}>Bestellpositionen</Divider>
                                        {
                                            order.orderPositions.map((oPos, oPosIndex) => {
                                                
                                                return !oPos.deleted && <OrderPositionDisplay data={oPos} oPosIndex={oPosIndex} orderIndex={index} key={"OPosDisplay_" + order.id + "_" + oPos.position} />
                                            })}
                                    </>
                                }
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
                                    {(order.orderPositions == null || order.oPosOffset != -1) &&
                                        <Button onClick={() => this.loadOrderPositions(index, order.id, order.shopId, order.oPosOffset)}>{order.orderPositions?.length == 0 ? "Positionen Laden" : order.oposOffset != -1 ? "Mehr Laden" : ""}</Button>
                                    }
                                    <Button onClick={() => { this.save(index) }} variant='contained' startIcon={<Save />}>Speichern</Button>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
                {this.props.data?.orderOffset !== -1 &&
                    <Button onClick={() => { this.loadOrders() }} style={{ marginTop: 15 }}>Mehr Laden</Button>
                }
            </TabPanel>
        )
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.support.activeCaseList[state.support.chatCaseIndex]?.userInfo,
        data: state.support.activeCaseList[state.support.chatCaseIndex],
        activeCaseIndex: state.support.chatCaseIndex,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addToOrderListAndIncreaseOffset: (index, data) => {
            dispatch(addToOrderListAndIncreaseOffset({ index: index, data: data }));
        },
        setOrderOffset: (index, value) => {
            dispatch(setOrderOffset({ index: index, value: value }));
        },
        addOrderPositionToOrderListAndIncreaseOffset: (index, oIndex, data) => {
            dispatch(addOrderPositionToOrderListAndIncreaseOffset({ index: index, oIndex: oIndex, data: data }));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTab)
