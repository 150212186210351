import { TabPanel } from '@mui/lab'
import React, { Component } from 'react'
import { SUPPORT_TAB } from '../SupportOverview'
import { Typography } from '@mui/material'
import SupportCaseButton from '../Elements/SupportCaseButton'
import { connect } from 'react-redux'

export class OpenSupportCasesTabContent extends Component {
    render() {
        return (
            <TabPanel value={SUPPORT_TAB.OPEN.toString()}>
                <Typography>Offene Supportfälle ({this.props.caseList.length})</Typography>
                <div style={{ display:"flex", flexWrap:"wrap", justifyContent:"center", marginTop:"2.5%"}}>
                    {this.props.caseList?.map(item => {
                        return <SupportCaseButton key={"SupporCaseBtn_"+item.id} id={item.id} data={item}></SupportCaseButton>
                    })}
                </div>
            </TabPanel>
        )
    }
}
function mapStateToProps(state) {
    return {
        caseList: state.support.openCaseList
    }
}
export default connect(mapStateToProps)( OpenSupportCasesTabContent);
