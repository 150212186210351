import React, { Component } from 'react'
import DefaultFrame from '../../components/DefaultFrame'
import TileButton from '../../components/TileButton';
import { Typography } from '@mui/material';
import { Tune } from '@mui/icons-material';
import { withParams } from '../../hookInjection/withParams';
import CategoriesTile from './ShopContent/CategoriesTile';
import CollectionsTile from './ShopContent/CollectionsTile';

export class ContentManagementView extends Component {
    constructor(props) {
        super(props);
        this.companyId = this.props.params.companyId;
        this.shopId = this.props.params.shopId;
    }
    render() {
        return (

            <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                <CollectionsTile shopId={this.shopId} {...this.props} />
                <CategoriesTile shopId={this.shopId} openAlertDialog={this.props.openAlertDialog} showAlert={this.props.showAlertDisplay}  openDrawer={this.props.openDrawer} closeDrawer={this.props.closeDrawer}/>

                {/* <Typography variant="h4">Shopkonfiguration</Typography>
                    <TileButton path="/support" title="Grundkonfiguration" iconSrc={require('../../assets/icons/tune.png')} />
                    <TileButton path="/support" title="Produktkonfiguration" iconSrc={require("../../assets/icons/dairyproducts.png")} />
                    Test */}
            </div>
        )
    }
}

class defaultWrapper extends React.Component {
    render() {
        return (
            <DefaultFrame title="Content Management" noPermissionCheck>
                <ContentManagementView {...this.props} />
            </DefaultFrame>
        )
    }
}
export default withParams(defaultWrapper);