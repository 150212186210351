import { Badge, Button, Typography } from '@mui/material'
import React, { Component } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';
import { removeActiveCaseByIndex, resetUnreadItemsCountForCaseAtIndex, setChatDialogOpen, setCurrentChatCaseIndex } from '../../../../redux/supportSlice';

export class SupportCaseBarItem extends Component {
    render() {
        return (
            <div style={{ display: "flex", marginLeft: 5, flexDirection: "row", justifyContent: "center", alignItems: "center", border: "1px solid #dadada", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottom: 0, height: 50, width: 250, minWidth: 250, maxWidth: 300 }}>
                <Badge badgeContent={this.props.unreadItems != null ? this.props.unreadItems : 0} color="primary" style={{flex: 12, height: "100%"}}>
                    <Button onClick={() => { this.props.resetUnreadItemsAtIndex(this.props.index); this.props.openChatDialog(this.props.index) }} style={{ border: 0, display: "flex", justifyContent: "center", alignItems: "center", width:"100%"  }}>
                        <Typography style={{ fontSize: "small" }}>Fall {this.props.caseId}</Typography>
                    </Button>
                </Badge>
                <Button onClick={() => { this.props.onClose(); this.props.removeActiveCaseByIndex(this.props.index) }} style={{ border: 0, display: "flex", justifyContent: "center", alignItems: "center", flex: 1, height: "100%" }}>
                    <CloseIcon />
                </Button>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        caseList: state.support.activeCaseList,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        removeActiveCaseByIndex: (index) => {
            dispatch(removeActiveCaseByIndex(index));

        },
        openChatDialog: (index) => {
            dispatch(setCurrentChatCaseIndex(index));
            dispatch(setChatDialogOpen(true));
        },
        resetUnreadItemsAtIndex: (index) => {
            dispatch(resetUnreadItemsCountForCaseAtIndex(index));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SupportCaseBarItem)
