import React, { Component } from 'react'
import CollapsibleTile from '../../../components/CollapsibleTile'
import { APIData } from '../../../constants/APIAdresses';
import { ALERT_SEVERITY } from '../../../components/AlertDisplay2';
import { Autocomplete, Button, TextField, Typography } from '@mui/material';
import OpeningHoursButton from './OpeningHoursButton';
import { MUI_COLORS } from '../../../constants/MUIConstants';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { MoreTime } from '@mui/icons-material';

const daysOfWeek = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
// const daysOfWeek = []
//     2: "Montag",
//     3: "Dienstag",
//     4: "Mittwoch",
//     5: "Donnerstag",
//     6: "Freitag",
//     7: "Samstag",
//     1: "Sonntag"
// }
// const dayAutoCompl
const dayOptions = [
    { label: 'Montag', value: 2 },
    { label: 'Dienstag', value: 3 },
    { label: 'Mittwoch', value: 4 },
    { label: 'Donnerstag', value: 5 },
    { label: 'Freitag', value: 6 },
    { label: 'Samstag', value: 7 },
    { label: 'Sonntag', value: 1 }
]
export class OpeningHoursTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            openingHours: [],
            weekdayFrom: dayOptions[0],
            weekdayTo: dayOptions[0],
            hourFrom: dayjs().hour(10).minute(0).second(0).millisecond(0),
            hourTo: dayjs().hour(19).minute(0).second(0).millisecond(0)
        }
        console.log("DAYSOFWEEKKEYS: ", Object.keys(daysOfWeek));
    }
    loadOpeningHours() {
        this.setState({ loading: true });
        fetch(APIData.SHOP + `/shop/${this.props.shopId}/openingHours`, { credentials: "include", headers: { "Content-Type": "application/json" } })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            }).then(result => {
                if (result) {
                    if (result.length > 0) {
                        this.setState({
                            loading: false,
                            openingHours:
                                result.sort((a, b) => { // this sorts the days starting with monday... So basically dayOfWeek 1 goes to the end of the array
                                    return (a.dayOfWeek === 1 ? 7 : a.dayOfWeek) - (b.dayOfWeek === 1 ? 7 : b.dayOfWeek);
                                })
                        });
                    } else {
                        this.setState({ loading: false });
                    }
                } else {
                    this.setState({
                        loading: false
                    });
                    this.props.showAlert("Öffnungszeiten konnten nicht geladen werden", ALERT_SEVERITY.ERROR);
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.showAlert("Öffnungszeiten konnten nicht geladen werden", ALERT_SEVERITY.ERROR);
                console.log("ERROR while loading openinghours: ", error);
            })
    }
    deleteOpeningHour(id) {
        // console.log("OH wird gelöscht... ", id);

        let index = this.state.openingHours.findIndex(oh => oh.id === id);
        if (index !== -1) {

            let myOpeningHours = [...this.state.openingHours];
            myOpeningHours.splice(index, 1);
            this.setState({
                loading: true,
                openingHours: [...myOpeningHours]
            })
            fetch(APIData.SHOP + `/shop/${this.props.shopId}/deleteOpeningHours/${this.state.openingHours[index].id}`, { credentials: "include", headers: { "Content-Type": "application/json" } })
                .then(response => {
                    if (!response.ok) {
                        myOpeningHours.splice(index, 0, this.state.openingHours[index]);
                        this.setState({
                            loading: false,
                            openingHours: [...myOpeningHours]
                        })
                    } else {
                        this.setState({
                            loading: false
                        })
                        this.props.showAlert("Öffnungszeiten wurden gelöscht.", ALERT_SEVERITY.SUCCESS);
                    }
                }).catch(e => {
                    console.log("Error while deleting opening hours: ", e);
                    this.props.showAlert("Öffnungszeiten konnten nicht gelöscht werden.", ALERT_SEVERITY.ERROR);
                })
        }
    }

    addOpeningHours() {
        // console.log("OH wird hinzugefügt...");
        // let body = [
        //     {
        //         dow: 2,
        //         opensAt: "10:00",
        //         closesAt: "19:00",
        //         tempId: Math.random().toString(36).substring(7),
        //     }, {
        //         dow: 3,
        //         opensAt: "10:00",
        //         closesAt: "19:00",
        //         tempId: Math.random().toString(36).substring(7),
        //     },
        //     {
        //         dow: 4,
        //         opensAt: "10:00",
        //         closesAt: "19:00",
        //         tempId: Math.random().toString(36).substring(7),
        //     },
        //     {
        //         dow: 5,
        //         opensAt: "10:00",
        //         closesAt: "19:00",
        //         tempId: Math.random().toString(36).substring(7),
        //     },
        //     {
        //         dow: 6,
        //         opensAt: "10:00",
        //         closesAt: "23:00",
        //         tempId: Math.random().toString(36).substring(7),
        //     },
        //     {
        //         dow: 7,
        //         opensAt: "10:00",
        //         closesAt: "14:00",
        //         tempId: Math.random().toString(36).substring(7),
        //     },
        //     {
        //         dow: 7,
        //         opensAt: "17:00",
        //         closesAt: "23:00",
        //         tempId: Math.random().toString(36).substring(7),
        //     }
        // ]

        let body = []
        let dayOptionStartIndex = dayOptions.findIndex((el) => el.value === this.state.weekdayFrom?.value);
        let dayOptionEndIndex = dayOptions.findIndex((el) => el.value === this.state.weekdayTo?.value);
        for(var i = 0; i < (dayOptionEndIndex- dayOptionStartIndex+1); i++){
            body.push({
                dow: dayOptions[dayOptionStartIndex+i].value,
                opensAt: this.state.hourFrom.format("HH:mm"),
                closesAt: this.state.hourTo.format("HH:mm"),
                // tempId: Math.random().toString(36).substring(7)
            })
        }
        console.log("BODY: ", body);
        this.setState({ loading: true });
        fetch(APIData.SHOP + `/shop/${this.props.shopId}/addOpeningHours`, { credentials: "include", headers: { "Content-Type": "application/json" }, method: "POST", body: JSON.stringify(body) })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            }).then(result => {
                if (result != null) {
                    this.loadOpeningHours();
                } else {
                    this.setState({ loading: false });
                    this.props.showAlert("Öffnungszeiten konnten nicht hinzugefügt werden", ALERT_SEVERITY.ERROR);
                    console.log("ERROR while adding opening hours: ", result);
                }
            }).catch(e => {
                this.setState({ loading: false });
                this.props.showAlert("Öffnungszeiten konnten nicht hinzugefügt werden", ALERT_SEVERITY.ERROR);
                console.log("ERROR while adding opening hours: ", e);
            })
    }
    render() {
        return (
            <CollapsibleTile title="Öffnungszeiten"
                isLoading={this.state.loading}
                onCollapseChange={(isExpanded, firstExpand) => {
                    console.log("ONCOLLAPSE???")
                    if (isExpanded && firstExpand) {
                        this.loadOpeningHours();
                    }
                }}
                icon={<img src={require('../../../assets/icons/workingtime.png')} width={20} height={20} />}>
                <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>

                    <div style={{ display: "flex", border: '0.5px solid #cecece', padding: 20, flexDirection: "column", gap: 10 }}>
                        <Typography fontStyle="italic">Hinzufügen</Typography>
                        <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                            <div style={{ display: "flex", flex: 1, flexDirection: "column", gap: 10 }}>
                                <Autocomplete
                                    value={this.state.weekdayFrom}
                                    onChange={(evt, newValue) => {
                                        let weekdayToIndex = dayOptions.findIndex((el) => el.value === this.state.weekdayTo?.value);
                                        let weekdayFromIndex = dayOptions.findIndex((el) => el.value === newValue?.value);
                                        this.setState({ weekdayTo: weekdayFromIndex > weekdayToIndex ? newValue : this.state.weekdayTo, weekdayFrom: newValue });
                                    }}
                                    options={dayOptions}
                                    renderInput={(params) => <TextField {...params} label="Wochentag (Von)" />}
                                />
                                <Autocomplete
                                    value={this.state.weekdayTo}
                                    onChange={(evt, newValue) => {
                                        let weekdayToIndex = dayOptions.findIndex((el) => el.value === newValue?.value);
                                        let weekdayFromIndex = dayOptions.findIndex((el) => el.value === this.state.weekdayFrom?.value);
                                        this.setState({ weekdayTo: newValue, weekdayFrom: weekdayFromIndex > weekdayToIndex ? newValue : this.state.weekdayFrom });
                                    }}
                                    options={dayOptions}
                                    renderInput={(params) => <TextField {...params} label="Wochentag (Bis)" />}
                                />
                            </div>
                            <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 10 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        ampm={false}
                                        label="Uhrzeit (Von)"
                                        value={this.state.hourFrom}
                                        maxTime={
                                            dayjs(this.state.hourTo).hour() == 0 && dayjs(this.state.hourTo).minute() > 30 || dayjs(this.state.hourTo).hour() > 0 ?
                                            dayjs(this.state.hourTo).subtract(30, 'minute') : 
                                            this.state.hourTo
                                        }
                                        onChange={(newValue) => { this.setState({ hourFrom: newValue }) }}
                                    />
                                    <TimePicker
                                        ampm={false}
                                        minTime={
                                            dayjs(this.state.hourFrom).hour() == 23 && dayjs(this.state.hourFrom).minute() > 30 || dayjs(this.state.hourFrom).hour() < 23 ?
                                            dayjs(this.state.hourFrom).add(30, 'minute') :
                                            this.state.hourFrom
                                        }
                                        label="Uhrzeit (Bis)"
                                        value={this.state.hourTo}
                                        onChange={(newValue) => { 

                                            this.setState({ hourTo: newValue }) 
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <Button variant='contained' onClick={() => { this.addOpeningHours() }} startIcon={<MoreTime />}>Öffnungszeit Hinzufügen</Button>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: 15 }}>
                        {

                            daysOfWeek.map((day, dowIndex) => {
                                return (
                                    <div style={{ flex: 1 }}>
                                        <Typography style={{ fontWeight: "bold" }}>{day}</Typography>
                                        <div style={{ display: "flex", marginTop: 10, flexWrap: "wrap", gap: 20 }}>
                                            {
                                                this.state.openingHours.filter(oh => {
                                                    return oh.dayOfWeek === (dowIndex == 6 ? 1 : dowIndex + 2);
                                                }).length === 0 ?
                                                    <div style={{ flexGrow: 1, padding: 20, borderRadius: 4, border: '0.5px solid #dadfe3', }}>
                                                        <Typography style={{ fontSize: 14, color: "#353535" }}>Keine Öffnungszeiten...</Typography>
                                                    </div>
                                                    :
                                                    this.state.openingHours.filter(oh => {
                                                        return oh.dayOfWeek === (dowIndex == 6 ? 1 : dowIndex + 2);
                                                    }).map((oh, index) => {
                                                        // console.log("OH: ", oh.dayOfWeek, "DOWINDEX: ", dowIndex, "objKey: ", Object.keys(daysOfWeek));
                                                        return (
                                                            <OpeningHoursButton key={"ohBtn_" + oh.id + "_i" + dowIndex + "_" + index}
                                                                config={oh}
                                                                onDelete={() => {
                                                                    this.props.openAlertDialog(
                                                                        [
                                                                            {
                                                                                label: "Ja",
                                                                                color: MUI_COLORS.ERROR,
                                                                                onChoice: () => {
                                                                                    // this.deleteRule(index);
                                                                                    this.deleteOpeningHour(oh.id);
                                                                                }
                                                                            },
                                                                            {
                                                                                label: "Nein",
                                                                                onChoice: () => {
                                                                                    console.log("OH wird nicht gelöscht...");
                                                                                }
                                                                            }
                                                                        ],
                                                                        "Löschen", "Möchten Sie diese Öffnungszeit wirklich löschen?")
                                                                }}
                                                            />

                                                        )
                                                    })
                                            }
                                        </div>
                                    </div>
                                )


                            })
                        }
                        {/* <Typography>
                        {JSON.stringify(this.state.openingHours)}
                        </Typography> */}
                    </div>
                </div>
            </CollapsibleTile>
        )
    }
}

export default OpeningHoursTile