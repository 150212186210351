import { Button, Typography } from '@mui/material'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addUpdateToOrderPosition } from '../../../redux/supportSlice'

export class OrderPositionDisplay extends Component {
    decreaseQuantity() {
        if (this.props.update?.quantity == null || (this.props.data.quantity + this.props.update.quantity) > 0) {
            if ((!isNaN(parseInt(this.props.data.update?.quantity)) ? this.props.data.quantity + parseInt(this.props.data?.update?.quantity) : this.props.data.quantity) > this.props.data.totalRefundQty) {
                // this.props.addUpdateToOrderPosition({index: this.props.activeCaseIndex, oIndex: this.props.orderIndex, oPosIndex: this.props.oPosIndex, key: "quantity", value: -1});
                this.props.addUpdateToOrderPosition(this.props.activeCaseIndex, this.props.orderIndex, this.props.oPosIndex, "quantity", -1);
            } else {
                // it had reached minimum. Product has already refunds.    
            }
        } else {
            // it had reached minimum
        }
    }
    // <OrderPositionDisplay data={oPos} oPosIndex={index} orderIndex={index} key={"OPosDisplay_"+order.id+"_"+oPos.position}/>
    increaseQuantity() {
        this.props.addUpdateToOrderPosition(this.props.activeCaseIndex, this.props.orderIndex, this.props.oPosIndex, "quantity", 1);
    }
    render() {
        return (
            <div style={{ borderWidth: 0.5, borderStyle: "solid", borderColor: "#979797", borderRadius: 12, padding: 10, marginTop: 20 }}>
                <div style={{ display: "flex" }}>
                    <Typography style={{ fontSize: 12, color: this.props.data.refundPosition != null ? "#d14d4d" : "#353535", width: "75%" }}>{this.props.data.quantity} x {this.props.data.name} ({this.props.data.isRefund === 1 ? "-" : ""}{this.props.data.price.toFixed(2).replace(".", ",")} €)</Typography>
                    <Typography style={{ fontSize: 12, color: "#353535", width: "25%", textAlign: "right" }}>{this.props.data.isRefund === 1 ? "-" : ""}{(this.props.data.price * this.props.data.quantity).toFixed(2).replace(".", ",")} €</Typography>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 3 }}>
                        <Typography style={{ fontSize: 12, color: "#676767", width: "75%" }}>{this.props.data.options}</Typography>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Typography style={{ fontSize: 12, color: "#676767", textAlign: "end" }}>#{this.props.data.position}</Typography>
                    </div>
                </div>
                {this.props.data.note &&
                    <Typography style={{ fontSize: 12, color: "#676767", }}>Notiz: {this.props.data.update?.note != null ? this.props.data.update.note : this.props.data.note}</Typography>
                }
                {this.props.data.totalRefundQty != null &&
                    <Typography style={{ fontSize: 12, color: "#d14d4d", marginTop:15 }}>* Stornos: {this.props.data.totalRefundQty}</Typography>
                }
                {this.props.data.isRefund !== 1 &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15 }}>
                        <Button onClick={() => { this.decreaseQuantity() }}>-</Button>
                        <Typography>{this.props.data.update?.quantity != null ? this.props.data.quantity + this.props.data.update.quantity : this.props.data.quantity}</Typography>
                        <Button onClick={() => { this.increaseQuantity() }}>+</Button>
                    </div>
                }

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        activeCaseIndex: state.support.chatCaseIndex
    }
}
function mapDispatchToProps(dispatch) {
    return {
        addUpdateToOrderPosition: (caseIndex, orderIndex, oPosIndex, key, value) => {
            // myCaseList[arg.payload.index].orderList[arg.payload.oIndex].orderPositions[arg.payload.oPosIndex].update[arg.payload.key]
            dispatch(addUpdateToOrderPosition({ index: caseIndex, oIndex: orderIndex, oPosIndex: oPosIndex, key: key, value: value }));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderPositionDisplay)
