import { TabContext } from '@mui/lab'
import React, { Component } from 'react'
import UserInfoTab from './Tabs/UserInfoTab';
import OrdersTab from './Tabs/OrdersTab';
import UserSupportOverviewTab from './Tabs/UserSupportOverviewTab';
import { CircularProgress, Tab, Tabs } from '@mui/material';
import { connect } from 'react-redux';
import { APIData } from '../../../constants/APIAdresses';
import { SUPPORTCHAT_INFOTAB, addUserInfoToCaseList, setCurrentChatDialogTab } from '../../../redux/supportSlice';

// export const SUPPORTCHAT_INFOTAB = { USER: 0, ORDERS: 1, SUPPORTOVERVIEW: 2 }
export class InfoTabContainer extends Component {
    constructor(props) {
        super(props);
        this.userDataLoaded = false;
    }

    componentDidMount() {
        console.log("InfoTabContainer mounted...");
    }
    componentWillUnmount() {
        console.log("InfoTabContainer unmounted...");
        setCurrentChatDialogTab(SUPPORTCHAT_INFOTAB.USER);
    }
    render() {
        return (
            <div style={{ width: "95%", height: "95%", overflowY: "scroll"}}>
                <TabContext value={this.props.currentTab.toString()}>
                    <Tabs value={this.props.currentTab} onChange={(evt, newValue) => { this.props.setCurrentChatDialogTab(newValue) }} centered>
                        <Tab label="👤 Benutzer" />
                        <Tab label="🛵 Bestellungen" />
                        <Tab label="👩‍💻 Supportfälle" />
                    </Tabs>
                    <UserInfoTab tabActive={this.props.currentTab === SUPPORTCHAT_INFOTAB.USER} />
                    <OrdersTab tabActive={this.props.currentTab === SUPPORTCHAT_INFOTAB.ORDERS} />
                    <UserSupportOverviewTab tabActive={this.props.currentTab === SUPPORTCHAT_INFOTAB.SUPPORTOVERVIEW} />
                </TabContext>

            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        currentTab: state.support.currentChatDialogTab,
        currentCaseIndex: state.support.chatCaseIndex,
        data: state.support.activeCaseList[state.support.chatCaseIndex]
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setCurrentChatDialogTab: (tab) => {
            dispatch(setCurrentChatDialogTab(tab));
        },
        addUserInfoToActiveCase(index, data) {
            dispatch(addUserInfoToCaseList({ index: index, data: data }));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InfoTabContainer)
