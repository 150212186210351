import React, { Component } from 'react'
import CollapsibleTile from '../../../components/CollapsibleTile'
import { AddBusiness, ConstructionOutlined, Delete, DomainAdd, Edit } from '@mui/icons-material';
import { MUI_BTN_VARIANTS, MUI_COLORS } from '../../../constants/MUIConstants';
import NestedGenericTable from '../../../components/GenericTable/NestedGenericTable';
import { APIData } from '../../../constants/APIAdresses';
import { ROUTES } from '../../..';
import { GD_MODE, GD_STATUS_TYPE, GENERIC_DRAWER_TYPE } from '../../../components/GenericDrawer';
import { ALERT_SEVERITY } from '../../../components/AlertDisplay2';

const mainColumns = [
    {
        width: 50,
        label: 'ID',
        dataKey: 'id',
    },
    {
        // width: 2,
        width: 200,
        label: 'Kategorie',
        dataKey: 'name',
    },
    {
        width: 200,
        label: 'Sortierschlüssel',
        dataKey: 'sortKey',
    },
]
const subColumns = [
    {
        width: 200,
        headerName: 'Unterkategorie-ID',
        field: 'subcatKey',
    },
    {
        // width: 2,
        flex: 1,
        headerName: 'Unterkategorie',
        field: 'name',
    },
]

export class CategoriesTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            maxSortKey: 0
        }

        this.shopId = this.props.shopId;
    }
    componentDidMount() {
        this.loadMaxCategorySortKey();
    }
    loadMaxCategorySortKey() {
        fetch(APIData.SHOP + `/shop/${this.shopId}/getMaxCategorySortKey`, { credentials: 'include', headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    return null;
                }
            }).then(result => {
                if (result != null) {
                    this.setState({ maxSortKey: result.maxSortKey });
                } else {
                    this.setState({ maxSortKey: 0 });
                }
            }).catch(err => {
                console.error("ERROR while getMaxCategorySortKey: ", err);
            })
    }
    updateCategory(configData) {
        console.log("UPDATE CATEGORY: ", configData);
    }
    uploadImage(file, uploadType) {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("image", file);
            console.log("UPLOADTYPE: ", uploadType);
            fetch(APIData.MEDIA + "/upload?type=" + uploadType, { body: formData, method: "POST", credentials: "include" })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        reject("Error while uploading image");
                    }
                }).then(result => {
                    console.log("UPLOADRESULT: ", result);
                    if (result?.path) {
                        resolve(result.path);
                    } else {
                        reject("Error while uploading image");
                    }
                }).catch(e => {
                    reject("Error while uploading image: " + e);
                })
            // })
        })
    }
    checkUpdateImages(configData) {
        return new Promise((resolve, reject) => {
            let imgPromises = [];
            var updatedConfigData = { ...configData };
            configData.fields.forEach((field, index) => {
                // .filter(field => field.status === GD_STATUS_TYPE.UPDATE && field.type === GENERIC_DRAWER_TYPE.IMAGE)
                console.log("Going through fields: ", field);
                if (field.status === GD_STATUS_TYPE.UPDATE && field.type === GENERIC_DRAWER_TYPE.IMAGE) {
                    imgPromises.push(
                        this.uploadImage(field.value, field.uploadType).then(result => {
                            updatedConfigData.fields[index].value = result;
                        }).catch(e => {
                            console.log("Error while uploading image: ", e);
                        })
                    );
                }
            })
            console.log("Updating files: ", imgPromises);
            Promise.all(imgPromises).then(() => {
                resolve({ ...updatedConfigData });
            }).catch(e => {
                console.log("PROMISE_Error while updating images: ", e);
            })
        })
    }
    addCategory(configData) {
        // console.log("ADD CATEGORY: ",configData);
        this.checkUpdateImages(configData)
            .then((updatedConfigData) => {
                configData = { ...updatedConfigData };
                let body = {
                    name: configData.fields[1].value
                }
                if (configData.fields[0].value?.length > 0) {
                    body.imgSource = configData.fields[0].value;
                }
                fetch(APIData.SHOP + `/shop/${this.shopId}/category/add`, { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
                    .then(res => {
                        if (res.ok) {
                            this.props.showAlert("Kategorie erfolgreich hinzugefügt.", ALERT_SEVERITY.SUCCESS);
                            return res.json();
                        } else {
                            this.props.showAlert("Fehler beim Hinzufügen der Kategorie.", ALERT_SEVERITY.ERROR);
                        }
                    }).then(result => {
                        if (result) {
                            // this.loadMaxCategorySortKey();
                            // this.props.closeDrawer();
                            let myTableData = [...this.state.tableData];
                            myTableData.push({ id: result.id, name: configData.fields[1].value, sortKey: this.state.maxSortKey + 1, imgSource: configData.fields[0].value, subcategories: [] });
                            this.setState({ tableData: [...myTableData], maxSortKey: this.state.maxSortKey + 1 });
                            this.props.closeDrawer();
                        }
                    })

            })
            .catch(e => {
                console.log("Error while uploading images: ", e);
                this.props.showAlert("Fehler beim Hochladen von Bildern.", ALERT_SEVERITY.ERROR);
            })

    }
    updateCategory(configData) {
        console.log("UPDATE CATEGORY: ", configData);
        this.checkUpdateImages(configData).then((updatedConfigData) => {
            configData = { ...updatedConfigData };
            let body = {
                fields: {}
            }
            configData.fields.filter(field => field.status === GD_STATUS_TYPE.UPDATE).forEach(field => {
                body.fields[field.dbKey] = field.value;
            })
            if (Object.keys(body.fields).length > 0) {
                fetch(APIData.SHOP + `/shop/${this.shopId}/category/${this.state.tableData[configData.index].id}/update`, { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
                    .then(res => {
                        if (res.ok) {
                            this.props.showAlert("Kategorie erfolgreich aktualisiert.", ALERT_SEVERITY.SUCCESS);
                            // return res.json();
                            let myTableData = [...this.state.tableData];
                            Object.keys(body.fields).forEach(fieldKey => {
                                myTableData[configData.index][fieldKey] = body.fields[fieldKey];
                            })
                            this.setState({ tableData: [...myTableData] });
                            this.props.closeDrawer();
                            // let subcatIndex = myTableData[configData.index].subcategories.findIndex(subcat => subcat.subcatKey === configData.subcatKey);
                            // if(subcatIndex >= 0){
                            //     Object.keys(body.fields).forEach(fieldKey => {
                            //         myTableData[configData.index].subcategories[subcatIndex][fieldKey] = body.fields[fieldKey];
                            //     })
                            //     this.setState({tableData: [...myTableData]});
                            //     this.props.closeDrawer();
                            // }
                        } else {
                            this.props.showAlert("Fehler beim Aktualisieren der Kategorie.", ALERT_SEVERITY.ERROR);
                        }
                    })
                    .catch(e => {
                        console.log("Error while updating category: ", e);
                        this.props.showAlert("Fehler beim Aktualisieren der Kategorie.", ALERT_SEVERITY.ERROR);
                    })
            }
            else {
                this.props.showAlert("Keine Änderungen vorgenommen.", ALERT_SEVERITY.INFO);
                return;
            }

        }).catch(e => {
            console.log("Error while uploading images: ", e);
            this.props.showAlert("Fehler beim Hochladen von Bildern.", ALERT_SEVERITY.ERROR);
        })
    }
    deleteCategory(index) {
        fetch(APIData.SHOP + `/shop/${this.shopId}/category/${this.state.tableData[index].id}/delete`, { credentials: 'include', headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.ok) {
                    this.props.showAlert("Kategorie erfolgreich gelöscht.", ALERT_SEVERITY.SUCCESS);
                    let myTableData = [...this.state.tableData];
                    myTableData.splice(index, 1);
                    myTableData.map((cat, index) => {
                        if(cat.sortKey > this.state.tableData[index].sortKey){
                            cat.sortKey = cat.sortKey - 1;
                        }
                    })
                    // this.setState({ tableData: [...myTableData] });
                } else {
                    this.props.showAlert("Fehler beim Löschen der Kategorie.", ALERT_SEVERITY.ERROR);
                }
            }).catch(e => {
                console.log("Error while deleting category: ", e);
                this.props.showAlert("Fehler beim Löschen der Kategorie.", ALERT_SEVERITY.ERROR);
            })
    }
    deleteSubcategory(mainIndex, row) {
        fetch(APIData.SHOP + `/shop/${this.shopId}/category/${this.state.tableData[mainIndex].id}/subcategory/${row.subcatKey}/delete`, { credentials: 'include', headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.ok) {
                    this.props.showAlert("Unterkategorie erfolgreich gelöscht.", ALERT_SEVERITY.SUCCESS);
                    let myTableData = [...this.state.tableData];
                    let subcatIndex = myTableData[mainIndex].subcategories.findIndex(subcat => subcat.subcatKey === row.subcatKey);
                    if (subcatIndex >= 0) {
                        myTableData[mainIndex].subcategories.splice(subcatIndex, 1);
                        myTableData[mainIndex].subcategories.map((cat, index) => {
                            if (cat.subcatKey > row.subcatKey) {
                                cat.subcatKey = cat.subcatKey - 1;
                            }
                        })
                        this.setState({ tableData: [...myTableData] });
                    }
                } else {
                    this.props.showAlert("Fehler beim Löschen der Unterkategorie.", ALERT_SEVERITY.ERROR);
                }
            }).catch(e => {
                console.log("Error while deleting subcategory: ", e);
                this.props.showAlert("Fehler beim Löschen der Unterkategorie.", ALERT_SEVERITY.ERROR);
            })
    }
    openCategoryEditDrawer(index) {
        console.log("OnEdit Clicked: ", this.state.tableData[index]);
        let baseDrawerConfig = {
            // id: this.state.tableData[index].id,
            index: index,
            options: [
                { label: "Speichern", onClick: (configData) => { this.updateCategory(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "id",
                    disabled: true,
                    value: this.state.tableData[index].id,
                },
                {
                    label: "Sortierschlüssel",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "sortKey",
                    isNumeric: true,
                    minValue: 0,
                    maxValue: this.state.maxSortKey,
                    value: this.state.tableData[index].sortKey,
                },
                {
                    label: "Titelbild",
                    type: GENERIC_DRAWER_TYPE.IMAGE,
                    value: this.state.tableData[index].imgSource,
                    baseUri: APIData.MEDIA,
                    uploadType: APIData.MEDIA_TYPES.CATEGORIES,
                    dimensions: { width: 800, height: 200 },
                    dbKey: "imgSource"
                },
                {
                    label: "Kategorie",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "name",
                    value: this.state.tableData[index].name,
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    openCategoryAddDrawer() {
        let baseDrawerConfig = {
            options: [
                { label: "Speichern", doRequiredFieldCheck: true, onClick: (configData) => { this.addCategory(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "Titelbild",
                    type: GENERIC_DRAWER_TYPE.IMAGE,
                    value: "",
                    baseUri: APIData.MEDIA,
                    uploadType: APIData.MEDIA_TYPES.CATEGORIES,
                    dimensions: { width: 800, height: 200 },
                    dbKey: "imgSource",
                    isRequired: true
                },
                {
                    label: "Kategorie",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "id",
                    value: "",
                    isRequired: true
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.NEW);
    }
    getMaxSubcatKey(mainIndex) {
        let maxSubcatKey = 0;
        this.state.tableData[mainIndex].subcategories.forEach(subcat => {
            if (subcat.subcatKey > maxSubcatKey) {
                maxSubcatKey = subcat.subcatKey;
            }
        });
        return maxSubcatKey;
    }
    addSubCategory(configData) {
        this.checkUpdateImages(configData).then((updatedConfigData) => {
            configData = { ...updatedConfigData };
            let body = {
                name: configData.fields[1].value,
                subcatKey: configData.fields[0].value
            }
            if (configData.fields[0].value?.length > 0) {
                body.imgSource = configData.fields[0].value;
            }
            fetch(APIData.SHOP + `/shop/${this.shopId}/category/${this.state.tableData[configData.index].id}/subcategory/add`, { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
                .then(res => {
                    if (res.ok) {
                        this.props.showAlert("Unterkategorie erfolgreich hinzugefügt.", ALERT_SEVERITY.SUCCESS);
                        return res.json();
                    } else {
                        this.props.showAlert("Fehler beim Hinzufügen der Unterkategorie.", ALERT_SEVERITY.ERROR);
                    }
                }).then(result => {
                    if (result) {
                        let myTableData = [...this.state.tableData];
                        let subcat = { name: configData.fields[1].value, subcatKey: result.subcatKey, imgSource: configData.fields[0].value };
                        myTableData[configData.index].subcategories.push(subcat);
                        myTableData = JSON.stringify(myTableData);
                        this.setState({ tableData: JSON.parse(myTableData) });
                        this.props.closeDrawer();
                    }
                })
        }).catch(e => {
            console.log("Error while uploading images: ", e);
            this.props.showAlert("Fehler beim Hochladen von Bildern.", ALERT_SEVERITY.ERROR);
        });
    }
    updateSubcategory(configData) {
        console.log("UPDATE SUBCATEGORY: ", configData);
        this.checkUpdateImages(configData).then((updatedConfigData) => {
            configData = { ...updatedConfigData };
            let body = {
                fields: {}
            }
            configData.fields.filter(field => field.status === GD_STATUS_TYPE.UPDATE).forEach(field => {
                body.fields[field.dbKey] = field.value;
            })
            if (Object.keys(body.fields).length > 0) {
                fetch(APIData.SHOP + `/shop/${this.shopId}/category/${configData.id}/subcategory/${configData.subcatKey}/update`, { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
                    .then(res => {
                        if (res.ok) {
                            this.props.showAlert("Unterkategorie erfolgreich aktualisiert.", ALERT_SEVERITY.SUCCESS);
                            // return res.json();
                            let myTableData = [...this.state.tableData];
                            let subcatIndex = myTableData[configData.index].subcategories.findIndex(subcat => subcat.subcatKey === configData.subcatKey);
                            if (subcatIndex >= 0) {
                                Object.keys(body.fields).forEach(fieldKey => {
                                    myTableData[configData.index].subcategories[subcatIndex][fieldKey] = body.fields[fieldKey];
                                })
                                this.setState({ tableData: [...myTableData] });
                                this.props.closeDrawer();
                            }
                        } else {
                            this.props.showAlert("Fehler beim Aktualisieren der Unterkategorie.", ALERT_SEVERITY.ERROR);
                        }
                    })
                    .catch(e => {
                        console.log("Error while updating subcategory: ", e);
                        this.props.showAlert("Fehler beim Aktualisieren der Unterkategorie.", ALERT_SEVERITY.ERROR);
                    })
            }
            else {
                this.props.showAlert("Keine Änderungen vorgenommen.", ALERT_SEVERITY.INFO);
                return;
            }

        }).catch(e => {
            console.log("Error while uploading images: ", e);
            this.props.showAlert("Fehler beim Hochladen von Bildern.", ALERT_SEVERITY.ERROR);
        })
    }
    openSubcategoryEditDrawer(mainIndex, row) {
        console.log("Edit Subcategory: ", row);
        let baseDrawerConfig = {
            id: this.state.tableData[mainIndex].id,
            index: mainIndex,
            subcatKey: row.subcatKey,
            options: [
                { label: "Speichern", onClick: (configData) => { this.updateSubcategory(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "Titelbild",
                    type: GENERIC_DRAWER_TYPE.IMAGE,
                    value: row.imgSource,
                    baseUri: APIData.MEDIA,
                    uploadType: APIData.MEDIA_TYPES.CATEGORIES,
                    dimensions: { width: 800, height: 200 },
                    dbKey: "imgSource"
                },
                {
                    label: "Unterkategorie-ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "subcatKey",
                    isNumeric: true,
                    minValue: 1,
                    maxValue: this.getMaxSubcatKey(mainIndex),
                    value: row.subcatKey,
                },
                {
                    label: "Unterkategorie",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "name",
                    value: row.name,
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    openSubcategoryAddDrawer(mainIndex) {
        let baseDrawerConfig = {
            index: mainIndex,
            // id: this.state.tableData[mainIndex].id,
            options: [
                { label: "Speichern", onClick: (configData) => { this.addSubCategory(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "Titelbild",
                    type: GENERIC_DRAWER_TYPE.IMAGE,
                    value: "",
                    baseUri: APIData.MEDIA,
                    uploadType: APIData.MEDIA_TYPES.CATEGORIES,
                    dimensions: { width: 800, height: 200 },
                    dbKey: "imgSource",
                    isRequired: true,
                },
                {
                    label: "Unterkategorie",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    dbKey: "name",
                    isRequired: true,
                    value: "",
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.NEW);
    }
    render() {
        return (
            <CollapsibleTile title="Kategorien / Unterkategorien" icon={<img src={require('../../../assets/icons/categories.png')} width={20} height={20} />}
                onCollapseChange={(expanded) => { }}
            >
                <NestedGenericTable
                    config={{ columns: [...mainColumns], subColumns: [...subColumns], subTableKey: "subcategories" }}
                    apiEndPoint={APIData.SHOP + `/shop/${this.shopId}/getPagedCategories`}
                    tableData={this.state.tableData}
                    setTableData={(data) => { this.setState({ tableData: data }) }}
                    onClickRow={(index) => {
                        console.log("MainRow clicked redirect to: ./company/" + this.state.tableData[index].id);
                    }}
                    addBtnOnClick={() => { this.openCategoryAddDrawer() }}
                    addBtnLabel="Kategorie Hinzufügen"
                    getSubRowId={(row) => { return row.subcatKey; }}
                    subTableSortModel={[{ field: 'subcatKey', sort: 'asc' }]}
                    addBtnIcon={<img src={require('../../../assets/icons/addcategory.png')} width={20} height={20} />}
                    addSubBtnIcon={<img src={require('../../../assets/icons/addcategory.png')} width={20} height={20} />}
                    onEdit={(index) => {
                        this.openCategoryEditDrawer(index);
                    }}
                    onDelete={(index) => {
                        console.log("ONDELETE CLICKED: ", this.state.tableData[index]);
                        this.props.openAlertDialog(
                            [
                                {
                                    label: "Ja",
                                    color: MUI_COLORS.ERROR,
                                    onChoice: () => {
                                        this.deleteCategory(index);
                                    }
                                },
                                {
                                    label: "Nein",
                                    onChoice: () => {
                                        console.log("Subcat wird nicht gelöscht...");
                                    }
                                }
                            ],
                            "Löschen", "Möchtest du die Kategorie '"+this.state.tableData[index].name+"' wirklich löschen?"
                        )
                    }}

                    onClickSubRow={(mainIndex, row) => {
                        console.log("Subrow clicked redirect to ./company/" + this.state.tableData[mainIndex].id + "/shop/" + row.id);
                        this.props.navigate(ROUTES.SHOP(this.state.tableData[mainIndex].id, row.id))

                    }}
                    addSubBtnLabel="Unterkategorie Hinzufügen"
                    addSubBtnOnClick={(index) => {
                        console.log("Add Sub Button Clicked: ", index);
                        this.openSubcategoryAddDrawer(index);
                    }}
                    onSubEdit={(mainIndex, row) => {
                        // this.openShopEditDrawer(mainIndex, row)
                        this.openSubcategoryEditDrawer(mainIndex, row);
                    }}
                    onSubDelete={(mainIndex, row) => {
                        console.log("ONSUBDELETE CLICKED: ", mainIndex, " ", row);
                        this.props.openAlertDialog(
                            [
                                {
                                    label: "Ja",
                                    color: MUI_COLORS.ERROR,
                                    onChoice: () => {
                                        this.deleteSubcategory(mainIndex, row);
                                    }
                                },
                                {
                                    label: "Nein",
                                    onChoice: () => {
                                        console.log("Subcat wird nicht gelöscht...");
                                    }
                                }
                            ],
                            "Löschen", "Möchtest du die Unterkategorie '"+row.name+"' wirklich löschen?"
                        )
                    }}
                />
            </CollapsibleTile>
        )
    }
}

export default CategoriesTile