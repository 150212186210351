
import React, { Component } from 'react'

import TableBody from '@mui/material/TableBody';
import { StyledTableCell, StyledTableRow } from './GenericTable';
import { IconButton, Switch } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

// 
export class GTTableBody extends Component {
    render() {
        return (
            <TableBody>
                {this.props.tableData?.map((row, index) => {
                    return <StyledTableRow key={row.id} onClick={() => { this.props.onClick(index); }} style={{ cursor: 'pointer' }}>
                        {this.props.onSetActive != null &&  this.props.activeKey != null &&
                            <StyledTableCell sx={{ width: 25, textAlign:"center !important" }} onClick={(event) => { event.stopPropagation(); this.props.onSetActive(index, !row[this.props.activeKey]); }} > 
                                <Switch size='small' color="primary" checked={row[this.props.activeKey]} />
                            </StyledTableCell>            
                        }
                        {this.props.columns.map((column) => {
                            return <StyledTableCell key={column.dataKey} >{column.format ? column.format(row[column.dataKey]) : row[column.dataKey]}</StyledTableCell>
                        })}
                        {this.props.onDelete != null &&
                            <StyledTableCell sx={{ width: 25 }} onClick={(event) => { event.stopPropagation(); this.props.onDelete(index, row.id); }} >
                                <IconButton size="small" color='primary' >
                                    <Delete  />
                                </IconButton>
                            </StyledTableCell>
                        }
                        {this.props.onEdit != null &&
                            <StyledTableCell sx={{ width: 25 }} onClick={(event) => { event.stopPropagation(); this.props.onEdit(index, row.id); }} >
                                <IconButton size="small" color='primary' >
                                    <Edit />
                                </IconButton>
                            </StyledTableCell>
                        }
                    </StyledTableRow>
                })}
            </TableBody>
        )
    }
}

export default GTTableBody
