import React, { Component } from 'react'
import TileComponent from '../../components/TileComponent'
import { Business, Delete, Edit, ImageNotSupported, Store, Tune } from '@mui/icons-material'
import { CircularProgress, Link, Typography } from '@mui/material'
import { ALERT_SEVERITY } from '../../components/AlertDisplay2';
import { APIData } from '../../constants/APIAdresses';
import { MUI_BTN_VARIANTS, MUI_COLORS } from '../../constants/MUIConstants';
import CollapsibleTile from '../../components/CollapsibleTile';

export class ShopTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            titleImageLoadErr: false,
            iconImageLoadErr: false,
        }
    }
    componentDidMount() {
        if (this.props.id != null) {
            const showErr = () => {
                this.setState({
                    alertDisplayMsg: "Fehler beim Laden der Partnerdaten.",
                    alertDisplayVisible: true,
                    alertSeverity: ALERT_SEVERITY.ERROR
                })
            }
            let uri = APIData.SHOP + `/shop/${this.props.id}/getInformation`;
            console.log("CompanyURI: ", uri);
            fetch(uri, { credentials: "include", headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return null;
                    }
                }).then(result => {

                    this.setState({ isLoading: false });
                    if (result) {
                        console.log("COMPANYDATA: ", result);
                        this.props.onChange(result);
                        // this.setState({companyData: result});
                    } else {
                        showErr();
                    }
                })
                .catch(e => {

                    this.setState({ isLoading: false });
                    console.log("Error while loading partner data: ", e);
                    showErr();
                })
        }
    }
    render() {
        return (
            <CollapsibleTile defaultExpanded title="Shopinformationen" icon={<img src={require('../../assets/icons/store.png')} width={20} height={20} />}
                actions={[
                    { label: "Löschen", icon: <Delete />, variant: MUI_BTN_VARIANTS.OUTLINED, color: MUI_COLORS.ERROR, onClick: () => { this.props.onDelete() } },
                    { label: "Editieren", icon: <Edit />, onClick: () => { this.props.onEdit(); } },
                ]}
                onCollapseChange={(expanded) => { }}
            >
                {!this.state.isLoading ?
                    <div style={{ display: "flex", flexDirection: "row", minWidth: 900}}>

                        <div style={{ flex: 1, overflow: "hidden" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap:25 }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                                    <Typography style={{ marginRight: 20 }} fontWeight={"bold"}>Titelbild: </Typography>
                                    {this.props.data?.imgSource?.length > 0 && !this.state.titleImageLoadErr ?
                                        <img onError={() => { this.setState({ titleImageLoadErr: true }) }} src={APIData.MEDIA + this.props.data.imgSource} alt='Bild' style={{ width: 40, height: 40, objectFit: 'cover', borderRadius: 20 }} />
                                        :
                                        <ImageNotSupported style={{ width: 40, height: 40 }} />
                                    }
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Typography style={{ marginRight: 20 }} fontWeight={"bold"}>Logo: </Typography>
                                    {this.props.data?.imgSource?.length > 0 && !this.state.iconImageLoadErr ?
                                        <img onError={() => { this.setState({ iconImageLoadErr: true }) }} src={APIData.MEDIA + this.props.data.iconSource} alt='Bild' style={{ width: 40, height: 40, objectFit: 'cover', borderRadius: 20 }} />
                                        :
                                        <ImageNotSupported style={{ width: 40, height: 40 }} />
                                    }
                                </div>
                            </div>
                            <Typography><b>Aktiv:</b> {this.props.data.isActive ? "Ja" : "Nein"}</Typography>
                            <Typography><b>ID:</b> {this.props.data.id}</Typography>
                            <Typography><b>Name:</b> {this.props.data.name}</Typography>
                            <Typography><b>Adresse:</b> {this.props.data.street} {this.props.data.houseNumber}</Typography>
                            <Typography><b>PLZ / Ort:</b> {this.props.data.zip} {this.props.data.city}</Typography>
                        </div>
                        <div style={{ flex: 1, overflow: "hidden" }}>
                            <Typography><b>Telefon:</b> {this.props.data.phone ? this.props.data.phone : "-"}</Typography>
                            <Typography><b>E-Mail:</b> {this.props.data.mail ? this.props.data.mail : "-"}</Typography>
                            <Typography><b>Abholrabatt:</b> {this.props.data.pickupDiscount ? this.props.data.pickupDiscount+" %" : "-"}</Typography>
                            <Typography><b>Lieferzeit:</b> {this.props.data.deliveryTime ? this.props.data.deliveryTime+" Minuten" : "-"}</Typography>
                            <Typography><b>Zusatzinfo:</b> {this.props.data.additionalInfo ? this.props.data.additionalInfo : "-"}</Typography>
                            <Typography><b>GPS-Position:</b> <Link color='inherit' target="_blank" href={`https://maps.google.com/?q=${this.props.data.latitude},${this.props.data.longitude}`}>{this.props.data.latitude},{this.props.data.longitude}</Link></Typography>
                        </div>
                        <div style={{flex: 1, overflow: "hidden"}}>
                        <Typography><b>Kartenzahlung:</b> {this.props.data.debitAvailable ? "Ja" : "Nein"}</Typography>
                            <Typography><b>Zahlungen Deaktiviert:</b> {this.props.data.paymentsDisabled ? "Ja" : "Nein"}</Typography>
                            <Typography><b>Großhandelshop:</b> {this.props.data.partnerDealShop ? "Ja" : "Nein"}</Typography>
                            <Typography><b>Automatische Ext. Lieferung:</b> {this.props.data.extDeliveryByDefault ? "Ja" : "Nein"}</Typography>
                            <Typography><b>Ext. Lieferung Aktiv:</b> {this.props.data.extDeliveryActive ? "Ja" : "Nein"}</Typography>
                        </div>
                    </div>
                    :
                    <div style={{ height: 50, justifyContent: "center", alignItems: "center", display: "flex" }}>
                        <CircularProgress size={25} />
                    </div>
                }
            </CollapsibleTile>
        )
    }
}

export default ShopTile