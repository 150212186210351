// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, #root {
    height: 100%;
    min-width: 600px;
    overflow: hidden
}

.mainView {
    background-color: #FAFAFA; 
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/general.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB;AACJ;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":["html, body, #root {\n    height: 100%;\n    min-width: 600px;\n    overflow: hidden\n}\n\n.mainView {\n    background-color: #FAFAFA; \n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
