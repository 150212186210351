// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
html, body, #root {
    height: 100%;
    min-width: 600px;
}
.searchField { 
    margin-right: 20px;
}
@media only screen and (max-width: 700px) {
    .tabAndSearchArea {
        flex-direction: column;
        display: inline;
    }
    .searchField {
        /* padding-top: 15px; */
        padding: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/areas/support/SupportOverview.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,kBAAkB;AACtB;AACA;IACI;QACI,sBAAsB;QACtB,eAAe;IACnB;IACA;QACI,uBAAuB;QACvB,aAAa;IACjB;AACJ","sourcesContent":["\nhtml, body, #root {\n    height: 100%;\n    min-width: 600px;\n}\n.searchField { \n    margin-right: 20px;\n}\n@media only screen and (max-width: 700px) {\n    .tabAndSearchArea {\n        flex-direction: column;\n        display: inline;\n    }\n    .searchField {\n        /* padding-top: 15px; */\n        padding: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
