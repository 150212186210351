import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MUI_COLORS } from '../constants/MUIConstants';

// export const AD_BTN_COLORS = {
//     PRIMARY: "primary",
//     SECONDARY: "secondary",
//     DEFAULT: "default",
//     INHERIT: "inherit",
//     SUCCESS: "success",
//     ERROR: "error",
// }
export default function AlertDialog(props) {

  const handleChoice = (option) => {
    option.onChoice();
    props.closeDialog();
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        // onClose={handleChoice}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            {
            props.options?.map((option, index) => {
                return <Button key={index} onClick={() => {handleChoice(option)}} color={option.color ? option.color : MUI_COLORS.PRIMARY}>
                    {option.label}
                </Button>
            })
            }
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
