import { Button, CircularProgress, Divider, Typography } from '@mui/material';
import { createTheme } from '@mui/material';
import React, { Component } from 'react'
import { MUI_BTN_VARIANTS, MUI_COLORS } from '../constants/MUIConstants';


const DEFAULTMARGIN = 20;
export class TileComponent extends Component {

    render() {
        return (
            <div style={{ border: "0.5px solid #cecece", borderRadius: 5, padding: DEFAULTMARGIN, position: "relative" }}>
                <div style={{ display: "flex", alignItems:"center", flexDirection: "row" }}>
                    { this.props.icon != null &&
                        <div style={{ marginRight: 15 }}>
                            {this.props.icon}
                        </div>
                    }
                    <Typography style={{ fontSize: 18, fontWeight: "500", color: '#353535' }}>{this.props.title}</Typography>
                </div>
                <Divider style={{ marginTop: 10, marginBottom: 20 }} />
                <div style={{ marginTop: DEFAULTMARGIN, marginBottom: DEFAULTMARGIN, overflowX:'scroll', scrollbarWidth:'none' }}>
                    {this.props.children}
                </div>
                {this.props.actions != null &&
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: 10, marginTop: DEFAULTMARGIN }}>
                        {
                            this.props.actions?.map((action, index) => {
                                return <Button
                                    key={"tc_action_btn-"+index}
                                    startIcon={action.icon}
                                    onClick={action.onClick}
                                    disabled={action.disabled}
                                    variant={action.variant ? action.variant : MUI_BTN_VARIANTS.CONTAINED}
                                    color={action.color ? action.color : MUI_COLORS.PRIMARY}>{action.label}</Button>
                            })
                        }
                    </div>
                }
                {this.props.isLoading &&
                    <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", borderRadius: 4, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(0,0,0,0.2)" }}>
                        <CircularProgress size={25} />
                    </div>
                }
            </div>
        );
    }
}

export default TileComponent;



