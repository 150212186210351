import { Button, Tab, Tabs, TextField, Typography, } from '@mui/material'
import React, { Component } from 'react'
import "./SupportOverview.css"
import "../../assets/css/general.css"
import { TabContext } from '@mui/lab'
import AllSupportCasesTabContent from './Tabs/AllSupportCasesTabContent'
import OpenSupportCasesTabContent from './Tabs/OpenSupportCasesTabContent'
import ClosedSupportCasesTabContent from './Tabs/ClosedSupportCasesTabContent'
import Header from '../../components/Header'
import { connect } from 'react-redux'
import { setRole } from '../../redux/permissionSlice'

import { addCasesToCaseList, setCaseList, setCaseOffset } from '../../redux/supportSlice'
import SearchSupporCaseContent from './Tabs/SearchSupporCaseContent'
import { APIData } from '../../constants/APIAdresses'
import DefaultFrame from '../../components/DefaultFrame'


export const SUPPORT_TAB = { ALL: 0, OPEN: 1, CLOSED: 2 };
export class SupportOverview extends Component {

    constructor(props) {
        super(props);
        // if(!props.loggedIn){
        //     window.location="/";
        // }
        this.state = {

            currentTab: SUPPORT_TAB.OPEN,
            searchValue: "",
            searchActive: false,
            searchContent: null
            // caseList: [
            //     {id:"18446744"},
            //     {id:2},
            //     {id:3},
            //     {id:4},
            //     {id:5},{id:6},{id:7},{id:8},{id:9},{id:10},{id:11},{id:12},{id:13},{id:14},{id:15},{id:16},{id:17},{id:18},{id:19},{id:20},{id:21},{id:22},{id:23},{id:24},{id:25},{id:26},{id:27},{id:28},{id:29},{id:30},{id:31},{id:32},{id:33},
            // ],
            // currentCaseList: [, "455", "300", "301", "302", "303"]
        }
        console.log("PROPS: ", props);
    }

    componentDidMount() {
        if (this.props.openCaseOffset == 0) {
            this.loadCases(this.props.openCaseOffset, SUPPORT_TAB.OPEN);
        }
    }
    loadCases(offset, tab) {
        let uri = "https://viveel.de:3002/support/getCases?offsetMultiplier=" + offset + "&type=" + tab;
        console.log("Loading cases with uri: ", uri);
        fetch(uri, { credentials: "include" })
            .then(response => {
                console.log("Response: ", response.status);
                if (response.ok) {
                    if (response.status === 204) { // status means request was processed but no data were found
                        return [];
                    } else {
                        return response.json();
                    }
                }
            }).then(result => {
                if (result.length > 0) {
                    this.props.addCasesToCaseList({ tab: tab, list: result });
                    switch (tab) {
                        case SUPPORT_TAB.ALL: this.props.setCaseOffset({ tab: SUPPORT_TAB.ALL, value: this.props.allCaseOffset + 1 }); break;
                        case SUPPORT_TAB.OPEN: this.props.setCaseOffset({ tab: SUPPORT_TAB.OPEN, value: this.props.openCaseOffset + 1 }); break;
                        case SUPPORT_TAB.CLOSED: this.props.setCaseOffset({ tab: SUPPORT_TAB.CLOSED, value: this.props.closedCaseOffset + 1 }); break;
                    }
                } else {
                    switch (tab) {
                        case SUPPORT_TAB.ALL: this.props.setCaseOffset({ tab: SUPPORT_TAB.ALL, value: -1 }); break;
                        case SUPPORT_TAB.OPEN: this.props.setCaseOffset({ tab: SUPPORT_TAB.OPEN, value: -1 }); break;
                        case SUPPORT_TAB.CLOSED: this.props.setCaseOffset({ tab: SUPPORT_TAB.CLOSED, value: -1 }); break;
                    }
                }
            }).catch(e => {
                console.log("Error while loading cases: ", e);
            })
    }
    loadMore() {
        let myOffset = -1;
        switch (this.state.currentTab) {
            case SUPPORT_TAB.ALL:
                myOffset = this.props.allCaseOffset;
                break;
            case SUPPORT_TAB.OPEN:
                myOffset = this.props.openCaseOffset;
                break;
            case SUPPORT_TAB.CLOSED:
                myOffset = this.props.closedCaseOffset;
                break;
        }
        if (myOffset != null && myOffset != -1) {
            this.loadCases(myOffset, this.state.currentTab);
        }
    }
    onTabChange(event, newValue) {

        this.setState({ currentTab: newValue, searchValue: "" });
        if (this.state.searchValue.length > 0) {
            switch (this.state.currentTab) {
                case SUPPORT_TAB.ALL:
                    this.props.setCaseOffset({ tab: SUPPORT_TAB.ALL, value: 0 });
                    break;
                case SUPPORT_TAB.OPEN:
                    this.props.setCaseOffset({ tab: SUPPORT_TAB.OPEN, value: 0 });
                    break;
                case SUPPORT_TAB.CLOSED:
                    this.props.setCaseOffset({ tab: SUPPORT_TAB.CLOSED, value: 0 });
                    break;
            }
        }
        let offset = newValue === SUPPORT_TAB.ALL ? this.props.allCaseOffset : newValue === SUPPORT_TAB.OPEN ? this.props.openCaseOffset : newValue === SUPPORT_TAB.CLOSED ? this.props.closedCaseOffset : -1;
        if (offset == 0) {
            // if tab doesn't have any data yet -> load
            // let uri = "https://viveel.de:3002/support/getCases?offsetMultiplier=0&type=" + this.state.currentTab;
            this.loadCases(offset, newValue);

        }
    }
    searchForCase() {
        if(this.state.searchValue.length > 0){
            this.setState({searchActive:true});
            fetch(APIData.REALTIME+`/support/getCaseById/${this.state.searchValue}/status/${this.state.currentTab}`, {credentials:"include"})
            .then(response => {
                console.log("SearchForCase Response: ",response);
                if(response.ok){
                    return response.json();
                }else{
                    return null;
                }
            }).then(result => {
                console.log("Result For Search: ",result);
                if(result != null){
                    this.setState({
                        searchContent: result
                    })
                    // if(result)
                } 
            }).catch(e => {
                alert("Fehler bei der Suche vom Supportfall");
            })
        }else{
            if(this.state.searchActive ||this.state.searchContent != null){
                this.setState({
                    searchActive: false,
                    searchContent: null
                })
            }
        }
        // /support/getCaseById/:id/status/:status
    }
    render() {
        return (
            <DefaultFrame title="Support">
                {/* <Typography>ROLE: {this.props.role}</Typography> */}
                <div style={{ padding: "10px", }}>
                    <TabContext value={this.state.currentTab.toString()}>
                        <div className='tabAndSearchArea' style={{ display: 'flex' }}>
                            <Tabs style={{ flex: 1 }} value={this.state.currentTab} onChange={(evt, newValue) => { this.onTabChange(evt, newValue) }} >
                                <Tab label="Alle" />
                                <Tab label="Offen ⚠️" />
                                <Tab label="Geschlossen ✅" />
                            </Tabs>
                            <div className='searchField'>
                                <TextField value={this.state.searchValue} onChange={(evt) => { this.setState({ searchValue: evt.target.value }) }} onKeyDown={(evt) => { if (evt.key === "Enter") { evt.target.blur(); evt.preventDefault(); this.searchForCase(); } }} /* onBlur={(evt) => {}} */ type='number' inputProps={{ min: 1 }} style={{ minWidth: "300px", width: "10%", backgroundColor: "#fff" }} id="outlined-basic" variant='outlined' label='Suche'></TextField>
                            </div>
                        </div>
                        <div style={{}}>
                            {!this.state.searchActive ?
                                <>
                                    <AllSupportCasesTabContent />
                                    <OpenSupportCasesTabContent />
                                    <ClosedSupportCasesTabContent />
                                </>
                                :
                                <SearchSupporCaseContent data={this.state.searchContent} searchValue={this.state.searchValue}/>
                            }
                        </div>
                        {
                            (this.state.currentTab === SUPPORT_TAB.ALL && this.props.allCaseOffset != -1 ||
                                this.state.currentTab === SUPPORT_TAB.OPEN && this.props.openCaseOffset != -1 ||
                                this.state.currentTab === SUPPORT_TAB.CLOSED && this.props.closedCaseOffset != -1) && !this.state.searchActive &&
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button onClick={() => { this.loadMore() }} style={{}}>Mehr Laden</Button>
                            </div>
                        }


                    </TabContext>
                </div>
            </DefaultFrame>
        )
    }
}
function mapStateToProps(state) {
    return {
        role: state.permission.role,
        allCaseOffset: state.support.allCaseOffset,
        openCaseOffset: state.support.openCaseOffset,
        closedCaseOffset: state.support.closedCaseOffset,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setCaseOffset: (data) => {
            dispatch(setCaseOffset(data));
        },
        setCaseList: (data) => {
            dispatch(setCaseList(data))
        },
        addCasesToCaseList: (data) => {
            dispatch(addCasesToCaseList(data));
        },
        setRole: (role) => {
            dispatch(setRole(role));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SupportOverview)
