import { createSlice } from "@reduxjs/toolkit";
export const ROLES = {
    USER: 1,
    SHOPDRIVER: 2,
    STAFF: 3,
    DRIVER: 4,
    SHOPMANAGER: 5,
    SHOPOWNER: 6,
    AGENT: 7,
    SUPERVISOR: 8,
    ADMIN: 9
}
export const permissionSlice = createSlice({
    name:'permission',
    initialState: {
        loggedIn: false,
        role: null,
    },
    reducers: {
        setLoggedIn: (state, arg) => {state.loggedIn = arg.payload;  console.log("SETTING LOGGEDIN: ",state.loggedIn)},
        setRole: (state, arg) => {console.log("Are we doing something here??"); state.role=arg.payload},
    }
})

export const { setLoggedIn, setRole } = permissionSlice.actions

export default permissionSlice.reducer