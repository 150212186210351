
import React, { Component, useEffect } from 'react'

import TableBody from '@mui/material/TableBody';
import { GT_ACTIVETYPES, StyledTableCell, StyledTableRow } from './GenericTable';
import { Checkbox, IconButton, Radio, Switch } from '@mui/material';
import { Delete, Edit, ImageNotSupported } from '@mui/icons-material';

function Column({ column, row }) {
    const [error, setError] = React.useState(false);
    useEffect(() => {
        if(column.baseImgUri != null && error){
            setTimeout(() => { // This is required to avoid the resource not found from the server, since a change of the value means a new uploaded picture and we have to give the server the time to process the upload it...
                setError(false);
            },500)
        }
    },[row[column.dataKey]])
    return column.baseImgUri == null ?
        <StyledTableCell key={column.dataKey} >{column.format ? column.format(row[column.dataKey]) : row[column.dataKey]}</StyledTableCell>
        :
        <StyledTableCell key={column.dataKey+row[column.dataKey]} >
            {!error ?
                <img onError={() => { setError(true) }} key={row[column.dataKey]} src={column.baseImgUri + row[column.dataKey]} style={{ width: 35, height: 35, borderRadius: 17.5 }} />
                :
                <ImageNotSupported style={{ width: 35, height: 35 }} />
            }
        </StyledTableCell>
}
export class GTTableBody extends Component {
    render() {
        return (
            <TableBody>
                {this.props.tableData?.map((row, index) => {
                    return <StyledTableRow key={"STR_" + index + "_" + row.id} onClick={() => { this.props.onClick(index); }} style={{ cursor: 'pointer' }}>
                        {this.props.onSetActive != null && (this.props.activeKey != null || this.props.isActive != null) &&
                            <StyledTableCell key={"STC_" + index} sx={{ width: 25, textAlign: "center !important" }} onClick={(event) => { event.stopPropagation(); this.props.onSetActive(index, !row[this.props.activeKey]); }} >
                                {
                                    // Regardless of when the checked value is being set using a own function or a key, the key is always mandatory
                                    // since this is the value that is being used to identify the row by the virtual dom of react
                                    // otherwise the items will not be updated correctly...
                                this.props.activeCompType == GT_ACTIVETYPES.CHECKBOX ?
                                    <Checkbox key={"AB_"+index+"_"+row[this.props.activeKey]} disabled={this.props.activeCompDisabled} color="primary" checked={this.props.isActive ? this.props.isActive(row) : row[this.props.activeKey]} />
                                    :
                                    this.props.activeCompType == GT_ACTIVETYPES.RADIO ?
                                        <Radio key={"AB_"+index+"_"+row[this.props.activeKey]} type='radio' disabled={this.props.activeCompDisabled} checked={this.props.isActive ? this.props.isActive(row) : row[this.props.activeKey]} />
                                        :
                                        <Switch key={"AB_"+index+"_"+row[this.props.activeKey]} size='small' disabled={this.props.activeCompDisabled} color="primary" checked={this.props.isActive ? this.props.isActive(row) : row[this.props.activeKey]} />
                                }
                                {/* <Switch size='small' color="primary" checked={row[this.props.activeKey]} /> */}
                            </StyledTableCell>
                        }
                        {this.props.columns.map((column) => {
                            // console.log("ROW: ", row);
                            return <Column column={column} row={row} />

                        })}
                        {this.props.onDelete != null &&
                            <StyledTableCell sx={{ width: 25 }} onClick={(event) => { event.stopPropagation(); this.props.onDelete(index, row.id); }} >
                                <IconButton size="small" color='primary' >
                                    <Delete />
                                </IconButton>
                            </StyledTableCell>
                        }
                        {this.props.onEdit != null &&
                            <StyledTableCell sx={{ width: 25 }} onClick={(event) => { event.stopPropagation(); this.props.onEdit(index, row.id); }} >
                                <IconButton size="small" color='primary' >
                                    <Edit />
                                </IconButton>
                            </StyledTableCell>
                        }
                    </StyledTableRow>
                })}
            </TableBody>
        )
    }
}

export default GTTableBody
