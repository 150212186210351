import React, {  useEffect } from 'react'
import SupportCaseBarItem from './SupportCaseBarItem';
import { connect } from 'react-redux';
// import { increaseUnreadItemsCountForCaseId, removeActiveCaseByIndex } from '../../../../redux/supportSlice';
import { io } from 'socket.io-client';
import { SOCKET_EVENT } from '../SupportChatDialog';
// import notification1 from 
// var innerCaseList = null;
var firstTime = true;
export const ActiveCasesBar = (props) => {
    var mainNSSocket = io('https://viveel.de:3002', {withCredentials: true});
    useEffect(() => {
        
    }, []);
    useEffect(() => {
        if(!firstTime){
            new Audio(require('../../../../assets/sounds/notification3.wav')).play();
        }else{
            firstTime=false;
        }
    }, [props.notificationSoundToggle])
    function onCloseCase(id){
        mainNSSocket.emit(SOCKET_EVENT.LEAVE_ROOM, id);
    }
    return (
        <div style={{ position: "absolute", display: "flex", overflow: "visible", flexDirection: "row", zIndex: 3, bottom: props.caseList?.length > 0 ? 0 : -50, left: 50, right: 50, height: 50, }}>
            {props.caseList?.map((item, index) => {
                return <SupportCaseBarItem onClose={() => {onCloseCase(item?.id?.toString())}} key={"supportCaseBarItem_" + item?.id?.toString()} index={index} caseId={item?.id} unreadItems={item?.unreadItems} />
            })}
        </div>
    )
    // }
}
function mapStateToProps(state) {
    return {
        caseList: state.support.activeCaseList,
        role: state.permission.role,
        notificationSoundToggle: state.support.playNotificationSoundToggle

    }
}
function mapDispatchToProps(dispatch){
    return {
        // increaseUnreadItemsQty: (caseId) => {
        //     // dispatch(increaseUnreadItemsCountForCaseId(caseId));
        // }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ActiveCasesBar)
