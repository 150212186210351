import React, { Component } from 'react'
import DefaultFrame from '../../components/DefaultFrame'
import { Button, CircularProgress, TextField, Typography } from '@mui/material'
import { withParams } from '../../hookInjection/withParams'
import { GD_MODE, GD_STATUS_TYPE, GENERIC_DRAWER_TYPE } from '../../components/GenericDrawer'
import PartnerTile from './PartnerTile'
import ShopTile from './ShopTile'
import { APIData } from '../../constants/APIAdresses'
import { ALERT_SEVERITY } from '../../components/AlertDisplay2'
import TileComponent from '../../components/TileComponent'
import CollapsibleTile from '../../components/CollapsibleTile'
import { Delete, PasswordOutlined, Save } from '@mui/icons-material'
import SecretTextField from '../../components/SecretTextField'
import { MUI_BTN_VARIANTS, MUI_COLORS } from '../../constants/MUIConstants'
import PayPalTile from './Shop/PayPalTile'
import DeliveryRulesTile from './Shop/DeliveryRulesTile'
import OpeningHoursTile from './Shop/OpeningHoursTile'
import MultipleAutocomplete from '../../components/MultipleAutocomplete'
import SectorsTile from './Shop/SectorsTile'
import DirectLinkButton from './Shop/DirectLinkButton'
// Define TestWrapper and TestChild

export class Shop extends Component {
    constructor(props) {
        super(props);
        console.log("PROPS: ", Object.keys(props));
        this.state = {
            data: [],
            shopData: {},
        }
        this.companyId = this.props.params.companyId;
        this.shopId = this.props.params.shopId;
    }
    uploadImage(file, uploadType) {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("image", file);
            console.log("UPLOADTYPE: ", uploadType);
            fetch(APIData.MEDIA + "/upload?type=" + uploadType, { body: formData, method: "POST", credentials: "include" })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        reject("Error while uploading image");
                    }
                }).then(result => {
                    console.log("UPLOADRESULT: ", result);
                    if (result?.path) {
                        resolve(result.path);
                    } else {
                        reject("Error while uploading image");
                    }
                }).catch(e => {
                    reject("Error while uploading image: " + e);
                })
            // })
        })
    }
    checkUpdateImages(configData) {
        return new Promise((resolve, reject) => {
            let imgPromises = [];
            var updatedConfigData = { ...configData };
            configData.fields.forEach((field, index) => {
                // .filter(field => field.status === GD_STATUS_TYPE.UPDATE && field.type === GENERIC_DRAWER_TYPE.IMAGE)
                console.log("Going through fields: ", field);
                if (field.status === GD_STATUS_TYPE.UPDATE && field.type === GENERIC_DRAWER_TYPE.IMAGE) {
                    imgPromises.push(
                        this.uploadImage(field.value, field.uploadType).then(result => {
                            updatedConfigData.fields[index].value = result;
                        }).catch(e => {
                            console.log("Error while uploading image: ", e);
                        })
                    );
                }
            })
            console.log("Updating files: ", imgPromises);
            Promise.all(imgPromises).then(() => {
                resolve({ ...updatedConfigData });
            }).catch(e => {
                console.log("PROMISE_Error while updating images: ", e);
            })
        })
    }
    updateShop(configData) {
        this.checkUpdateImages(configData)
            .then(updatedConfigData => {
                console.log("UPDATEDCONFIGDATA: ", updatedConfigData);
                configData = { ...updatedConfigData };
                let body = {
                    fields: {}
                }
                let lat = "";
                let lon = "";
                const showErr = () => {
                    this.setState({
                        alertDisplayProps: {
                            alertDisplayMsg: "Beim Aktualisieren der Daten ist ein Fehler aufgetreten.",
                            alertDisplayVisible: true,
                            alertSeverity: ALERT_SEVERITY.ERROR
                        },
                        // drawerProps: {
                        //     ...this.state.drawerProps,
                        //     isOpen: false,
                        // },
                    })
                    this.props.closeDrawer();
                }
                configData.fields.filter(field => field.status === GD_STATUS_TYPE.UPDATE).forEach((field, index) => {
                    if (field.value != null) {
                        if (field.dbKey === "gpsPosition") {
                            if (field.label === "Breitengrad") {
                                lat = field.value;
                            } else {
                                lon = field.value;
                            }
                        } else {
                            body.fields[field.dbKey] = field.value;
                        }
                    }
                });
                if (lat.length > 0 && lon.length > 0) {
                    body.fields["gpsPosition"] = `POINT (${lat} ${lon})`;
                }
                console.log("SHOP BODY: ", body);
                if (Object.keys(body.fields).length > 0) {
                    fetch(APIData.SHOP + "/shop/" + configData.id + "/updateInformation", { body: JSON.stringify(body), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
                        .then(response => {
                            if (!response.ok) {
                                showErr();
                            } else {
                                this.props.closeDrawer();
                                this.props.showAlertDisplay("Shopdaten wurden erfolgreich aktualisiert.", ALERT_SEVERITY.SUCCESS);
                                let myData = { ...this.state.shopData };
                                configData.fields.filter(field => field.status === GD_STATUS_TYPE.UPDATE).forEach((field) => {
                                    myData[field.dbKey] = field.value;
                                })
                                this.setState({ shopData: { ...myData } });

                            }
                        }).catch(e => {
                            console.log("Error while update shop \nbody: ", body, "\nerror: ", e);
                            showErr();
                        })
                } else {
                    this.props.showAlertDisplay("Es wurden keine Änderungen vorgenommen.", ALERT_SEVERITY.INFO);
                    this.props.closeDrawer();
                }
            }).catch(e => {
                console.log("Error while update shop (checkUpdateImage): ", e);
                this.props.showAlertDisplay("Beim Aktualisieren der Daten ist ein Fehler aufgetreten.", ALERT_SEVERITY.ERROR);
            })
    }
    openShopEditDrawer() {
        let baseDrawerConfig = {
            id: this.state.shopData.id,
            options: [
                { label: "Speichern", onClick: (configData) => { this.updateShop(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "Aktiv",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    dbKey: "isActive",
                    value: this.state.shopData.isActive,
                },
                {
                    label: "Titelbild",
                    type: GENERIC_DRAWER_TYPE.IMAGE,
                    value: this.state.shopData.imgSource,
                    baseUri: APIData.MEDIA,
                    uploadType: APIData.MEDIA_TYPES.HEADERS,
                    dimensions: { width: 800, height: 200 },
                    dbKey: "imgSource"
                },
                {
                    label: "Logo",
                    type: GENERIC_DRAWER_TYPE.IMAGE,
                    value: this.state.shopData.iconSource,
                    baseUri: APIData.MEDIA,
                    uploadType: APIData.MEDIA_TYPES.ICONS,
                    dimensions: { width: 500, height: 500 },
                    dbKey: "iconSource"
                },
                {
                    label: "ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: true,
                    value: this.state.shopData.id,
                    dbKey: "id"
                }, {
                    label: "Name",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.shopData.name,
                    dbKey: "name"
                },
                {
                    label: "Straße",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.shopData.street,
                    dbKey: "street"
                },
                {
                    label: "Hausnummer",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.shopData.houseNumber,
                    dbKey: "houseNumber"
                },
                {
                    label: "Ort",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.shopData.city,
                    dbKey: "city"
                },
                {
                    label: "PLZ",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.shopData.zip,
                    dbKey: "zip"
                },
                {
                    label: "Telefon",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.shopData.phone,
                    dbKey: "phone"
                },
                {
                    label: "GPS-Koordinaten",
                    type: GENERIC_DRAWER_TYPE.DIVIDER,
                },
                {
                    label: "Breitengrad",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.shopData.latitude,
                    dbKey: "gpsPosition",
                    isRequired: true
                },
                {
                    label: "Längengrad",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.shopData.longitude,
                    dbKey: "gpsPosition",
                    isRequired: true
                },
                {
                    label: "",
                    type: GENERIC_DRAWER_TYPE.DIVIDER,
                },
                {
                    label: "E-Mail",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.shopData.mail,
                    dbKey: "mail"
                },
                {
                    label: "Zusatzinfo",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.shopData.additionalInfo,
                    dbKey: "additionalInfo"
                },
                {
                    label: "Abholrabatt",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.shopData.pickupDiscount,
                    dbKey: "pickupDiscount"
                },
                {
                    label: "Kartenzahlung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: this.state.shopData.debitAvailable,
                    dbKey: "debitAvailable"
                },
                {
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    label: "Zahlungen Deaktiviert",
                    value: this.state.shopData.paymentsDisabled,
                    dbKey: "paymentsDisabled"
                },
                {
                    label: "Großhandelshop",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: this.state.shopData.partnerDealShop,
                    dbKey: "partnerDealShop"
                },
                {
                    label: "Automatische Externe Lieferung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: this.state.shopData.extDeliveryByDefault,
                    dbKey: "extDeliveryByDefault"
                },
                {
                    label: "Externe Lieferung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: this.state.shopData.extDeliveryActive,
                    dbKey: "extDeliveryActive"
                }

            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                {/* <div style={{display:"flex", flexDirection:"row"}}> */}
                    {/* <Typography variant="h4">Shopkonfiguration</Typography>
                     */}
                {/* </div> */}
                     <DirectLinkButton path='./contentmanagement' title='Content Management' icon={<img src={require('../../assets/icons/product-management.png')} width={30} height={30}/>}/>
                <ShopTile id={this.shopId} data={this.state.shopData} onChange={(data) => { this.setState({ shopData: { ...data } }) }} onEdit={() => { this.openShopEditDrawer() }} onDelete={() => { console.log("Delete CLICKED"); }} />
                {/* <MultipleAutocomplete label='Branche'/> */}
                
                <OpeningHoursTile shopId={this.shopId} showAlert={(msg, severity) => { this.props.showAlertDisplay(msg, severity) }} openAlertDialog={(options, title, msg) => { this.props.openAlertDialog(options, title, msg) }} />
                <DeliveryRulesTile shopId={this.shopId} showAlert={(msg, severity) => { this.props.showAlertDisplay(msg, severity) }} openAlertDialog={(options, title, msg) => { this.props.openAlertDialog(options, title, msg) }} />
                <SectorsTile shopId={this.shopId} showAlert={(msg, severity) => { this.props.showAlertDisplay(msg, severity) }} openAlertDialog={(options, title, msg) => { this.props.openAlertDialog(options, title, msg) }} />
                <PayPalTile shopId={this.shopId} showAlert={(msg, severity) => {
                    console.log("PROPS: ", this.props)
                    this.props.showAlertDisplay(msg, severity)
                }} />

            </div>

        )
    }
}
// This is 
class defaultWrapper extends React.Component {
    render() {
        return (
            <DefaultFrame title="Shop">
                <Shop {...this.props} />
            </DefaultFrame>
        )
    }
}
export default withParams(defaultWrapper)
// export default withParams(<DefaultFrame>{Shop}</DefaultFrame>)