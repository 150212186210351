import React, { Component } from 'react'
import { ALERT_SEVERITY } from '../../../components/AlertDisplay2';
import { APIData } from '../../../constants/APIAdresses';
import CollapsibleTile from '../../../components/CollapsibleTile';
import { Delete, Save } from '@mui/icons-material';
import { MUI_BTN_VARIANTS, MUI_COLORS } from '../../../constants/MUIConstants';
import SecretTextField from '../../../components/SecretTextField';

export class PayPalTile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paypalApiKey: "",
            paypalApiKeyLoading: false,
            paypalDeleteDisabled: true,
        }
        this.paypalApiKey="";
        this.paypalApiKeyId="";
        this.shopId = this.props.shopId;
    }
    loadPaypalApiKey() {
        const URI = APIData.USER + "/shop/" + this.shopId + "/getPaypalAPIKey";
        this.setState({ paypalApiKeyLoading: true });
        fetch(URI, { method: "GET", credentials: "include", headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                console.log("1111");
                if (response.status === 200) {
                    return response.json();
                } else {
                    console.log("NULLRETURN")
                    return null;
                }
            }).then(result => {
                console.log("Paypal API Key: ", result);
                if (result?.apiKey && result?.id) {
                    console.log("Paypal API Key: ", result);
                    console.log("PUTTING IN STATE: ", (result.apiKey != null ? result.apiKey : ""));
                    this.setState({
                        paypalApiKeyLoading: false, paypalApiKey: result.apiKey != null ? result.apiKey : "", paypalDeleteDisabled: false,
                    });
                    this.paypalApiKey = result.apiKey;
                    this.paypalApiKeyId = result.id;
                } else {
                    this.setState({ paypalApiKeyLoading: false });
                }
            }).catch(e => {
                console.log("Error while loading paypal api key: ", e);
                this.setState({
                    paypalApiKeyLoading: false,
                });
                this.props.showAlert("Fehler beim Laden vom PayPal API-Key.", ALERT_SEVERITY.ERROR)
            })
    }
    addUpdatePaypalKey() {
        console.log("AddUpdatePaypalKey: ", this.state.paypalApiKey);
        this.setState({ paypalApiKeyLoading: true });
        fetch(APIData.USER + `/shop/${this.shopId}/addUpdatePaypalAPIKey`, { method: "PUT", credentials: "include", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ apiKey: this.state.paypalApiKey }) })
            .then(response => {
                console.log("AddUpdateResponseStat: ", response.status);
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            }).then(result => {
                console.log("AddUpdateResult: ", result);
                if (result?.id) {
                    this.setState({
                        paypalApiKeyLoading: false,
                        paypalDeleteDisabled: false,
                    });

                    this.props.showAlert("Paypal API Key wurde erfolgreich gespeichert.", ALERT_SEVERITY.SUCCESS);
                    this.paypalApiKey = this.state.paypalApiKey;
                    this.paypalApiKeyId = result.id;
                }
            }).catch(e => {
                console.log("Error while adding/updating paypal api key: ", e);
            })
    }
    deletePaypalKey() {
        this.setState({ paypalApiKeyLoading: true });
        const showErr = () => {
            this.setState({
                paypalApiKeyLoading: false,
            });
            this.props.showAlert("Fehler beim Löschen der PayPal API-Key.", ALERT_SEVERITY.ERROR);
        }
        fetch(APIData.USER + `/deleteShopPaypalApiKey/${this.paypalApiKeyId}`, { method: "DELETE", credentials: "include" })
            .then(response => {
                if (response.ok) {
                    this.setState({
                        paypalApiKeyLoading: false, paypalApiKey: "", paypalDeleteDisabled: true,
                    });
                    this.props.showAlert("Paypal API Key wurde entfernt.", ALERT_SEVERITY.SUCCESS);
                    this.paypalApiKey = "";
                    this.paypalApiKeyId = "";
                } else {
                    console.log("Error while deleting paypal api key: ", response.status);
                    showErr();
                }
            }).catch(e => {
                console.log("Error while deleting paypal api key: ", e);
                showErr();
            })

    }
    render() {
        return (
            <CollapsibleTile
                
                onCollapseChange={(isExpanded, firstExpand) => {
                    if (isExpanded && firstExpand) {
                        this.loadPaypalApiKey()
                    }
                }}
                isLoading={this.state.paypalApiKeyLoading}
                actions={[
                    { label: "Löschen", icon: <Delete />, disabled: this.state.paypalDeleteDisabled, color: MUI_COLORS.ERROR, variant: MUI_BTN_VARIANTS.OUTLINED, onClick: () => { this.deletePaypalKey() } },
                    { label: "Speichern", disabled: this.state.paypalApiKey === this.paypalApiKey, icon: <Save />, onClick: () => { this.addUpdatePaypalKey() } },
                ]}
                title="PayPal API-Key" icon={<img src={require("../../../assets/icons/paypal_icon.png")} width={18} height={18} />}>
                <div style={{ width: "100%", }}>
                    <SecretTextField label='API-Key' value={this.state.paypalApiKey} onChange={(value) => { this.setState({ paypalApiKey: value }) }} />
                </div>

            </CollapsibleTile>
        )
    }
}

export default PayPalTile