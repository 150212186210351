import React, { Component } from 'react'
import "../../assets/css/general.css";
import { ROLES, setRole } from '../../redux/permissionSlice';
// import "./user.css"
import { setUserTableData } from '../../redux/userManagementSlice';
import { connect } from 'react-redux';
import { Add, Block, Delete, Save } from '@mui/icons-material';
import { USEREDITORDRAWERMODE } from './Elements/EditorDrawer';
import { GenericDrawer, GENERIC_DRAWER_TYPE, GD_MODE, GD_STATUS_TYPE } from '../../components/GenericDrawer';

import { APIData } from '../../constants/APIAdresses';
import DefaultFrame from '../../components/DefaultFrame';
import GenericTable from '../../components/GenericTable/GenericTable';
import { MUI_COLORS } from '../../constants/MUIConstants';
import { ALERT_SEVERITY } from '../../components/AlertDisplay2';
import AlertDialog from '../../components/AlertDialog';

const columns = [
    {
        width: 50,
        label: 'ID',
        dataKey: 'id',
    },
    {
        // width: 2,
        label: 'Rolle',
        dataKey: 'role',
        format: (value) => {
            let index = Object.values(ROLES).findIndex(rVal => rVal.toString() === value.toString())
            return index != -1 ? Object.keys(ROLES)[index] : value;
        }
    },
    {
        // width:2,
        label: 'Benutzername',
        dataKey: 'username',
        format: (value) => { return value != null ? value : "-" }
    },
    {
        // width: 3,
        label: 'Telefonnummer',
        dataKey: 'phoneNumber',
    },
    {
        // width: 4,
        width: 150,
        label: 'Name',
        dataKey: 'name',
    },
    {
        // width: 3,
        label: 'E-Mail Adresse',
        dataKey: 'email',
    },
    {
        width: 150,
        label: 'SocialProvider-ID',
        dataKey: 'externalId',
    },
    {
        width: 150,
        label: 'Social-Provider',
        dataKey: 'externalAPType',
    },
]
const ENTITY_TYPE = {
    ADDRESS: 1
}
export class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            alertDisplayMsg: "",
            alertDisplayVisible: false,
            alertSeverity: ALERT_SEVERITY.INFO,
            selectedIndex: -1,
            editDrawerMode: USEREDITORDRAWERMODE.NEW,
            drawerOpen: false,
            drawerConfigs: {
                options: [],
                fields: [],
            },
            ADOptions: [],
            ADOpen: false,
            ADMessage: "",
            ADTitle: "",
            drawerData: {},
            tableData: [],
            pageIndex: 0,
            pageCount: 10,
        }
        this.canPreviousPage = true
        this.canNextPage = true
        this.pageIndex = 0;

        this.searchValue = "";
        this.searchActive = false;
    }

    componentDidMount() {
        // load data and define 
        // this.loadInitial();
    }
    updateUserFields(id, fieldList) {
        return new Promise((resolve, reject) => {
            // /user/:id/updateData
            let body = {
                fields: {}
            }
            fieldList.forEach(fieldItem => {
                body.fields[fieldItem.dbKey] = fieldItem.value;
            })
            console.log("Sending body: ", body);
            fetch(APIData.USER + `/user/${id}/updateData`, { method: "POST", body: JSON.stringify(body), credentials: "include", headers: { "Content-Type": "application/json" } })
                .then(response => {
                    if (response.ok) {
                        resolve()
                    } else {
                        reject();
                    }
                }).catch(() => {
                    reject();
                })
        })
    }

    updateAddressData(id, addressData) {
        // return new Promise((resolve, reject) => {
        let body = {
            company: addressData.fields[0].value,
            fullname: addressData.fields[1].value,
            street: addressData.fields[2].value,
            houseNumber: addressData.fields[3].value,
            zip: addressData.fields[4].value,
            city: addressData.fields[5].value,
            phone: addressData.fields[6].value,
            InvCompany: addressData.fields[8].value,
            InvFullname: addressData.fields[9].value,
            InvStreet: addressData.fields[10].value,
            InvNumber: addressData.fields[11].value,
            InvZip: addressData.fields[12].value,
            InvCity: addressData.fields[13].value,
            InvPhone: addressData.fields[14].value,
        };
        if (addressData.fields.slice(8, 15).every(addItem => addItem.value === "")) {
            body.sameInvoiceAddress = true;
        } else {
            body.sameInvoiceAddress = false;
        }
        if (id != null) {
            body.userId = id;
        }
        if (addressData.id != null) {
            body.id = addressData.id
        }
        console.log("Sending body: ", body);
        switch (addressData.status) {
            case GD_STATUS_TYPE.NEW:
                // /userdata/address/insert
                return fetch(APIData.USER + "/userdata/address/insert", { credentials: "include", method: "POST", body: JSON.stringify(body), headers: { 'Content-Type': "application/json" } });
            case GD_STATUS_TYPE.UPDATE:
                // /userdata/address/update
                return fetch(APIData.USER + "/userdata/address/update", { credentials: "include", method: "POST", body: JSON.stringify(body), headers: { 'Content-Type': "application/json" } });
            case GD_STATUS_TYPE.DELETE:
                return fetch(APIData.USER + `/userdata/${id}/address/delete/${addressData.id}`, { credentials: "include", method: "DELETE" });
        }
        // })
    }
    addUser(data) {
        console.log("userData: ", data);
        console.log("TableData: ", this.state.tableData);

        let body = {
            username: data.fields[0].value,
            password: data.fields[1].value,
            role: data.fields[2].value,
            phoneNumber: data.fields[3].value,
            name: data.fields[4].value,
            email: data.fields[5].value,
            totpbase: data.fields[6].value,
        }
        this.setState({ drawerOpen: false });
        fetch(APIData.USER + "/auth/register", { method: "POST", credentials: "include", body: JSON.stringify(body), headers: { "Content-Type": "application/json" } })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.error("AddUser (/auth/register) failed with following status code: ", response.status);
                    return null;
                }
            }).then(result => {
                if (result?.userId != null) {
                    let td = [...this.state.tableData];
                    td = [
                        {
                            "id": result.userId,
                            "username": data.fields[0].value,
                            "password": "",
                            "role": data.fields[2].value,
                            "phoneNumber": data.fields[3].value,
                            "name": data.fields[4].value,
                            "email": data.fields[5].value,
                            "totpbase": data.fields[6].value,
                            "defaultAddressId": null,
                            "deviceToken": null,
                            "externalId": null,
                            "externalAPType": null,
                            "isActive": 1
                        }
                        , ...td]
                    this.setState({ tableData: [...td] });
                }
            }).catch(e => {
                console.error("Error while AddUser: ", e);
            })
    }
    updateUser(id, fields, index) {
        if (fields.length > 0) {
            console.log("fields: ", fields);
            let coreDataFields = fields.filter(fItem => fItem.entityKey == null);
            let addressEntityFields = fields.filter(fItem => fItem.entityKey == ENTITY_TYPE.ADDRESS);
            let coreDataError = false;
            let addressError = false;
            let promises = [];
            if (coreDataFields.length > 0) {
                console.log("Updating userData (userId=" + id + "): ", coreDataFields);
                promises.push(this.updateUserFields(id, coreDataFields).catch(() => { coreDataError = true }))

            }
            if (addressEntityFields.length > 0) {
                addressEntityFields.forEach(addressItem => {
                    console.log("ADDRESSITEM: ", addressItem);
                    promises.push(this.updateAddressData(id, addressItem).catch(() => { addressError = true }));
                })
            }
            Promise.all(promises).then(() => {
                // console.log("Everything has been updated...");
                // this.setState({
                //     drawerOpen: false,
                //     alertDisplayMsg: "Benutzerdaten wurden erfolgreich aktualisiert.",
                //     alertDisplayVisible: true,
                //     alertSeverity: ALERT_SEVERITY.SUCCESS
                // });
                this.props.showAlertDisplay("Benutzerdaten wurden erfolgreich aktualisiert.", ALERT_SEVERITY.SUCCESS);
                this.props.closeDrawer();
                // do updates in the main data of table.
                if (coreDataFields.length > 0) {
                    let myTableData = [...this.state.tableData];
                    // let currentItem = { ...this.state.tableData.find(item => item.id === id) };
                    coreDataFields.forEach(field => {
                        myTableData[index][field.dbKey] = field.value;
                    })
                    this.setState({ tableData: [...myTableData] });
                    // console.log("UPDATING ITEMS: ",currentItem);
                    // let 
                    // this.props.setItemAtSelectedIndex(currentItem);
                }
            }).catch(() => {
                // do some error handling
                // this.setState({
                //     drawerOpen: false,
                //     alertDisplayMsg: "Fehler beim aktualisieren von Benutzerdaten.",
                //     alertDisplayVisible: true,
                //     alertSeverity: ALERT_SEVERITY.ERROR
                // });

                this.props.showAlertDisplay("Fehler beim aktualisieren von Benutzerdaten.", ALERT_SEVERITY.ERROR);
                this.props.closeDrawer();
            })
            console.log("CoreDataFields: ", coreDataFields);
        }
    }
    testAsyncCheck() {
        return new Promise((resolve, reject) => {
            console.log("testAsyncCheckCalled")
            setTimeout(() => {
                console.log("testAsyncCheckResolved");
                // resolve({success: true});
                resolve({ success: false, errorMsg: "Benutzername vergeben." });
            }, 1000);
        })
    }
    openDrawerForNewUser() {
        let baseDrawerConfig = {
            options: [
                {
                    label: "Hinzufügen",
                    icon: <Save />,
                    doRequiredFieldCheck: true,
                    onClick: (configData) => { this.addUser(configData) }
                }
            ],
            fields: [
                {
                    label: "Benutzername",
                    disabled: false,
                    isRequired: true,
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "username",
                    asyncCheck: this.testAsyncCheck,
                },
                {
                    label: "Passwort",
                    disabled: false,
                    isRequired: true,
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    passwordField: true,
                    dbKey: "password",
                },
                {
                    label: "Rolle",
                    disabled: false,
                    type: GENERIC_DRAWER_TYPE.ENUM,
                    options: { ...ROLES },
                    value: "",
                    dbKey: "role"
                },
                {
                    label: "Telefonnummer",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: false,
                    value: "",
                    isPhone: true,
                    dbKey: "phoneNumber"
                },
                {
                    label: "Name",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: false,
                    isRequired: true,
                    maxChar: 30,
                    value: "",
                    dbKey: "name"
                },
                {
                    label: "E-Mail Adresse",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: false,
                    value: "",
                    dbKey: "email"
                },
                {
                    label: "TOTP-Base",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: false,
                    value: "",
                    dbKey: "totpbase"
                },
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.NEW);
        // this.setState({
        //     drawerOpen: true,
        //     drawerConfigs:
        //         { ...baseDrawerConfig }
        // })
    }
    validateAddress(fieldIndex) {
        let addressFields = [...this.state.drawerConfigs.fields[fieldIndex].fields];
        console.log("ADDRESSFIELDS: ", addressFields);
        if (addressFields.slice(8).map(addItem => addItem.value).join("").length > 0) {
            let isValid = true;
            addressFields.slice(9, 14).forEach((addItem, index) => {
                if (isValid) {
                    if (addItem.value.length === 0) {
                        console.log(addItem.label + " is not valid! It is empty!");
                        // addressFields[index+9].value = 
                    } else {
                        console.log(addItem.label + " has data and is valid...")
                    }
                }
            })
        } else {
            return true;
        }
    }
    openDrawer(index, data) {
        let baseDrawerConfig = {
            id: data.id,
            index: index,
            options: [
                {
                    label: "Speichern",
                    onClick: (configData) => {
                        this.updateUser(configData?.id, configData?.fields?.filter(fItem => fItem.status != null && fItem.status != GD_STATUS_TYPE.IDLE), index)
                    },
                    icon: <Save />,
                },
                {
                    label: "Löschen",
                    color: "error",
                    icon: <Delete />,
                },
                {
                    label: "Deaktivieren",
                    color: "warning",
                    variant: "outlined",
                    icon: <Block />,
                }
            ],
            fields: [
                {
                    label: "ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: true,
                    value: data.id,
                    isNumeric: true,
                    dbKey: "id"
                },
                {
                    label: "Benutzername",
                    disabled: false,
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: data.username,
                    dbKey: "username",
                    asyncCheck: this.testAsyncCheck
                },
                {
                    label: "Rolle",
                    disabled: false,
                    type: GENERIC_DRAWER_TYPE.ENUM,
                    options: { ...ROLES },
                    value: data.role?.toString(),
                    dbKey: "role"
                },
                {
                    label: "Telefonnummer",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: false,
                    value: data.phoneNumber != null ? data.phoneNumber : "",
                    isPhone: true,
                    dbKey: "phoneNumber"
                },
                {
                    label: "Name",
                    maxChar: 30,
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: false,
                    value: data.name,
                    dbKey: "name",
                    isRequired: true,
                    minChar: 4,
                    check: (text) => { if (text !== "Hallo") { return "Fehler, weil ich nur 'Hallo' akzeptiere. :D" } }
                },
                {
                    label: "E-Mail Adresse",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: false,
                    value: data.email != null ? data.email : "",
                    dbKey: "email",
                    isMail: true
                },
                {
                    label: "TOTP-Base",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: false,
                    value: data.totpbase != null ? data.totpbase : "",
                    dbKey: "totpbase"
                },
                {
                    label: "Adressen",
                    entityKey: ENTITY_TYPE.ADDRESS,
                    type: GENERIC_DRAWER_TYPE.DIVIDER,
                }
            ]
        }
        console.log("Loading addresses...");

        fetch(APIData.USER + "/userdata/address/getAll?userId=" + data.id, { credentials: "include" })
            .then(response => {
                console.log("Address response: ", response.status);
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            }).then(result => {
                if (result != null) {
                    console.log("AddressResult: ", result);
                    if (result.addressList?.length > 0) {
                        result.addressList.map(addressItem => {
                            let editableField = {
                                type: GENERIC_DRAWER_TYPE.EDITABLE,
                                isEditMode: false,
                                id: addressItem.id,
                                entityKey: ENTITY_TYPE.ADDRESS,
                                fields: [
                                    {
                                        label: "Firma",
                                        value: addressItem.company?.length > 0 ? addressItem.company : "",
                                        type: GENERIC_DRAWER_TYPE.TEXT,
                                        dbKey: "company",
                                    },
                                    {
                                        label: "Name",
                                        value: addressItem.fullname?.length > 0 ? addressItem.fullname : "",
                                        type: GENERIC_DRAWER_TYPE.TEXT,
                                        dbKey: "fullname",
                                        asyncCheck: this.testAsyncCheck
                                    },
                                    {
                                        label: "Straße",
                                        value: addressItem.street?.length > 0 ? addressItem.street : "",
                                        type: GENERIC_DRAWER_TYPE.TEXT,
                                        dbKey: "street",
                                    },
                                    {
                                        label: "Hausnummer",
                                        value: addressItem.houseNumber?.length > 0 ? addressItem.houseNumber : "",
                                        type: GENERIC_DRAWER_TYPE.TEXT,
                                        dbKey: "houseNumber",
                                    },
                                    {
                                        label: "PLZ",
                                        isNumeric: true,
                                        maxChar: 5,
                                        value: addressItem.zip?.length > 0 ? addressItem.zip : "",
                                        type: GENERIC_DRAWER_TYPE.TEXT,
                                        dbKey: "zip",
                                    },
                                    {
                                        label: "Ort",
                                        value: addressItem.city?.length > 0 ? addressItem.city : "",
                                        type: GENERIC_DRAWER_TYPE.TEXT,
                                        dbKey: "city",
                                    },
                                    {
                                        label: "Telefon",
                                        isNumeric: true,
                                        isPhone: true,
                                        value: addressItem.phone?.length > 0 ? addressItem.phone : "",
                                        type: GENERIC_DRAWER_TYPE.TEXT,
                                        dbKey: "phone",
                                    },
                                    {
                                        label: "Rechnungsaddresse",
                                        type: GENERIC_DRAWER_TYPE.DIVIDER,
                                    },
                                    {
                                        label: "Firma",
                                        value: addressItem.InvCompany?.length > 0 ? addressItem.InvCompany : "",
                                        type: GENERIC_DRAWER_TYPE.TEXT,
                                        dbKey: "InvCompany",
                                    },
                                    {
                                        label: "Name",
                                        value: addressItem.InvFullname?.length > 0 ? addressItem.InvFullname : "",
                                        type: GENERIC_DRAWER_TYPE.TEXT,
                                        dbKey: "InvFullname",
                                    },
                                    {
                                        label: "Straße",
                                        value: addressItem.InvStreet?.length > 0 ? addressItem.InvStreet : "",
                                        type: GENERIC_DRAWER_TYPE.TEXT,
                                        dbKey: "InvStreet",
                                    },
                                    {
                                        label: "Hausnummer",
                                        value: addressItem.InvNumber?.length > 0 ? addressItem.InvNumber : "",
                                        type: GENERIC_DRAWER_TYPE.TEXT,
                                        dbKey: "InvNumber",
                                    },
                                    {
                                        label: "PLZ",
                                        isNumeric: true,
                                        value: addressItem.InvZip?.length > 0 ? addressItem.InvZip : "",
                                        type: GENERIC_DRAWER_TYPE.TEXT,
                                        dbKey: "InvZip",
                                    },
                                    {
                                        label: "Ort",
                                        value: addressItem.InvCity?.length > 0 ? addressItem.InvCity : "",
                                        type: GENERIC_DRAWER_TYPE.TEXT,
                                        dbKey: "InvCity",
                                    },
                                    {
                                        label: "Telefon",
                                        isNumeric: true,
                                        isPhone: true,
                                        value: addressItem.InvPhone?.length > 0 ? addressItem.InvPhone : "",
                                        type: GENERIC_DRAWER_TYPE.TEXT,
                                        dbKey: "InvPhone",
                                    },

                                ],
                                check: (index) => { this.validateAddress(index) },
                                asyncCheck: (index) => { return new Promise((resolve, reject) => { console.log("Doing the final async check (index=" + index + "): ", this.state.drawerConfigs.fields[index]); resolve() }) }
                            }
                            console.log("editableField Address: ", editableField);
                            baseDrawerConfig.fields.push(editableField);
                        })

                    }
                }

                this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
                // this.setState({
                //     // selectedIndex: index,
                //     drawerOpen: true,
                //     drawerConfigs:
                //         { ...baseDrawerConfig }
                // })
            }).finally(() => {
                baseDrawerConfig.fields.push({
                    type: GENERIC_DRAWER_TYPE.ENTITY_ADD,
                    fields: [
                        {
                            label: "Firma",
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "company",
                        },
                        {
                            label: "Name",
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "fullname",
                            isRequired: true,
                            asyncCheck: this.testAsyncCheck,
                        },
                        {
                            label: "Straße",
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "street",
                        },
                        {
                            label: "Hausnummer",
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "houseNumber",
                        },
                        {
                            label: "PLZ",
                            isNumeric: true,
                            maxChar: 5,
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "zip",
                        },
                        {
                            label: "Ort",
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "city",
                        },
                        {
                            label: "Telefon",
                            isNumeric: true,
                            isPhone: true,
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "phone",
                        },
                        {
                            label: "Rechnungsaddresse",
                            type: GENERIC_DRAWER_TYPE.DIVIDER,
                        },
                        {
                            label: "Firma",
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "InvCompany",
                        },
                        {
                            label: "Name",
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "InvFullname",
                        },
                        {
                            label: "Straße",
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "InvStreet",
                        },
                        {
                            label: "Hausnummer",
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "InvNumber",
                        },
                        {
                            label: "PLZ",
                            isNumeric: true,
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "InvZip",
                        },
                        {
                            label: "Ort",
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "InvCity",
                        },
                        {
                            label: "Telefon",
                            isNumeric: true,
                            isPhone: true,
                            type: GENERIC_DRAWER_TYPE.TEXT,
                            dbKey: "InvPhone",
                        },
                    ],
                    // fields: ["Firma", "Name", "Straße", "Hausnummer", "PLZ", "Ort", "Telefon", "Rechnungsadresse:", "Firma", "Name", "Straße", "Hausnummer", "PLZ", "Ort", "Telefon"],
                    // values: Array.from({ length: 14 }, () => ""),
                    onAdd: (data) => { console.log("Add address: ", data) },
                    check: (data) => { console.log("Final check: ", data) },
                    // asyncCheck: (data) => {},
                    btnIcon: <Add></Add>,
                    btnLabel: "Adresse Hinzufügen",
                    entityKey: ENTITY_TYPE.ADDRESS,
                    // fieldOptions: {
                    //     4: { isNumeric: true, maxChar: 5 }, 6: { isDivider: true }
                    // }
                })
            })
    }
    setAlertDisplayVisible() {
        console.log("setAlertDisplayVisible!!!!");
        this.setState({ alertDisplayVisible: true });
    }
    onSetActive(index, active) {
        let myData = [...this.state.tableData];
        myData[index].isActive = active;
        this.setState({ tableData: [...myData] });
        console.log("isActive: ", active)
        let myBody = { fields: {isActive: active } }
        const showErr = () => {
            let myData = [...this.state.tableData];
            myData[index].isActive = !active;
            this.setState({
                // alertDisplayMsg: "Fehler beim Aktualisieren des Benutzers.",
                // alertDisplayVisible: true,
                // alertSeverity: ALERT_SEVERITY.ERROR,
                tableData: [...myData]
            })
            this.props.showAlertDisplay("Fehler beim aktualisieren von Benutzerdaten.", ALERT_SEVERITY.SUCCESS);
            
        }
        fetch(APIData.USER + `/user/${myData[index].id}/updateData`, { credentials: "include", method: "POST",  body: JSON.stringify(myBody), headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (!response.ok) {
                    console.log("ErrStatus while updating user: ", response.status);
                    showErr();
                }
            }).catch(e => {
                console.log("Error while updating user: ", e);
                showErr();
            })
        //  TODO: Update data in backend
    }
    render() {
        return (
            <>
                <GenericTable
                    config={{ columns: [...columns] }}
                    apiEndPoint={APIData.USER + "/user/getPagedData"}
                    tableData={this.state.tableData}
                    setTableData={(data) => { this.setState({ tableData: data }) }}
                    // onClickRow={(index, rowData) => {console.log("CLICKEDUSER (index="+index+"): ",rowData); this.openDrawer(rowData); this.setAlertDisplayVisible(); }}
                    onClickRow={(index) => {
                        this.openDrawer(index, this.state.tableData[index]);
                        this.setAlertDisplayVisible();
                    }}
                    activeKey="isActive"
                    onSetActive={(index, active) => {
                        this.onSetActive(index, active);
                    }}
                    onEdit={(index) => {
                        console.log("ONEDIT CLICKED: ", this.state.tableData[index]);
                        this.openDrawer(index, this.state.tableData[index]);
                        this.setAlertDisplayVisible();
                    }}
                    onDelete={(index) => {
                        console.log("ONDELETE CLICKED: ", this.state.tableData[index]);
                        this.setState({
                            ADOptions: [
                                {
                                    label: "Ja",
                                    color: MUI_COLORS.ERROR,
                                    onChoice: () => {
                                        console.log("Benutzer wird gelöscht!");
                                        this.setState({ ADOpen: false });
                                    }
                                },
                                {
                                    label: "Nein",
                                    onChoice: () => {
                                        console.log("Benutzer wird nicht gelöscht...");
                                        this.setState({ ADOpen: false });
                                    }
                                }
                            ],
                            ADTitle: "Benutzer Löschen",
                            ADMessage: `Wollen Sie den Benutzer (${this.state.tableData[index].id} - ${this.state.tableData[index].name}) wirklich Löschen?`,
                            ADOpen: true,
                        })
                        // this.openDrawer(index, this.state.tableData[index]); 
                        // this.setAlertDisplayVisible();
                    }}
                    // onSelectRow={(data) => { console.log("SELECTED USER: ",data); this.openDrawer(data); this.setAlertDisplayVisible(); }}
                    addBtnOnClick={() => { this.openDrawerForNewUser() }}
                    addBtnLabel="Benutzer Hinzufügen"
                />
                {/* <GenericDrawer mode={GD_MODE.UPDATE} config={this.state.drawerConfigs} isOpen={this.state.drawerOpen} closeDrawer={() => { this.setState({ drawerOpen: false }) }} openDrawer={() => { this.setState({ drawerOpen: true }); }} />
                <AlertDisplay2 severity={this.state.alertSeverity} label={this.state.alertDisplayMsg} isVisible={this.state.alertDisplayVisible} hide={() => { this.setState({ alertDisplayVisible: false }) }} />
                </</>> */}
                <AlertDialog options={this.state.ADOptions} open={this.state.ADOpen} title={this.state.ADTitle} message={this.state.ADMessage} />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        tableData: state.userManagement.userTableData,
        role: state.permission.role
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setTableData: (data) => {
            dispatch(setUserTableData(data));
        },
        setRole: (role) => {
            dispatch(setRole(role));
        },
    }
}
class defaultWrapper extends React.Component {
    render() {
        return (
            <DefaultFrame title="Benutzerverwaltung" noPermissionCheck>
                <UserManagement {...this.props} />
            </DefaultFrame>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(defaultWrapper);
