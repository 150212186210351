import { Button, Typography } from '@mui/material'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { SUPPORTCHAT_INFOTAB, addCaseToActiveCaseListAndSetIndex, setChatDialogOpen, setCurrentChatDialogTab } from '../../../redux/supportSlice'

export class ChatSupportCaseButton extends Component {
    render() {
        return (
            <Button onClick={() => {this.props.openChatForCase(this.props.data)}} disabled={this.props.disabled} style={{display: 'flex',backgroundColor: "#E5E5E5", borderRadius:5, width:"100%", padding: "2.5%", marginTop:10}}>
                <Typography style={{flex:1, textAlign:"left"}}>Fall {this.props.data.id}</Typography>
                <Typography style={{flex:1, textAlign:"right"}}>{new Date(this.props.data.createdAt).toLocaleDateString("de", { year: "numeric", month: "2-digit", day: "2-digit" })} -  {new Date(this.props.data.createdAt).toLocaleTimeString("de", { hour: "2-digit", minute: "2-digit", hour12: false })}</Typography>
            </Button>
        )
    }
}
function mapDispatchToProps(dispatch){
    return {
        openChatForCase: (data) => {

            // dispatch(setChatDialogOpen(true));
            dispatch(addCaseToActiveCaseListAndSetIndex({...data, unreadItems: 0, msgList: [], loadOffset: 0, userInfo: {}, orderList: [], orderOffset: 0, supportCases: [], joined: false }));
            dispatch(setCurrentChatDialogTab(SUPPORTCHAT_INFOTAB.USER))
            // dispatch(setRefreshSignal(true));
            // dispatch(setChatDialogOpen(true));
        }
    }
}

export default connect(null, mapDispatchToProps)(ChatSupportCaseButton)
