import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { Component } from 'react'
import { addStatusUpdateToOrder } from '../../../../../redux/supportSlice'
import { connect } from 'react-redux'

export class OrderStatusSelector extends Component {
    render() {
        return (
            <FormControl style={{marginTop:15,}} fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.props.value}
                    label="Status"
                    onChange={(e) => { this.props.addStatusUpdateToOrder(this.props.index, e.target.value)  }}
                >
                    <MenuItem value={1}>
                        <div style={{ display: "flex", height:20, alignItems: "center" }}>
                            <img width={20} height={20} src={require("../../../../../assets/icons/timer.png")} />
                            <p style={{marginLeft:15, fontSize:12}}>Ausstehend</p>
                        </div>
                    </MenuItem>
                    <MenuItem value={2}>
                        <div style={{ display: "flex", height:20, alignItems: "center" }}>
                            <img width={20} height={20} src={require("../../../../../assets/icons/cooking.png")} />
                            <p style={{marginLeft:15, fontSize:12}}>Wird Zubereitet</p>
                        </div>
                    </MenuItem>
                    <MenuItem value={3}>
                        <div style={{ display: "flex", height:20, alignItems: "center" }}>
                            <img width={20} height={20} src={require(this.props.deliveryOption === "0" ? "../../../../../assets/icons/deliveryscooter.png" : "../../../../../assets/icons/take-away.png")} />
                            <p style={{marginLeft:15, fontSize:12}}>{this.props.deliveryOption === "0" ? "Unterwegs" : "Abholbereit"}</p>
                        </div>
                    </MenuItem>
                    <MenuItem value={4}>
                        <div style={{ display: "flex", height:20, alignItems: "center" }}>
                            <img width={20} height={20} src={require("../../../../../assets/icons/warning.png")} />
                            <p style={{marginLeft:15, fontSize:12}}>Lieferproblem</p>
                        </div>
                    </MenuItem>
                    <MenuItem value={5}>
                        <div style={{ display: "flex", height:20, alignItems: "center" }}>
                            <img width={20} height={20} src={require("../../../../../assets/icons/checked_filled.png")} />
                            <p style={{marginLeft:15, fontSize:12}}>Geliefert</p>
                        </div>
                    </MenuItem>
                    <MenuItem value={6}>
                        <div style={{ display: "flex", height:20, alignItems: "center" }}>
                            <img width={20} height={20} src={require("../../../../../assets/icons/error_filled.png")} />
                            <p style={{marginLeft:15, fontSize:12}}>Storniert</p>
                        </div>
                    </MenuItem>
                </Select>
            </FormControl>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addStatusUpdateToOrder: (index, status) => {
            dispatch(addStatusUpdateToOrder({index: index, status: status}));
        }
    }
}

export default connect(null, mapDispatchToProps)(OrderStatusSelector)
