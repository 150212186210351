import { Edit } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import React, { Component } from 'react'

export class AddressDisplay extends Component {
    render() {
        return (
            <div style={{ padding: 10, display: "flex", marginTop: this.props.marginTop != null ? this.props.marginTop : 15, borderRadius: 5, borderWidth: 0.5, borderColor: "#979797", borderStyle: "dotted" }} key={"AddressDisplay_" + this.props.addressData.id}>
                <div style={{ flex: 6 }}>
                    <Typography fontSize={10}>ID: {this.props.addressData.id}</Typography>
                    <Typography fontSize={10}>Name: {this.props.addressData.fullname} {this.props.addressData.company != null ? `(${this.props.addressData.company})` : ""}</Typography>
                    <Typography fontSize={10}>Adresse: {this.props.addressData.street} {this.props.addressData.houseNumber}, {this.props.addressData.zip} {this.props.addressData.city}</Typography>
                    {this.props.addressData.phone != null &&
                        <Typography fontSize={10}>Telefon: {this.props.addressData.phone}</Typography>
                    }
                    {this.props.addressData.sameInvoiceAddress === 0 &&
                        <>
                            <Typography fontSize={10} style={{ fontWeight: "bold" }}>Rechnungsaddresse:</Typography>
                            <Typography fontSize={10}>Name: {this.props.addressData.InvFullname} {this.props.addressData.InvCompany != null ? `(${this.props.addressData.InvCompany})` : ""}</Typography>
                            <Typography fontSize={10}>Adresse: {this.props.addressData.InvStreet} {this.props.addressData.InvNumber}, {this.props.addressData.InvZip} {this.props.addressData.InvCity}</Typography>
                            {this.props.addressData.InvPhone != null &&
                                <Typography fontSize={10}>Telefon: {this.props.addressData.InvPhone}</Typography>
                            }
                        </>
                    }
                </div>
                {this.props.editActive == true &&
                    <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button variant='contained' style={{ padding: 5, display: "flex", justifyContent: "center", alignItems: "center", width: 30, height: 30, minWidth: 0, borderRadius: "50%" }}>
                            <Edit style={{width:20, height:20}}/>
                        </Button>
                    </div>
                }
            </div>
        )
    }
}

export default AddressDisplay
