import { createSlice } from "@reduxjs/toolkit";
import { SUPPORT_TAB } from "../areas/support/SupportOverview";
export const SENDERTYPE = {
    SUPPORT: "1",
    USER: "2",
}
export const SUPPORTCHAT_INFOTAB = { USER: 0, ORDERS: 1, SUPPORTOVERVIEW: 2 }
export const supportSlice = createSlice({
    name: 'support',
    initialState: {
        // a list of support caseses, which the support uses currently and shown in the bottom bar.
        // the inner object contains the attributes {id, msgList: [], userData:{}, orderList:[], addressList: [] }
        allCaseList: [],
        openCaseList: [],
        closedCaseList: [],
        allCaseOffset: 0,
        openCaseOffset: 0,
        closedCaseOffset: 0,
        currentChatDialogTab: SUPPORTCHAT_INFOTAB.USER,
        supportChatTabAreaLoading: false,

        activeCaseList: [], // this has nothing to do with the status. active means that it's opened actively at the bottom bar of current chats.
        // TESTDATA
        // activeCaseList: [
        //     {
        //         id: "18446744",
        //         unreadItems: 5,
        //         msgList: [
        //             {createdAt : "2023-10-15T11:59:43.704Z" , content: "Hallo, wie können wir dir Helfen", senderType: "1"},
        //             {createdAt : "2023-10-15T11:59:43.704Z" , content: "Ich warte seit 2 Stunden auf mein Essen!?", senderType: "2"},
        //             {createdAt : "2023-10-15T11:59:43.704Z" , content: "Geht es dabei um deine Bestellung #37 bei Viveel Pizza?", senderType: "1"},
        //             {createdAt : "2023-10-15T11:59:43.704Z" , content: "Ja siehst du etwa andere Bestellung oder was?", senderType: "2"},
        //             {createdAt : "2023-10-15T11:59:43.704Z" , content: "Das tut mir leid. Bei mir in der Liste sind auch die vergangenen Bestellungen. In manchen Fällen werden die im System als abgeschlossen angezeigt, wenn der Partnershop diese als geliefert markiert. Daher wollte ich mir nur sicher gehen. Ich schaue sofort für dich nach. Einen Moment bitte.", senderType: "1"},
        //             {createdAt : "2023-10-15T11:59:43.704Z" , content: "Das tut mir leid. Bei mir in der Liste sind auch die vergangenen Bestellungen. In manchen Fällen werden die im System als abgeschlossen angezeigt, wenn der Partnershop diese als geliefert markiert. Daher wollte ich mir nur sicher gehen. Ich schaue sofort für dich nach. Einen Moment bitte.", senderType: "1"},
        //             {createdAt : "2023-10-15T11:59:43.704Z" , content: "Das tut mir leid. Bei mir in der Liste sind auch die vergangenen Bestellungen. In manchen Fällen werden die im System als abgeschlossen angezeigt, wenn der Partnershop diese als geliefert markiert. Daher wollte ich mir nur sicher gehen. Ich schaue sofort für dich nach. Einen Moment bitte.", senderType: "1"},
        //             {createdAt : "2023-10-15T11:59:43.704Z" , content: "Das tut mir leid. Bei mir in der Liste sind auch die vergangenen Bestellungen. In manchen Fällen werden die im System als abgeschlossen angezeigt, wenn der Partnershop diese als geliefert markiert. Daher wollte ich mir nur sicher gehen. Ich schaue sofort für dich nach. Einen Moment bitte.", senderType: "1"},
        //             {createdAt : "2023-10-15T11:59:43.704Z" , content: "Das tut mir leid. Bei mir in der Liste sind auch die vergangenen Bestellungen. In manchen Fällen werden die im System als abgeschlossen angezeigt, wenn der Partnershop diese als geliefert markiert. Daher wollte ich mir nur sicher gehen. Ich schaue sofort für dich nach. Einen Moment bitte.", senderType: "1"},
        //             {createdAt : "2023-10-15T11:59:43.704Z" , content: "Das tut mir leid. Bei mir in der Liste sind auch die vergangenen Bestellungen. In manchen Fällen werden die im System als abgeschlossen angezeigt, wenn der Partnershop diese als geliefert markiert. Daher wollte ich mir nur sicher gehen. Ich schaue sofort für dich nach. Einen Moment bitte.", senderType: "1"},
        //             {createdAt : "2023-10-15T11:59:43.704Z" , content: "Das tut mir leid. Bei mir in der Liste sind auch die vergangenen Bestellungen. In manchen Fällen werden die im System als abgeschlossen angezeigt, wenn der Partnershop diese als geliefert markiert. Daher wollte ich mir nur sicher gehen. Ich schaue sofort für dich nach. Einen Moment bitte.", senderType: "1"},
        //             {createdAt : "2023-10-15T11:59:43.704Z" , content: "Das tut mir leid. Bei mir in der Liste sind auch die vergangenen Bestellungen. In manchen Fällen werden die im System als abgeschlossen angezeigt, wenn der Partnershop diese als geliefert markiert. Daher wollte ich mir nur sicher gehen. Ich schaue sofort für dich nach. Einen Moment bitte.", senderType: "1"},
        //             {createdAt : "2023-10-15T11:59:43.704Z" , content: "Das tut mir leid. Bei mir in der Liste sind auch die vergangenen Bestellungen. In manchen Fällen werden die im System als abgeschlossen angezeigt, wenn der Partnershop diese als geliefert markiert. Daher wollte ich mir nur sicher gehen. Ich schaue sofort für dich nach. Einen Moment bitte.", senderType: "1"},
        //         ],
        //     },
        //     {
        //         id:"15",
        //         unreadItems:0,
        //         msgList: [],
        //     },
        //     {
        //         id:"32",
        //         unreadItems:0,
        //         msgList: [],
        //     }
        // ],
        playNotificationSoundToggle: false, // every time this value is toggled. Sound is played for notification
        chatDialogOpen: false,
        chatCaseIndex: -1,
    },
    reducers: {
        changeSupportStatus: (state, arg) => {
            let myCaseList = [...state.activeCaseList];
            myCaseList[state.chatCaseIndex].status = arg.payload;
            console.log("### BEFORE changeSupportStatus: ",state.activeCaseList);
            state.activeCaseList = [...myCaseList];
        },
        setSupportChatTabAreaLoading: (state, arg) => {
            state.supportChatTabAreaLoading = arg.payload;
        },
        setUserInfoForActiveCase: (state, arg) => {
            let myCaseList = [...state.activeCaseList];
            myCaseList[arg.payload.index].userInfo = {...arg.payload.data};
            console.log("### BEFORE setUserInfoForActiveCase: ",state.activeCaseList);
            state.activeCaseList = [...myCaseList];
        },
        setRoomJoinedForCaseIndex: (state, arg) => {
            state.activeCaseList[arg.payload].joined = true;
        },
        setCurrentChatDialogTab: (state, arg) => {
            state.currentChatDialogTab = arg.payload;
        },
        setCaseOffset: (state, arg) => {
            switch(arg.payload.tab) {
                case SUPPORT_TAB.ALL:
                    state.allCaseOffset=arg.payload.value
                    break;
                case SUPPORT_TAB.OPEN:
                    state.openCaseOffset= arg.payload.value
                    break;
                case SUPPORT_TAB.CLOSED:
                    state.closedCaseOffset=arg.payload.value
                    break;
            }
        },
        setCaseList: (state, arg) => {
            if(arg.payload.tab != null){
                switch(arg.payload.tab) {
                    case SUPPORT_TAB.ALL:
                        state.allCaseList = [...arg.payload.list];
                        break;
                    case SUPPORT_TAB.OPEN:
                        state.openCaseList = [...arg.payload.list];
                        break;
                    case SUPPORT_TAB.CLOSED:
                        state.closedCaseList = [...arg.payload.list];
                        break;
                }
            }
        },
        addCasesToCaseList: (state, arg) => {
            if(arg.payload.tab != null){
                switch(arg.payload.tab) {
                    case SUPPORT_TAB.ALL:
                        state.allCaseList = [...state.allCaseList, ...arg.payload.list];
                        break;
                    case SUPPORT_TAB.OPEN:
                        state.openCaseList = [...state.openCaseList, ...arg.payload.list];
                        break;
                    case SUPPORT_TAB.CLOSED:
                        state.closedCaseList = [...state.closedCaseList, ...arg.payload.list];
                        break;
                }
            }
        },
        // setting chatDialogOpen attribute to toggle the chat dialog.
        setChatDialogOpen: (state, arg) => { state.chatDialogOpen = arg.payload },
        // setting the current chats case number
        setCurrentChatCaseIndex: (state, arg) => { state.chatCaseIndex = arg.payload },
        addMessageListToCaseList: (state, arg) => {
            console.log("State activeCaseList: ",state.activeCaseList);
            let index = state.activeCaseList.findIndex(myCase => String(myCase.id) === String(arg.payload.scid));
            console.log("Adding message list to case: ",arg.payload);
            if(index != -1){
                let myCaseList = [...state.activeCaseList];
                myCaseList[index].msgList = [...arg.payload.msgList, ...myCaseList[index].msgList ]

                console.log("### BEFORE addMessageListToCaseList: ",state.activeCaseList);
                state.activeCaseList = [...myCaseList];
            }
            console.log("State activeCaseList (AFTER): ",state.activeCaseList);
        },
        // pagination load is disabled by setting the page to -1. This is done if the server sends an empty result, which indicates the end of the results.
        disableLoadOffset: (state, arg) => {
            let index = state.activeCaseList.findIndex(myCase => myCase.id === arg.payload);
            if(index != -1){
                let myCaseList = [...state.activeCaseList];
                myCaseList[index].loadOffset = -1;
                state.activeCaseList=[...myCaseList];
            }
        },
        increaseLoadOffset: (state, arg) => {
            let index = state.activeCaseList.findIndex(myCase => myCase.id === arg.payload);
            if(index != -1){
                let myCaseList = [...state.activeCaseList];
                myCaseList[index].loadOffset += 1;
                state.activeCaseList=[...myCaseList];
            }
        },
        // adds incoming message to the activeCaseList at the specified index.
        addMessageToCaseList: (state, action) => {
            const { scid, ...payload } = action.payload;
            const index = state.activeCaseList.findIndex(myCase => String(myCase.id) === String(scid));
            if (index !== -1) {
                // Copy the activeCaseList and the specific case
                const updatedCaseList = [...state.activeCaseList];
                const updatedCase = { ...updatedCaseList[index] };
        
                // Update unreadItems and msgList for the specific case
                updatedCase.unreadItems = updatedCase.unreadItems + (state.chatCaseIndex !== index ? 1 : 0);
                updatedCase.msgList = [...updatedCase.msgList, payload];
        
                // Replace the updated case in the list
                updatedCaseList[index] = updatedCase;
        
                // Update the state
                state.activeCaseList = updatedCaseList;
                state.playNotificationSoundToggle = state.chatCaseIndex !== index ? !state.playNotificationSoundToggle : state.playNotificationSoundToggle;
            } else {
                console.log("CASE FOR MSG NOT FOUND!!!!");
            }
        },
        addUserInfoToCaseList: (state, arg) => {
            let myCaseList = [...state.activeCaseList];
            myCaseList[arg.payload.index] = {...myCaseList[arg.payload.index], userInfo:{...arg.payload.data}};
            console.log("ADDING: ",myCaseList[arg.payload.index]);
            state.activeCaseList= [...myCaseList];
        },
        resetUnreadItemsCountForCaseAtIndex: (state, arg) => {
            if (arg.payload < state.activeCaseList.length) {
                let myCaseList = [...state.activeCaseList]
                myCaseList[arg.payload].unreadItems = 0;
                console.log("### BEFORE resetUnreadItemsCountForCaseAtIndex: ",state.activeCaseList);
                state.activeCaseList = [...myCaseList];
            }
        },
        // adding casenumber for a support case to display them at the bottom bar.
        addCaseToActiveCaseListAndSetIndex: (state, arg) => {
            console.log("addCaseToActiveCaseListAndSetIndex: ",arg.payload);
            let index = state.activeCaseList.findIndex(caseItem => caseItem.id === arg.payload.id);
            if(index == -1){ // if case not already exists.

                console.log("### BEFORE addCaseToActiveCaseListAndSetIndex: ",state.activeCaseList);
                state.activeCaseList = [...state.activeCaseList, arg.payload]
                state.chatCaseIndex = state.activeCaseList.length - 1;
            }else{
                state.chatCaseIndex = index;
            }
            console.log("ActiveCaseList: ",state.activeCaseList);
        },
        // adds specified elements in arg.payload.data to the orderList in activeCaseList at specified index (arg.payload.index);
        addToOrderListAndIncreaseOffset: (state, arg) => {
            let myCaseList = [...state.activeCaseList];
            if(arg.payload.data?.length > 0){
                myCaseList[arg.payload.index].orderList = [...myCaseList[arg.payload.index].orderList, ...arg.payload.data];
                myCaseList[arg.payload.index].orderOffset += 1;
            } else {
                myCaseList[arg.payload.index].orderOffset = -1;
            }
            console.log("### BEFORE addToOrderListAndIncreaseOffset: ",state.activeCaseList);
            state.activeCaseList = [...myCaseList];
        },
        setOrderOffset: (state, arg) => {
            let myCaseList = [...state.activeCaseList];
            myCaseList[arg.payload.index].orderOffset = arg.payload.value;

            console.log("### BEFORE setOrderOffset: ",state.activeCaseList);
            state.activeCaseList = [...myCaseList];
        },
        setOrderPositionOffset: () => {

        },
        // adds specified elements in arg.payload.data to the orderPositions in orderList at index arg.payload.oIndex in activeCaseList at specified index (arg.payload.index);
        addOrderPositionToOrderListAndIncreaseOffset: (state, arg) => {
            let myCaseList = [...state.activeCaseList];
            if(arg.payload.data?.length > 0){
                myCaseList[arg.payload.index].orderList[arg.payload.oIndex].orderPositions = [...myCaseList[arg.payload.index].orderList[arg.payload.oIndex].orderPositions, ...arg.payload.data];
                myCaseList[arg.payload.index].orderList[arg.payload.oIndex].oPosOffset += 1;
            } else {
                myCaseList[arg.payload.index].orderList[arg.payload.oIndex].oPosOffset = -1;
            }

            console.log("### BEFORE setOrderOffset: ",state.activeCaseList);
            state.activeCaseList = [...myCaseList];
        },
        addStatusUpdateToOrder: (state, arg) => {
            let myCaseList = [...state.activeCaseList];

            console.log("### BEFORE addstatusupdatetoorder: ",state.activeCaseList);
            // console.log("ChatCaseIndex: ",state.chatCaseIndex);
            // console.log("CASELISTATINDEX: ",myCaseList[0]);
            // console.log("CASELISTATINDEX: ",myCaseList[state.chatCaseIndex].orderList[arg.payload.index]);
            if(myCaseList[state.chatCaseIndex].orderList[arg.payload.index].update == null){
                myCaseList[state.chatCaseIndex].orderList[arg.payload.index].update = {
                    status: arg.payload.status
                }
            }else{
                myCaseList[state.chatCaseIndex].orderList[arg.payload.index].update.status = arg.payload.status;
            }
            state.activeCaseList = [...myCaseList];
        },
        addUpdateToOrderPosition: (state, arg) => {
            let myCaseList = [...state.activeCaseList];

            console.log("### BEFORE addupdatetoorderposition: ",state.activeCaseList);
            
            if(myCaseList[arg.payload.index].orderList[arg.payload.oIndex].orderPositions[arg.payload.oPosIndex].update == null){
                myCaseList[arg.payload.index].orderList[arg.payload.oIndex].orderPositions[arg.payload.oPosIndex].update = {};
            }
            myCaseList[arg.payload.index].orderList[arg.payload.oIndex].orderPositions[arg.payload.oPosIndex].update[arg.payload.key] = myCaseList[arg.payload.index].orderList[arg.payload.oIndex].orderPositions[arg.payload.oPosIndex].update[arg.payload.key] != null ? myCaseList[arg.payload.index].orderList[arg.payload.oIndex].orderPositions[arg.payload.oPosIndex].update[arg.payload.key]+arg.payload.value : arg.payload.value;
            state.activeCaseList = [...myCaseList];
        },
        // removing case from the bottom bar.
        removeActiveCaseByIndex: (state, arg) => {
            if (parseInt(arg.payload) >= 0 && parseInt(arg.payload) < state.activeCaseList.length) {
                if (parseInt(arg.payload) === state.chatCaseIndex) {
                    state.chatCaseIndex = -1; // setting it to -1 (undefined) if the supportchat is opened for that very case
                }
                state.activeCaseList.splice(arg.payload, 1);
            }
        }
    }
})

export const { addCaseToActiveCaseListAndSetIndex, setSupportChatTabAreaLoading, changeSupportStatus, setUserInfoForActiveCase, addUpdateToOrderPosition, addStatusUpdateToOrder, setRoomJoinedForCaseIndex, setCurrentChatDialogTab, addToOrderListAndIncreaseOffset, addOrderPositionToOrderListAndIncreaseOffset, setOrderOffset, addUserInfoToCaseList, setCaseOffset, removeActiveCaseByIndex, setCaseList,addCasesToCaseList, setChatDialogOpen, setCurrentChatCaseIndex, resetUnreadItemsCountForCaseAtIndex, disableLoadOffset, increaseLoadOffset, addMessageToCaseList, addMessageListToCaseList} = supportSlice.actions

export default supportSlice.reducer