import React, { Component } from 'react'
import DefaultFrame from '../../components/DefaultFrame'
import NutritionDataTile from './NutritionDataTile'
import AllergensTile from './AllergensTile'
import PromotionsTile from './PromotionsTile'

export class DataManagement extends Component {
    render() {
        return (
            <div style={{display:"flex", flexDirection:"column", gap:15}}>
                <NutritionDataTile {...this.props} />
                <AllergensTile {...this.props} />
                <PromotionsTile {...this.props} />
            </div>
        )
    }
}

class defaultWrapper extends Component {
    render() {
        return (
            <DefaultFrame title="Plattformverwaltung" >
                <DataManagement />
            </DefaultFrame>
        )
    }
}
export default defaultWrapper