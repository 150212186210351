import { ArrowBack, Close, Save } from '@mui/icons-material'
import { Button, Switch, TextField, TextareaAutosize, Typography } from '@mui/material'
import React, { Component } from 'react'
import { APIData } from '../../../../constants/APIAdresses';
import Textarea from '@mui/joy/Textarea'
import NutritionTable from './NutritionTable';
import { connect } from 'react-redux';
import { NUTRITIONS, setNutritiondata, setProductVariants, setSelectedAllergens, setSelectedCategories, setSelectedCollections, setSelectedGroupCollections } from '../../../../redux/productSlice';
import './ProductEditor.css'
import AllergensSelector from './AllergensSelector';
import CategorySelector from './CategorySelector';
import GCSelector from './GCSelector';
import CollectionSelector from './CollectionSelector';
import ProductVariantArea from './ProductVariantArea';
import ImageField from '../../../../components/Elements/ImageField';
import { ALERT_SEVERITY } from '../../../../components/AlertDisplay2';

export const PE_CHANGE_TYPES = {
    DELETE: "DELETE",
    NEW: "NEW",
    UPDATE: "UPDATE"
}
export class ProductEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productData: this.props.product == null ? {update:{active: 1 }} : { },
            isLoading: false,
            isNewProduct: this.props.product == null,
            allergenData: []
        }
        console.log()
        this.imgBlobURL = null;
        this.bodyValid = true;
        this.bodyErrorMsg = "";
        // this.shopId = this.props.shopId;
    }
    prepareNutritionData(productNutritionData) {
        let nutritionData = [...this.props.nutritionData];
        nutritionData = nutritionData.map((nutr, index) => {
            let pn = productNutritionData.find((pnd) => pnd.nkid === nutr.id);
            if (pn != null) {
                return { ...nutr, defaultLabel: NUTRITIONS[nutr.uniqueKey], isActive: true, value: pn.value };
            }
            return { ...nutr, defaultLabel: NUTRITIONS[nutr.uniqueKey] };
        });
        console.log("NUTRITIONDATA: ", nutritionData);
        this.props.setNutritiondata([...nutritionData]);
    }
    prepareAllergenData(productAllergenData) {
        let allergenData = [...productAllergenData];
        let selectedAllergens = allergenData.map((allergen, index) => {
            let foundItem = this.props.allergens.find((allergenDataItem) => allergenDataItem.id === allergen.aid);
            // console.log("MAP ALLERGENS: ", foundItem, " DBALLERGEN: ",);
            if (foundItem != null) {
                return { ...foundItem, dbId: allergen.id };
            }
        })
        this.props.setSelectedAllergens([...selectedAllergens]);
    }
    loadData() {
        this.setState({ isLoading: true });
        fetch(APIData.SHOP + `/product/${this.props.product.id}/loadPEData`, { credentials: 'include', headers: { "Content-Type": "application/json" } })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            }).then((result) => {
                this.setState({ isLoading: true });

                if (result != null) {
                    result.categoryData = result.categoryData ? JSON.parse(result.categoryData) : [];
                    this.props.setSelectedCategories(result.categoryData);
                    result.collData = result.collData ? JSON.parse(result.collData) : [];
                    this.props.setSelectedCollections(result.collData);
                    result.pgData = result.pgData ? JSON.parse(result.pgData) : [];
                    this.props.setSelectedGroupCollections(result.pgData);
                    result.nutritionData = result.nutritionData ? JSON.parse(result.nutritionData) : [];
                    this.prepareNutritionData(result.nutritionData);
                    result.allergens = result.allergens ? JSON.parse(result.allergens) : [];
                    this.prepareAllergenData(result.allergens);
                    result.pvData = result.pvData.map((pvItem) => {
                        return {
                            ...pvItem,
                            pgData: pvItem.pgData ? JSON.parse(pvItem.pgData) : [],
                            collData: pvItem.collData ? JSON.parse(pvItem.collData) : [],
                            allergens: pvItem.allergens ? JSON.parse(pvItem.allergens) : []
                        }
                    });
                    console.log("PVRESULT: ", result);
                    this.props.setProductVariants(result.pvData);
                    Object.keys(result).forEach(key => {
                        if (result[key] == null) {
                            result[key] = "";
                        }
                    });
                    // console.log("-----SETTING PESESULT: ",result);
                    this.setState({
                        productData: result
                    }, () => {
                        console.log("-----state.productData.active: ", this.state.productData.active);
                    });
                }
            }).catch((error) => {
                console.log("ERROR: ", error);
                this.setState({ isLoading: true });
            })

    }
    componentDidMount() {
        console.log("PRODUCTEDITORMOUNTED: ", this.props.product);
        if (!this.state.isNewProduct) {
            console.log("LOADINGDATA");
            this.loadData();
        }else {
            console.log("PREPARENUTRITIONDATA: ",this.props.nutritionData);
            this.prepareNutritionData([]);
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.product != this.props.product) {
            this.setState({ isNewProduct: this.props.product == null });
            if (this.props.product != null) {
                this.loadData();
            }
        }
    }
    uploadImage(file) {
        return new Promise((resolve, reject) => {
            if (file == null) {
                resolve(null)
            } else {
                let formData = new FormData();
                formData.append("image", file);
                fetch(APIData.MEDIA + "/upload?type=" + APIData.MEDIA_TYPES.PRODUCTS, { body: formData, method: "POST", credentials: "include" })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            reject("Error while uploading image");
                        }
                    }).then(result => {
                        console.log("UPLOADRESULT: ", result);
                        if (result?.path) {
                            resolve(result.path);
                        } else {
                            reject("Error while uploading image");
                        }
                    }).catch(e => {
                        reject("Error while uploading image: " + e);
                    })
            }
        })
    }
    createBody(imgRelPath) {
        let body = { id: this.state.isNewProduct ? "0" : this.state.productData.id };
        if (imgRelPath != null) {
            if (this.state.isNewProduct) {
                body.imgSource = imgRelPath;
            } else {
                body.update = { imgSource: imgRelPath };
            }
        }
        if (this.state.isNewProduct) {
            if (this.state.productData.update?.mainPrice == null || this.state.productData.update?.name == null) {
                this.bodyValid = false;
                this.bodyErrorMsg = "Produkt muss einen Namen und Preis haben.";
            } else {
                body = { ...body, ...this.state.productData.update };
                this.props.nutritionData.filter((nutr) => nutr.edited != null || nutr.editActive != null).forEach((nutr, index) => {
                    if (index === 0) {
                        body.nutritionData = {}
                    }
                    body.nutritionData[nutr.id] = { value: nutr.edited };
                })
                this.props.selectedAllergens.filter((allergen) => allergen.status != null).forEach((allergen, index) => {
                    if (index === 0) {
                        body.allergens = []
                    }
                    body.allergens.push({ aid: allergen.id });
                })
                if (this.props.selectedCategories.filter((cat) => cat.insert != null).length > 0) {
                    body.categoryData = this.props.selectedCategories.filter((cat) => cat.insert != null).map((cat) => {
                        let catData = { ...cat };
                        delete catData.scName;
                        delete catData.name;
                        if (catData.scKey == null) {
                            delete catData.scKey;
                        }
                        return catData;
                    });
                }
                if (this.props.selectedCollections.some((coll) => coll.status != null)) {
                    body.collData = this.props.selectedCollections.map((coll) => {
                        return { id: coll.id }
                    })
                }
                if (this.props.selectedGroupCollections.some((gc) => gc.status != null)) {
                    body.pgData = this.props.selectedGroupCollections.filter(gc => gc.status != null).map((gc) => {
                        return { gc_id: gc.gc_id, insert: true }
                    })
                }
                let bodyPVData = [];
                this.props.productVariants.forEach((pv) => {
                    let pvData = { ...pv };
                    if (pvData.name?.length > 0 && pvData.price?.length > 0 && parseFloat(pvData.price) >= 0) {
                        let newBody = { name: pvData.name, price: pvData.price, insert: true };
                        newBody.pgData = pvData.pgData.filter((pg) => pg.status != null).map((pg, index) => {
                            return { gc_id: pg.gc_id, insert: true }
                        })
                        newBody.collData = pvData.collData.filter((coll) => coll.status != null).map((coll, index) => {
                            return { id: coll.id }
                        })
                        newBody.allergens = pvData.allergens.filter((allergen) => allergen.status != null).map((allergen) => {
                            return { aid: allergen.aid }
                        })
                        if (newBody.pgData.length === 0) {
                            delete newBody.pgData;
                        }
                        if (newBody.collData.length === 0) {
                            delete newBody.collData;
                        }
                        if (newBody.allergens.length === 0) {
                            delete newBody.allergens;
                        }
                        bodyPVData.push(newBody);
                    }
                })
                if (bodyPVData.length > 0) {
                    body.pvData = bodyPVData;
                }
            }
            console.log("STATEPRODUCTDATA NEW PRODUCT: ", this.state.productData);
        } else {
            if (this.state.productData.update != null) {
                if (
                    (this.state.productData.update.mainPrice == null || (this.state.productData.update.mainPrice.toString().length > 0 && parseFloat(this.state.productData.update.mainPrice) >= 0))
                    && (this.state.productData.update.name == null || this.state.productData.update.name.length > 0)
                ) {
                    body.update = { ...body.update, ...this.state.productData.update };
                } else {
                    this.bodyValid = false;
                    this.bodyErrorMsg = "Produktname und -preis dürfen nicht leer sein.";
                }
            }
            this.props.nutritionData.filter((nutr) => nutr.edited != null || nutr.editActive != null).forEach((nutr, index) => {
                if (index === 0) {
                    body.nutritionData = {}
                }
                if (nutr.isActive && !nutr.editActive) {
                    body.nutritionData[nutr.id] = { delete: true };
                } else if (!nutr.isActive && nutr.editActive) {
                    body.nutritionData[nutr.id] = { value: nutr.edited };
                } else if (nutr.edited != null) {
                    body.nutritionData[nutr.id] = { value: nutr.edited };
                }
            })
            this.props.selectedAllergens.filter((allergen) => allergen.status != null).forEach((allergen, index) => {
                if (index === 0) {
                    body.allergens = []
                }
                if (allergen.status === PE_CHANGE_TYPES.NEW) {
                    body.allergens.push({ aid: allergen.id }); // Sending the id of allergen means insert / new for the server
                } else if (allergen.status === PE_CHANGE_TYPES.DELETE) {
                    body.allergens.push({ id: allergen.dbId }); // Sending the productAllergen.id means delete for the server
                }
            })
            if (this.props.selectedCategories.filter((cat) => cat.delete != null || cat.insert != null).length > 0) {
                body.categoryData = this.props.selectedCategories.filter((cat) => cat.delete != null || cat.insert != null).map((cat) => {
                    let catData = { ...cat };
                    delete catData.scName;
                    delete catData.name;
                    if (catData.scKey == null) {
                        delete catData.scKey;
                    }
                    return catData;
                });
            }
            // COLLECTIONS
            if (this.props.selectedCollections.some((coll) => coll.status != null)) {
                body.collData = this.props.selectedCollections.map((coll) => {
                    if (coll.pCollId != null) {
                        return { pCollId: coll.pCollId } // this is a delete, since productionCollection id is being sent
                    } else {
                        return { id: coll.id } // this is an insert, since collection id is being sent
                    }
                })
            }
            // GROUPCOLLECTIONS
            if (this.props.selectedGroupCollections.some((gc) => gc.status != null)) {
                body.pgData = this.props.selectedGroupCollections.filter(gc => gc.status != null).map((gc) => {
                    return { gc_id: gc.gc_id, [gc.status === PE_CHANGE_TYPES.NEW ? 'insert' : 'delete']: true }
                })
            }
            // if(this.props.productVariants.some((pv) => pv.status != null)){
            // body.pvData = []
            let bodyPVData = [];
            this.props.productVariants.forEach((pv) => {
                let pvData = { ...pv };
                if (pvData.status === PE_CHANGE_TYPES.DELETE) {
                    bodyPVData.push({ id: pvData.id, delete: true }); // nothing else to check anymore, since we deleting..
                } else if (pvData.status === PE_CHANGE_TYPES.NEW) {
                    let newBody = {};
                    if (pvData.name?.length > 0 && pvData.price?.length > 0 && parseFloat(pvData.price) >= 0) {
                        newBody = { name: pvData.name, price: pvData.price, insert: true };
                    } else {
                        this.bodyValid = false;
                        this.bodyErrorMsg = "Produktvarianten müssen einen Namen und gültigen Preis haben.";
                    }
                    newBody.pgData = pvData.pgData.filter((pg) => pg.status != null).map((pg, index) => {
                        return { gc_id: pg.gc_id, insert: true }
                    })
                    newBody.collData = pvData.collData.filter((coll) => coll.status != null).map((coll, index) => {
                        return { id: coll.id } // here we have only insert, since we are creating a new product variant
                        // return {[coll.pCollId != null ? 'pCollId' : 'id']: coll.pCollId != null ? coll.pCollId : coll.id, insert: true}
                    })
                    newBody.allergens = pvData.allergens.filter((allergen) => allergen.status != null).map((allergen) => {
                        return { aid: allergen.aid } // here we have only insert, since we are creating a new product variant
                    })
                    if (newBody.pgData.length === 0) {
                        delete newBody.pgData;
                    }
                    if (newBody.collData.length === 0) {
                        delete newBody.collData;
                    }
                    if (newBody.allergens.length === 0) {
                        delete newBody.allergens;
                    }
                    bodyPVData.push(newBody);
                } else if (pvData.status === PE_CHANGE_TYPES.UPDATE) {
                    // This is an update then...
                    let updateBody = { id: pvData.id };
                    if (pvData.update != null) {
                        updateBody.update = { ...pvData.update };
                        if (pvData.update?.name?.length === 0 || pvData.update?.price?.length === 0 || parseFloat(pvData.update?.price) < 0) {
                            this.bodyValid = false;
                            this.bodyErrorMsg = "Produktvarianten müssen einen Namen und gültigen Preis haben.";
                        }
                    }
                    updateBody.pgData = pvData.pgData.filter((pg) => pg.status != null).map((pg, index) => {
                        return { gc_id: pg.gc_id, [pg.status === PE_CHANGE_TYPES.NEW ? 'insert' : 'delete']: true }
                    })
                    updateBody.collData = pvData.collData.filter((coll) => coll.status != null).map((coll, index) => {
                        return { [coll.pCollId != null ? 'pCollId' : 'id']: coll.pCollId != null ? coll.pCollId : coll.id, insert: true }
                    })
                    updateBody.allergens = pvData.allergens.filter((allergen) => allergen.status != null).map((allergen) => {
                        console.log("ALLERGEN: ", allergen);
                        return { [allergen.status === PE_CHANGE_TYPES.DELETE ? 'id' : 'aid']: (allergen.status === PE_CHANGE_TYPES.DELETE ? allergen.id : allergen.aid) }
                        // return {aid: allergen.aid} // here we have only insert, since we are creating a new product variant
                    })
                    if (updateBody.pgData.length === 0) {
                        delete updateBody.pgData;
                    }
                    if (updateBody.collData.length === 0) {
                        delete updateBody.collData;
                    }
                    if (updateBody.allergens.length === 0) {
                        delete updateBody.allergens;
                    }
                    bodyPVData.push(updateBody);
                }
            })
            if (bodyPVData.length > 0) {
                body.pvData = bodyPVData;
            }
            console.log("BODYDATAPV: ", bodyPVData);
            // }

        }
        console.log("BODYDATA: ", body);
        return body;
    }
    onSave() {
        // this.createBody();
        let startTime = performance.now();
        this.uploadImage(this.imgBlobURL).then((imgRelPath) => {
            let body = this.createBody(imgRelPath);
            if (this.bodyValid) {
                if (Object.keys(body).length > 1) {
                    fetch(APIData.SHOP + `/shop/${this.props.shopId}/product/updateOrCreate`, { credentials: 'include', method: 'POST', body: JSON.stringify(body), headers: { "Content-Type": "application/json" } })
                        .then((response) => {
                            if (response.ok) {
                                console.log("DURATION OF SAVE: ", performance.now() - startTime);
                                this.props.showAlertDisplay("Produkt erfolgreich gespeichert.", ALERT_SEVERITY.SUCCESS);
                                let myPD = { ...this.state.productData };
                                if (imgRelPath != null) {
                                    myPD.update = { ...myPD.update, imgSource: imgRelPath };
                                }
                                this.props.onBack(myPD)
                                this.resetStateProductData();
                            } else {
                                this.props.showAlertDisplay(`Fehler beim Speichern des Produkts (${response.status}). Bitte versuche es erneut.`, ALERT_SEVERITY.ERROR);
                            }
                        }).catch((error) => {
                            console.log("DURATION OF SAVE (FAILED): ", performance.now() - startTime);
                            console.log("Error while saving product: ", error);
                            this.props.showAlertDisplay("Fehler beim Speichern des Produkts. Bitte versuche es erneut.", ALERT_SEVERITY.ERROR);
                        })

                } else {
                    this.props.showAlertDisplay("Keine Änderungen erkannt.", ALERT_SEVERITY.INFO);
                }
            } else {
                this.props.showAlertDisplay(this.bodyErrorMsg, ALERT_SEVERITY.ERROR);
                this.bodyValid = true;
                this.bodyErrorMsg = "";
            }
            // console.log("ONSAVEPRODUCTDATA: ", this.state.productData);
            // console.log("ONSAVENUTRITIONDATA: ", this.props.nutritionData);
            // console.log("ONSAVEALLERGENS: ", this.props.selectedAllergens);
            // console.log("BODY: ",body);
            // console.log("ONSAVESELECTEDCATS: ", this.props.selectedCategories);
            // console.log("ONSAVESELECTEDGROUPCOLLECTIONS: ", this.props.selectedGroupCollections);
            // console.log("ONSAVECOLLECTIONS: ", this.props.selectedCollections);
            // console.log("ONSAVEPRODUCTVARIANTS: ", this.props.productVariants);
            // console.log("ONSAVEIMG: ", this.imgBlobURL);
        }).catch((error) => {
            this.props.showAlertDisplay("Fehler beim Hochladen des Produktbildes", ALERT_SEVERITY.ERROR);
            console.log("ERROR while uploading Product Image: ", error)
        })

    }
    updateField(key, value) {
        if(key === "active"){
            console.log("VALUE: ",value);
        }
        let productData = { ...this.state.productData };
        if (productData[key] === value) {
            delete productData.update[key];
            if (Object.keys(productData.update)?.length === 0) {
                delete productData.update;
            }
        } else {
            if (productData.update == null) {
                productData.update = {};
            } 
            productData.update[key] = value;
        }
        this.setState({ productData: { ...productData } });
    }
    resetStateProductData() {
        this.setState({ productData: {} });
    }
    componentWillUnmount(){
        let nutritionData = [...this.props.nutritionData];
        nutritionData = nutritionData.map((nutr) => {
            let nutrData = { ...nutr };
            delete nutrData.edited;
            delete nutrData.editActive;
            nutrData.isActive=false;
            nutrData.value="";
            return nutrData;
        })
        this.props.setNutritiondata([...nutritionData]);
        this.props.setSelectedAllergens([]);
        this.props.setSelectedCategories([]);
        this.props.setSelectedCollections([]);
        this.props.setSelectedGroupCollections([]);
        this.props.setProductVariants([]);
    }
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", gap: 25, width: "100%" }}>
                <PEHeader {...this.props} isNewProduct={this.state.isNewProduct} />
                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 20, }}>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: 15, paddingRight:15}}>
                        <div style={{ display: "flex", gap: 10, flexBasis:150, justifyContent:"center", alignItems:"center", border:"0.5px solid #cecece", borderRadius:4}}>
                            <Switch checked={this.state.productData?.update?.active != null ? this.state.productData?.update?.active : this.state.productData?.active != null && this.state.productData?.active} onChange={(evt) => { this.updateField("active", evt.target.checked ? 1 : 0) }} />
                            <Typography style={{marginRight:15}}>Aktiv</Typography>
                        </div>
                        {
                            !this.state.isNewProduct &&
                            <TextField style={{ flex: 1, flexBasis: 100 }} disabled={true} label="ID" value={this.props.product.id != null ? this.props.product.id : ''} />
                        }
                        {(this.state.productData?.imgSource != null || this.state.isNewProduct) &&
                            <ImageField label="Produktbild" baseUri={APIData.MEDIA} relPath={this.state.productData?.imgSource} dimensions={{ width: 500, height: 500 }} onSelect={(blobUrl) => { this.imgBlobURL = blobUrl }} />
                        }
                        <TextField onChange={(evt) => { this.updateField("mainPrice", evt.target.value) }} style={{ flex: 1, flexBasis: 100 }} type='number' label="Preis" value={this.state.productData?.update?.mainPrice != null ? this.state.productData?.update?.mainPrice : this.state.productData?.mainPrice ? this.state.productData?.mainPrice : ''} />
                        <TextField onChange={(evt) => { this.updateField("name", evt.target.value) }} style={{ flex: 1, flexBasis: 250 }} label="Name" value={this.state.productData?.update?.name != null ? this.state.productData?.update?.name : this.state.productData?.name ? this.state.productData?.name : ''} />
                        <TextField onChange={(evt) => { this.updateField("info", evt.target.value) }} style={{ flex: 1, flexBasis: 250 }} label="Info" value={this.state.productData?.update?.info != null ? this.state.productData?.update?.info : this.state.productData?.info ? this.state.productData?.info : ''} />
                    </div>
                    <div className='description-ingredients-area' style={{ display: "flex", flexWrap: "wrap", gap: 20, }}>
                        <Textarea sx={{ flex: 1 }} onChange={(evt) => { this.updateField('description', evt.target.value) }} placeholder='Beschreibung...' minRows={3} value={this.state.productData?.update?.description != null ? this.state.productData?.update?.description : this.state.productData?.description ? this.state.productData?.description : ''} />
                        <Textarea sx={{ flex: 1 }} onChange={(evt) => { this.updateField('ingredients', evt.target.value) }} placeholder='Zutaten / Inhaltsstoffe...' minRows={3} value={this.state.productData?.update?.ingredients != null ? this.state.productData?.update?.ingredients : this.state.productData?.ingredients ? this.state.productData?.ingredients : ''} />
                    </div>
                    <NutritionTable />
                    <ProductVariantArea shopId={this.props.shopId} />
                    <CategorySelector shopId={this.props.shopId} />
                    <CollectionSelector shopId={this.props.shopId} />
                    <GCSelector shopId={this.props.shopId} />
                    <AllergensSelector label="Allergene" />
                </div>
                <PEFooter onSave={() => { this.onSave() }} onCancel={() => { this.props.onBack() }} />
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        nutritionData: state.product.nutritionData,
        allergens: state.product.allergens,
        selectedAllergens: state.product.selectedAllergens,
        selectedCategories: state.product.selectedCategories,
        selectedGroupCollections: state.product.selectedGroupCollections,
        selectedCollections: state.product.selectedCollections,
        productVariants: state.product.productVariants
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setNutritiondata: (data) => {
            dispatch(setNutritiondata(data));
        },
        setSelectedAllergens: (data) => {
            dispatch(setSelectedAllergens(data));
        },
        setProductVariants: (variants) => {
            dispatch(setProductVariants(variants));
        },
        setSelectedCategories: (data) => {
            dispatch(setSelectedCategories(data));
        },
        setSelectedCollections: (data) => {
            dispatch(setSelectedCollections(data));
        },
        setSelectedGroupCollections: (data) => {
            dispatch(setSelectedGroupCollections(data));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductEditor)

class PEFooter extends Component {
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: 10 }}>
                <Button style={{ flex: 1 }} variant='contained' onClick={this.props.onSave} startIcon={<Save />}>Speichern</Button>
                <Button style={{ flex: 1 }} variant='outlined' onClick={() => { this.props.onCancel() }} startIcon={<Close />} >Abbrechen</Button>
            </div>
        )
    }
}
class PEHeader extends Component {
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                    <Typography fontSize={16} fontStyle="italic" >{this.props.isNewProduct ? "Neues Produkt" : `Produkt Bearbeiten (${this.props.product?.name})`}</Typography>
                </div>
                <div style={{ flex: 1, display: "flex", justifyContent: "right" }}>

                    <Button startIcon={<ArrowBack />} onClick={() => { this.props.onBack() }} variant='outlined'>Zurück</Button>
                </div>
            </div>
        )
    }
}