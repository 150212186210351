import { Close, Delete, Edit } from '@mui/icons-material'
import { CircularProgress, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { GD_MAIL_REGEX, GD_PHONE_REGEX, GENERIC_DRAWER_TYPE } from '../GenericDrawer'

export class EditableField extends Component {
    buildEditArea(fieldItem, index) {
        switch (fieldItem.type) {
            case GENERIC_DRAWER_TYPE.DIVIDER:
                return <Divider key={(fieldItem.label != null ? fieldItem.label : fieldItem.value) + "_" + index} style={{ marginTop: 25, marginBottom: 25, color: "#676767" }}>{fieldItem.label != null ? fieldItem.label : fieldItem.value}</Divider>
            case GENERIC_DRAWER_TYPE.TEXT:
                if (fieldItem.asyncCheck != null) {
                    return <TextField maxRows={fieldItem.maxChar} type={fieldItem.isNumeric ? 'number' : fieldItem.isPhone ? 'tel' : 'text'} helperText={fieldItem.helperText} error={fieldItem.error} key={fieldItem.label + "_" + index}
                        InputProps={{
                            endAdornment: fieldItem.loading && (
                                <InputAdornment position="end">
                                    <CircularProgress size={15} />
                                </InputAdornment>
                            ),
                        }}
                        onBlur={(evt) => {
                            console.log(evt.target.value);
                            if (evt.target.value.length > 0) {
                                if (fieldItem.isMail && !GD_MAIL_REGEX.test(evt.target.value)) {
                                    this.props.setEditableFieldError(this.props.editableIndex, index, "E-Mail Adresse ist ungültig!");
                                } else if (fieldItem.isPhone && !GD_PHONE_REGEX.test(evt.target.value)) {
                                    this.props.setEditableFieldError(this.props.editableIndex, index, "Ungültige Telefonnummer!")
                                } else if (evt.target.value.length < fieldItem.minChar) {
                                    this.props.setEditableFieldError(this.props.editableIndex, index, `Dieses Feld muss mindestens ${fieldItem.minChar} Zeichen haben.`);
                                } else {
                                    this.props.setFieldLoading(this.props.editableIndex, index, true);
                                    fieldItem.asyncCheck()
                                        .then((result) => { if (!result.success) { this.props.setEditableFieldError(this.props.editableIndex, index, result.errorMsg) } })
                                        .catch(() => { this.props.setFieldLoading(this.props.editableIndex, index, false); })
                                        .finally(() => { this.props.setFieldLoading(this.props.editableIndex, index, false); })
                                }
                                // else if (fieldItem.check != null) {
                                //     let errorMsg = fieldItem.check(evt.target.value);
                                //     if (errorMsg != null) {
                                //         this.props.setEditableFieldError(index, errorMsg);
                                //     }
                                // }
                            } else if (fieldItem.isRequired) {
                                this.props.setEditableFieldError(this.props.editableIndex, index, "Das ist ein Pflichtfeld")
                            }
                            // do the same checks like in the textfield in GenericDrawer and show errorMessages
                            // this.props.setEditableFieldError(this.props.editableIndex, index, errorMsg);
                        }
                        } onChange={(evt) => { /*if (!option.isPhone || option.isPhone && (/^(0|\+\d{1,15})$/).test(evt.target.value)) { updateField(index, evt.target.value) } else { }*/ this.props.updateFieldValue(this.props.editableIndex, index, evt.target.value); }
                        } label={fieldItem.label} variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={fieldItem.value} disabled={fieldItem.disabled} />;
                } else {
                    return <TextField maxRows={fieldItem.maxChar} type={fieldItem.isNumeric ? 'number' : fieldItem.isPhone ? 'tel' : 'text'} helperText={fieldItem.helperText} error={fieldItem.error} key={fieldItem.label + "_" + index}
                        onBlur={(evt) => {
                            console.log(evt.target.value);
                            if (evt.target.value.length > 0) {
                                if (fieldItem.isMail && !GD_MAIL_REGEX.test(evt.target.value)) {
                                    this.props.setEditableFieldError(this.props.editableIndex, index, "E-Mail Adresse ist ungültig!");
                                } else if (fieldItem.isPhone && !GD_PHONE_REGEX.test(evt.target.value)) {
                                    this.props.setEditableFieldError(this.props.editableIndex, index, "Ungültige Telefonnummer!")
                                } else if (evt.target.value.length < fieldItem.minChar) {
                                    this.props.setEditableFieldError(this.props.editableIndex, index, `Dieses Feld muss mindestens ${fieldItem.minChar} Zeichen haben.`);
                                } else if (fieldItem.check != null) {
                                    let errorMsg = fieldItem.check(evt.target.value);
                                    if (errorMsg != null) {
                                        this.props.setEditableFieldError(this.props.editableIndex, index, errorMsg);
                                    }
                                }
                            } else if (fieldItem.isRequired) {
                                this.props.setEditableFieldError(this.props.editableIndex, index, "Das ist ein Pflichtfeld")
                            }
                            // do the same checks like in the textfield in GenericDrawer and show errorMessages
                            // this.props.setEditableFieldError(this.props.editableIndex, index, errorMsg);
                        }} onChange={(evt) => { /*if (!option.isPhone || option.isPhone && (/^(0|\+\d{1,15})$/).test(evt.target.value)) { updateField(index, evt.target.value) } else { }*/ this.props.updateFieldValue(this.props.editableIndex, index, evt.target.value); }} label={fieldItem.label} variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={fieldItem.value} disabled={fieldItem.disabled} />;
                }
            case GENERIC_DRAWER_TYPE.ENUM:
                return (<FormControl key={fieldItem.label + "_" + index} style={{ marginTop: 15, width: "100%" }}>
                    <InputLabel id={fieldItem.label + "_" + index}>{fieldItem.label}</InputLabel>
                    <Select value={fieldItem?.value} labelId={fieldItem?.label + "_" + index} label={fieldItem?.label} onChange={(evt) => { this.props.updateFieldValue(this.props.editableIndex, index, evt.target.value); }}>
                        {Object.keys(fieldItem.options).map(key => {
                            return <MenuItem key={key} value={fieldItem.options[key]}>{key}</MenuItem>
                        })}
                    </Select>
                </FormControl>)
            default:
                return null;
        }
    }
    render() {
        return (<div style={{ padding: 10, display: "flex", flexDirection: "row", marginTop: 15, borderRadius: 5, borderWidth: 0.5, borderColor: "#979797", borderStyle: "dotted" }} key={"Editable_" + this.props.editableIndex}>
            <div style={{ flex: 4, display: "flex", flexDirection: "column", justifyContent: "center" }}>

                {
                    this.props.fields?.map((fieldItem, index) => {
                        return (
                            this.props.isEditMode ?
                                this.buildEditArea(fieldItem, index)
                                :
                                fieldItem?.isDivider == true ?
                                    <Typography key={"Editable_" + this.props.editableIndex + "_Item_" + index} style={{ marginTop: 5 }} fontStyle={"italic"} fontWeight="bold" fontSize={10}>{fieldItem.label}</Typography> :
                                    <Typography key={"Editable_" + this.props.editableIndex + "_Item_" + index} fontSize={10}>{fieldItem.label}: {fieldItem.value}</Typography>)
                    })}
            </div>
            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <IconButton style={{ backgroundColor: "#FAFAFA" }} onClick={() => { 
                    // if(this.props.optionData)
                    console.log("OPTDATA: ",this.props.optionData);
                    if(this.props.isEditMode){
                        if(!this.props.optionData.check && !this.props.optionData.asyncCheck ){
                            this.props.setEditMode(this.props.editableIndex, false) 
                        }else {
                            if(this.props.optionData.check != null){
                                this.props.optionData.check(this.props.editableIndex);
                            }else{
                                this.props.optionData.asyncCheck(this.props.editableIndex)
                                .catch(e => {
                                    console.log("Error while asyncCheck EditableField: ",this.props.optionData);
                                })
                            }
                        }
                    }else{
                        this.props.setEditMode(this.props.editableIndex, true) 
                    }
                        }} color='primary'>
                    {this.props.isEditMode ?
                        <Close /> :
                        <Edit />
                    }
                </IconButton>
                {!this.props.isEditMode &&
                    <IconButton onClick={() => { this.props.deleteEntity(this.props.editableIndex) }} style={{ backgroundColor: "#FAFAFA" }} color='error'><Delete /></IconButton>
                }
            </div>
        </div>)
    }
}

export default EditableField