import React, { Component } from 'react'
import DefaultFrame from '../components/DefaultFrame';

const TestWrapper = ({children}) => {
    const micCheckStr = "test123MIC-CHECK";
    return React.cloneElement(children, { micCheckStr });
};


class TestChild extends React.Component {
    render() {
        return (
            <div>
                Hello World - PROPS={JSON.stringify(this.props)}
            </div>
        );
    }
}
export class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        console.log("myProps?: ", this.props);
        return (
            <div>
                <DefaultFrame noPermissionCheck>
                    {/* <div>Hello World - PROPS={JSON.stringify(this.props)}</div> */}
                    <TestChild />
                </DefaultFrame>
            </div>
        );
    }
}

export default Test;
