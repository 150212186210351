import { Typography } from '@mui/material';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class DirectLinkButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bgColor: '#ebf1f5'
        }
    }
    render() {
        return (
            <div style={{height:50}}>
                <Link to={this.props.path} onMouseEnter={() => { this.setState({ bgColor: '#edf3f7' }) }} onMouseLeave={() => { this.setState({ bgColor: '#ebf1f5' }) }}
                    style={{ backgroundColor: this.state.bgColor, textDecoration: "none", color: "#353535", gap: 15, flex: 1, borderRadius: 5, height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {this.props.icon &&
                        this.props.icon
                    }
                    <Typography>{this.props.title}</Typography>
                </Link>
            </div>
        )
    }
}

export default DirectLinkButton