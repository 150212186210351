import React, { Component } from 'react'
import CollapsibleTile from '../../../components/CollapsibleTile'
import GenericTable from '../../../components/GenericTable/GenericTable'
import { APIData } from '../../../constants/APIAdresses'
import { MUI_COLORS } from '../../../constants/MUIConstants'
import { GD_MODE, GENERIC_DRAWER_TYPE } from '../../../components/GenericDrawer'
import { type } from '@testing-library/user-event/dist/type'
import { ALERT_SEVERITY } from '../../../components/AlertDisplay2'
const gcColumns = [
    {
        width: 100,
        label: 'ID',
        dataKey: 'id',
    },
    {
        width: 100,
        label: 'Anzahl',
        dataKey: 'quantity',
    },
    {
        flex: 1,
        label: 'Name',
        dataKey: 'name',
    },
    {
        width: 100,
        // flex: 1,
        label: 'Kategorie',
        dataKey: 'cid',
        format: (value) => {
            return value ? "Ja" : 'Nein'
        },
    },
    {
        // width: 4,
        width: 120,
        label: 'Gruppenkategorie',
        dataKey: 'gc_cat_id',
        format: (value) => {
            return value ? "Ja" : 'Nein'
        },
    },
]
const ENTITY_TYPES = {
    "Kategorie": "CATEGORY",
    "Gruppenkategorie": "GROUP_CATEGORY"
}
export class GroupCollectionsTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: []
        }
        this.shopId = this.props.shopId;
    }
    deleteGC(index) {
        console.log("DELETE GC: ", this.state.tableData[index]);
        fetch(APIData.SHOP + `/shop/${this.shopId}/groupCollection/${this.state.tableData[index].id}/delete`, {method: "DELETE", credentials:"include"})
        .then(response => {
            if(response.ok){
                let myTableData = [...this.state.tableData];
                myTableData.splice(index, 1);
                this.setState({tableData: myTableData});
                this.props.showAlertDisplay("Gruppenkollektion erfolgreich gelöscht", ALERT_SEVERITY.SUCCESS);
            }else{
                this.props.showAlertDisplay("Fehler beim Löschen der Gruppenkollektion", ALERT_SEVERITY.ERROR);
            }
        }).catch(e => {
            console.log("Error while deleting GC: ", e);
            this.props.showAlertDisplay("Fehler beim Löschen der Gruppenkollektion", ALERT_SEVERITY.ERROR);
        })
    }
    changeEntitySelection(value, configData, entitySelectorIndex, mode) {
        configData.fields[entitySelectorIndex].label = value == ENTITY_TYPES.CATEGORY ? "Kategorie" : "Gruppenkategorie";
        if (value == ENTITY_TYPES.Kategorie) {
            configData.fields[entitySelectorIndex].apiEndPoint = "https://viveel.de:3000/categories?shopId=" + this.shopId;
            delete configData.fields[entitySelectorIndex].paginationKey
            configData.fields[entitySelectorIndex].localPagination = true;
            configData.fields[entitySelectorIndex].label = "Kategorie Auswählen";
        } else {
            configData.fields[entitySelectorIndex].apiEndPoint = "https://viveel.de:3000/shop/" + this.shopId + "/groupCategories/getAll";
            configData.fields[entitySelectorIndex].paginationKey = "offset"
            delete configData.fields[entitySelectorIndex].localPagination;
            configData.fields[entitySelectorIndex].label = "Gruppenkategorie Auswählen";
        }
        this.props.openDrawer(configData, mode != null ? mode : GD_MODE.NEW);
    }
    addGC(configData) {
        console.log("ADD GC: ", configData);
        let body = {
            // cid: configData.fields[2].value,
            // gc_cat_id: configData.fields[3].value,
            quantity: configData.fields[1].value,
            name: configData.fields[2].title
        }
        if (configData.fields[0].value == ENTITY_TYPES.Kategorie) {
            body.cid = configData.fields[2].value;
        } else {
            body.gc_cat_id = configData.fields[2].value;
        }
        fetch(APIData.SHOP + `/shop/${this.shopId}/groupCollection/add`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                console.log("ErrStatus while adding GC: ", response.status);
                return null;
            }
        }).then(data => {
            console.log("ADD GC RESPONSE: ", data);
            let myTableData = [...this.state.tableData];
            myTableData.push({...body, id: data.insertId});
            this.setState({tableData: myTableData});
            this.props.showAlertDisplay("Gruppenkollektion erfolgreich hinzugefügt", ALERT_SEVERITY.SUCCESS);
            this.props.closeDrawer();
            // insertId
        }).catch(() => {
            this.props.showAlertDisplay("Fehler beim Hinzufügen der Gruppenkollektion", ALERT_SEVERITY.SUCCESS);
            this.props.closeDrawer();
        })
    }
    openAddGCDrawer() {
        let baseDrawerConfig = {
            options: [
                { label: "Speichern", onClick: (configData) => { this.addGC(configData) } },
                { label: "Abbrechen", onClick: () => { this.closeDrawer() } }
            ],
            fields: [
                {
                    label: "Art",
                    type: GENERIC_DRAWER_TYPE.ENUM,
                    onUpdateCallback: (value, configData) => { this.changeEntitySelection(value, configData, 2) },
                    options: { ...ENTITY_TYPES },
                    value: ENTITY_TYPES.Kategorie,
                    dbKey: ""
                },
                {
                    label: "Anzahl",
                    type: GENERIC_DRAWER_TYPE.INTEGER_AMOUNT,
                    value: 1,
                    minValue: 1,
                    dbKey: "quantity"
                },
                {
                    label: "Kategorie Auswählen",
                    apiEndPoint: "https://viveel.de:3000/categories?shopId=" + this.shopId,
                    localPagination: true,
                    entityTitleKey: 'name', // actually not needed since default is name
                    entityTitleId: 'id', // actually not needed since default is id
                    type: GENERIC_DRAWER_TYPE.ENTITY_SELECTOR,
                    value: "",
                    title: "",
                    dbKey: ''
                }
            ]
        }

        this.props.openDrawer(baseDrawerConfig, GD_MODE.NEW);
    }
    updateGC(configData) {
        let body = {
            quantity: configData.fields[2].value,
            // gcid: configData.id,
        }
        if(configData.fields[1].value == ENTITY_TYPES.Kategorie){
            body.cid = configData.fields[3].value;
        }else{
            body.gc_cat_id = configData.fields[3].value;
        }
        fetch(APIData.SHOP + `/shop/${this.shopId}/groupCollection/${this.state.tableData[configData.index].id}/update`, {headers: {'Content-Type': 'application/json'}, method: "POST", body: JSON.stringify(body)})
        .then(response => {
            if(response.ok){
                // update the table fields, show success and close the drawer
                let myTableData = [...this.state.tableData];
                myTableData[configData.index].quantity = configData.fields[2].value;
                if(body.cid){
                    myTableData[configData.index].cid = body.cid;
                    delete myTableData[configData.index].gc_cat_id;
                }else{
                    myTableData[configData.index].gc_cat_id = body.gc_cat_id;
                    delete myTableData[configData.index].cid;
                }
                if(configData.fields[3].title){
                    myTableData[configData.index].name = configData.fields[3].title;
                }
                this.setState({tableData: myTableData});
                this.props.showAlertDisplay("Gruppenkollektion erfolgreich aktualisiert", ALERT_SEVERITY.SUCCESS);
                this.props.closeDrawer();
            }
            else{
                this.props.showAlertDisplay("Fehler beim Aktualisieren der Gruppenkollektion", ALERT_SEVERITY.ERROR);
                this.props.closeDrawer();
            }
        }).catch(e => {
            console.log("Error while updating GC: ", e);
            this.props.showAlertDisplay("Fehler beim Aktualisieren der Gruppenkollektion", ALERT_SEVERITY.ERROR);
            this.props.closeDrawer();
        })
    }
    openEditGCDrawer(index){
        let baseDrawerConfig = {
            index: index,
            options: [
                { label: "Speichern", onClick: (configData) => { this.updateGC(configData) } },
                { label: "Abbrechen", onClick: () => { this.closeDrawer() } }
            ],
            fields: [
                {
                    label: "ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: true,
                    value: this.state.tableData[index].id,
                    dbKey: "id",
                },
                {
                    label: "Art",
                    type: GENERIC_DRAWER_TYPE.ENUM,
                    onUpdateCallback: (value, configData) => { this.changeEntitySelection(value, configData, 3, GD_MODE.UPDATE) },
                    options: { ...ENTITY_TYPES },
                    // value: ENTITY_TYPES.Kategorie,
                    value: this.state.tableData[index].cid ? ENTITY_TYPES.Kategorie : ENTITY_TYPES.Gruppenkategorie,
                    dbKey: ""
                },
                {
                    label: "Anzahl",
                    type: GENERIC_DRAWER_TYPE.INTEGER_AMOUNT,
                    value: this.state.tableData[index].quantity,
                    minValue: 1,
                    dbKey: "companyName"
                },
            ]
        }
        if(this.state.tableData[index].cid){
            baseDrawerConfig.fields.push({
                label: "Kategorie Auswählen",
                apiEndPoint: "https://viveel.de:3000/categories?shopId=" + this.shopId,
                localPagination: true,
                entityTitleKey: 'name', // actually not needed since default is name
                entityTitleId: 'id', // actually not needed since default is id
                type: GENERIC_DRAWER_TYPE.ENTITY_SELECTOR,
                value: this.state.tableData[index].cid,
                title: "",
                dbKey: ''
            })
        }else {
            baseDrawerConfig.fields.push({
                label: "Gruppenkategorie Auswählen",
                apiEndPoint: "https://viveel.de:3000/shop/" + this.shopId + "/groupCategories/getAll",
                paginationKey: "offset",
                entityTitleKey: 'name', // actually not needed since default is name
                entityTitleId: 'id', // actually not needed since default is id
                type: GENERIC_DRAWER_TYPE.ENTITY_SELECTOR,
                value: this.state.tableData[index].gc_cat_id,
                title: "",
                dbKey: ''
            });
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    render() {
        return (
            <CollapsibleTile
                title="Gruppenkollektionen"
                onCollapseChange={(expanded, firstExpand) => { }}
                icon={<img src={require('../../../assets/icons/hierarchical-structure.png')} width={20} height={20} />}
            >
                <GenericTable
                    config={{ columns: [...gcColumns] }}
                    apiEndPoint={APIData.SHOP + `/shop/${this.shopId}/groupCollections/getPaged`}
                    tableData={this.state.tableData}
                    setTableData={(data) => { this.setState({ tableData: data }) }}
                    onEdit={(index) => {
                        this.openEditGCDrawer(index);
                    }}
                    onDelete={(index) => {
                        this.props.openAlertDialog(
                            [
                                {
                                    label: "Ja",
                                    color: MUI_COLORS.ERROR,
                                    onChoice: () => {
                                        this.deleteGC(index);
                                    }
                                },
                                {
                                    label: "Nein",
                                    onChoice: () => {
                                        console.log("Kollektion wird nicht gelöscht...");
                                    }
                                }
                            ],
                            "Löschen", "Möchten Sie die Kollektion wirklich löschen?");
                    }}
                    addBtnOnClick={() => { this.openAddGCDrawer() }}
                    addBtnLabel="Kollektion Hinzufügen"
                />
            </CollapsibleTile>
        )
    }
}

export default GroupCollectionsTile