import { createSlice } from "@reduxjs/toolkit";
export const ALERT_SEVERITY_TYPE = {
    ERROR: "error",
    WARNING: "warning",
    INFO: "info",
    SUCCESS: "success"
}
export const generalSlice = createSlice({
    name:'general',
    initialState: {
        alertStack: [],
    },
    reducers: {
        addToAlertStack: (state, arg) => {
            // payload must contain an object with content and severity attributes. Severity must be of type ALERT_SEVERITY_TYPE
            state.alertStack = [...state.alertStack, arg.payload]
        }
    }
})

export const { setLoggedIn, setRole } = generalSlice.actions

export default generalSlice.reducer