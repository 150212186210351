import React from 'react';
import Header from './Header';
import PermissionAreaComponent from '../hookInjection/PermissionAreaComponent';
import { GD_MODE, GenericDrawer } from './GenericDrawer';
import { AlertDisplay2 } from './AlertDisplay2';
import AlertDialog from './AlertDialog';
import ActiveCasesBar from '../areas/support/Elements/ActiveCaseBottomBar/ActiveCasesBar';
import SupportChatDialog from '../areas/support/Elements/SupportChatDialog';
class DefaultFrameComponent extends React.Component {
    render() {
        return (
            <div style={{ width: "100%", height: "100%", flexDirection: "column", display: "flex", }}>
                <div style={{ height: 64 }}>
                    <Header title={this.props.title} noMenu={this.props.noMenu} noBack={this.props.noBack} noLogout={this.props.noLogout} />
                </div>
                <div style={{ flex: 1, overflow: "scroll", scrollbarWidth: 'none', padding: 20, display: "flex", flexDirection: "column" }}>
                    {this.props.children != null &&
                        React.cloneElement(this.props.children,
                            {
                                showAlertDisplay: this.props.showAlertDisplay,
                                openDrawer: this.props.openDrawer,
                                closeDrawer: this.props.closeDrawer,
                                openAlertDialog: this.props.openAlertDialog,
                            }
                        )}
                </div>
            </div>
        );
    }
}
// DefaultFrame: conditional wrapper component
class DefaultFrame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertDisplayMsg: "",
            alertSeverity: "",
            alertDisplayVisible: false,
            gdMode: GD_MODE.UPDATE,
            gdConfig: {},
            gdIsOpen: false,
            ADOptions: [],
            ADOpen: false,
            ADTitle: "",
            ADMessage: "",
        }
    }
    showAlertDisplay(msg, severity) {
        this.setState({
            alertDisplayMsg: msg,
            alertSeverity: severity,
            alertDisplayVisible: true,
        })
    }
    hideAlertDisplay() {
        this.setState({
            alertDisplayVisible: false,
        });
    }
    openDrawerWithConfig(config, mode) {
        console.log("Opening Drawer... DF")
        this.setState({
            gdIsOpen: true,
            gdConfig: { ...config },
            gdMode: mode,
        });
    }
    closeDrawer() {
        this.setState({
            gdIsOpen: false,
            gdConfig: {}
        });
    }
    openAlertDialog(options, title, message) {
        this.setState({
            ADOptions: [...options],
            ADOpen: true,
            ADTitle: title,
            ADMessage: message,
        });
    }
    render() {
        return (
            <>
                {
                    this.props.noPermissionCheck ?
                        <DefaultFrameComponent {...this.props} openAlertDialog={(options, title, msg) => { this.openAlertDialog(options, title, msg) }} showAlertDisplay={(msg, severity) => { this.showAlertDisplay(msg, severity) }} openDrawer={(config, mode) => { this.openDrawerWithConfig(config, mode) }} closeDrawer={() => { this.closeDrawer() }} /> :
                        <PermissionAreaComponent children={
                                <DefaultFrameComponent {...this.props} openAlertDialog={(options, title, msg) => { this.openAlertDialog(options, title, msg) }} showAlertDisplay={(msg, severity) => { this.showAlertDisplay(msg, severity) }} openDrawer={(config, mode) => { this.openDrawerWithConfig(config, mode) }} closeDrawer={() => { this.closeDrawer() }} />
                                
                        } />
                }
                <AlertDisplay2
                    severity={this.state.alertSeverity}
                    label={this.state.alertDisplayMsg}
                    isVisible={this.state.alertDisplayVisible}
                    hide={() => { this.hideAlertDisplay() }}
                />
                <GenericDrawer mode={this.state.gdMode} config={this.state.gdConfig} isOpen={this.state.gdIsOpen} closeDrawer={() => { this.closeDrawer() }} openDrawer={(config, mode) => { this.openDrawerWithConfig(config, mode) }} />
                <AlertDialog closeDialog={() => { this.setState({ ADOpen: false }) }} options={this.state.ADOptions} open={this.state.ADOpen} title={this.state.ADTitle} message={this.state.ADMessage} />
            </>
        )
    }
}

export default DefaultFrame;


