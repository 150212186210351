import { TabPanel } from '@mui/lab'
import { Accordion, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Cached, Close, ExpandMore, Save, SaveOutlined } from '@mui/icons-material';
import { ROLES } from '../../../../redux/permissionSlice';
import { APIData } from '../../../../constants/APIAdresses';
import { SUPPORTCHAT_INFOTAB, changeSupportStatus, setSupportChatTabAreaLoading, setUserInfoForActiveCase } from '../../../../redux/supportSlice';
const AccordionSummary = styled((props) => (

    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
        margin: 'auto',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    // margin: 0,
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
export const addressComponent = (addressData, marginTop) => {
    // const addressData = {"id":1,"zip":"28201","city":"Stadtgebiet Bremen","phone":null,"InvZip":null,"street":"Buntentorsteinweg","InvCity":null,"company":null,"InvPhone":null,"fullname":"Sercan Savran","InvNumber":null,"InvStreet":null,"InvCompany":null,"InvFullname":null,"houseNumber":"171","sameInvoiceAddress":1}
    return (
        <div style={{padding:10, marginTop: marginTop != null ? marginTop : 15, borderRadius:5, borderWidth:0.5, borderColor:"#979797", borderStyle:"dotted"}} key={"AddressDisplay_"+addressData.id}>
            <Typography fontSize={10}>ID: {addressData.id}</Typography>
            <Typography fontSize={10}>Name: {addressData.fullname} {addressData.company != null ? `(${addressData.company})` : ""}</Typography>
            <Typography fontSize={10}>Adresse: {addressData.street} {addressData.houseNumber}, {addressData.zip} {addressData.city}</Typography>
            {addressData.phone != null &&
                <Typography fontSize={10}>Telefon: {addressData.phone}</Typography>
            }
            {addressData.sameInvoiceAddress === 0 &&
                <>
                    <Typography fontSize={10} style={{fontWeight:"bold"}}>Rechnungsaddresse:</Typography>
                    <Typography fontSize={10}>Name: {addressData.InvFullname} {addressData.InvCompany != null ? `(${addressData.InvCompany})` : ""}</Typography>
                    <Typography fontSize={10}>Adresse: {addressData.InvStreet} {addressData.InvNumber}, {addressData.InvZip} {addressData.InvCity}</Typography>
                    {addressData.InvPhone != null &&
                        <Typography fontSize={10}>Telefon: {addressData.InvPhone}</Typography>
                    }
                </>
            }
        </div>
    )
}

export class UserInfoTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {}
        }
        this.counter = 0;
        // this.initialData = {};
        this.alreadyLoaded= false;
    }
    loadUserInfo(){
        if(this.props.data?.userId != null && !this.userDataLoaded){
            console.log("USERID: ",this.props.data.userId)
            console.log("USERID: ",this.props.data)
            fetch( APIData.USER+`/user/${this.props.data.userId}/getUserInfo`, {credentials:"include"})
            .then(response => {
                console.log("Response: ",response);
                if(response.ok){
                    return response.json();
                }else{
                    return null;
                }
            }).then((result)=> {
                if(result != null){
                    this.alreadyLoaded=true;
                    if(result.userCompanyRelation?.length > 0){
                        result.userCompanyRelation = JSON.parse(result.userCompanyRelation);
                    }
                    if(result.addressData?.length > 0){
                        result.addressData = JSON.parse(result.addressData);
                    }
                    // this.initialData = {...result}; // that's a value to track changes when saving.
                    this.props.setUserInfo(this.props.currentCaseIndex, result);
                    this.setState({userInfo: {...result}});
                    // this.props.addUserInfoToActiveCase(this.props.currentCaseIndex,result);
                }
                console.log("Result: ",result);
            })
            .catch(e => {
                console.log("Error while loading data...");
            })
        }
    }
    componentDidMount(){
        // console.log("+++++++++++++++++ MOUNTED....");
        if(this.props?.userInfo != null && Object.keys(this.state.userInfo).length != Object.keys(this.props.userInfo).length){
            this.setState({userInfo: {...this.props.userInfo} });
        }
    }
    componentDidUpdate() {
        console.log("USERINFOTAB COMPDIDUPDATE???");
        if((this.props.tabActive && !this.alreadyLoaded)){
            this.loadUserInfo();
        }
        if(!this.props.tabActive){
            this.alreadyLoaded = false; // to refresh the view if changing tabs.
        }
    }
    save(){
        let changes = {}
        console.log("STATEROLE: ",typeof this.state.userInfo.role);
        console.log("INITIALDATAROLE: ", typeof this.props.userInfo.role);
        if(this.state.userInfo.role !== this.props.userInfo.role){
            changes.role = this.state.userInfo.role
        }
        if(this.state.userInfo.phoneNumber !== this.props.userInfo.phoneNumber){
            changes.phoneNumber = this.state.userInfo.phoneNumber;
        }
        if(this.state.userInfo.name !== this.props.userInfo.name){
            changes.name = this.state.userInfo.name;
        }
        if(this.state.userInfo.email !== this.props.userInfo.email){
            changes.email = this.state.userInfo.email;
        }
        if(Object.keys(changes).length > 0){
            console.log("CHANGES TO SAVE: ",changes);
            this.props.setSupportChatTabAreaLoading(true);
            fetch(APIData.USER+`/user/${this.state.userInfo.id}/updateData`, {credentials:"include", body: JSON.stringify({fields: {...changes}}), method:"POST", headers: { 'Content-Type': 'application/json' }})
            .then(response => {
                this.props.setSupportChatTabAreaLoading(false);
                if(response.ok){
                    this.props.setUserInfo(this.props.currentCaseIndex, this.state.userInfo) // setting the user info data from state to our props values to react on new changes.
                    alert("Daten erfolgreich übermittelt.");
                }else{
                    alert("Fehler beim Ändern der Benutzerdaten");
                }
            })

        }else{
            console.log("Nothing to save...");
        }
        // /user/:id/updateData
        // ["role", "defaultAddressId", "password", "phoneNumber", "name", "email", "totpbase" ]

    }
    toggleCaseStatus(){
        fetch(APIData.REALTIME+`/support/case/${this.props.data.id}/updateStatus/${this.props.data.status === "1" ? "2" : "1"}`, {credentials:"include"})
        .then(() => {
            this.props.changeSupportStatus(this.props.data.status === "1" ? "2" : "1");
        }).catch(() => {
            alert("Beim Ändern vom Supportcase-Status ist ein Fehler aufgetreten.");
        })
        // console.log("ACTIVECASEDATA: ",this.props.data);
    }
    onFieldChange(key, value){
        let myUserInfo = {...this.state.userInfo};
        myUserInfo[key] = value;
        this.setState({userInfo: {...myUserInfo}});
    }
    render() {
        return (
            this.state.userInfo != null &&
            <TabPanel style={{overflow: "scroll"}} value={SUPPORTCHAT_INFOTAB.USER.toString()}>
                {this.state.userInfo.externalId != null &&
                    <TextField label="Externe-ID (Social Login):" variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={this.state.userInfo.externalId} disabled />
                }
                {this.state.userInfo.externalAPType != null &&
                    <TextField label="Social-Login Art:" variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={this.state.userInfo.externalAPType} disabled />
                }
                <TextField label="ID" variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={this.state.userInfo.id} disabled />
                <TextField label="Benutzername" variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={this.state.userInfo.username} disabled />
                <FormControl style={{ marginTop: 15, width: "100%" }}>
                    <InputLabel id="role-simple-select-label">Rolle</InputLabel>
                    <Select value={this.state.userInfo?.role != null ? this.state.userInfo?.role : ""} labelId='role-simple-select-label' label="Rolle" onChange={(evt) => { let myUserInfo={...this.state.userInfo}; myUserInfo.role = evt.target.value.toString(); this.setState({userInfo: {...myUserInfo}}) }}>
                        {Object.keys(ROLES).map(key => {
                            return <MenuItem key={key} value={ROLES[key]}>{key}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <TextField label="Telefonnummer" onChange={(evt) => { this.onFieldChange("phoneNumber", evt.target.value); }} variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 15 }} value={this.state.userInfo.phoneNumber} />
                <TextField label="Name" onChange={(evt) => { this.onFieldChange("name", evt.target.value); }} variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 30 }} value={this.state.userInfo.name} />
                <TextField label="E-Mail" onChange={(evt) => { this.onFieldChange("email", evt.target.value); }} variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "100%", marginTop: 30 }} value={this.state.userInfo.email} />
                <Divider style={{ marginTop: 25, marginBottom:25, color:"#676767" }}>Adressen</Divider>
                {this.state.userInfo?.addressData?.length > 0 ?
                    this.state.userInfo.addressData.map(adData => {
                        return addressComponent(adData)
                    })
                    :
                    <Typography style={{color:"#676767"}}>Keine Adressen</Typography>
                }
                {this.state.userInfo?.userCompanyRelation?.length > 0 &&
                    <>
                        <Divider style={{ marginTop: 25, marginBottom:25, color:"#676767" }}>Rollen (Shop)</Divider>
                        <Typography style={{fontSize: 10}}>Rollenlegende: 
                            {Object.keys(ROLES).map((key, index) => {
                                return <Typography style={{fontSize: 10}} key={"UserRoles_"+key}>{key} = {ROLES[key]} {index != 0 && index < Object.keys(ROLES).length-1 && ", "}</Typography>
                            })}
                        </Typography>
                        {this.state.userInfo.userCompanyRelation.map(relation => {
                            return (
                                <>
                                    <TextField label="Shop-ID" variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "48%", marginTop: 15 }} defaultValue={relation.shopId} />
                                    <TextField label="ROLLE" variant='outlined' InputLabelProps={{ shrink: true }} style={{ width: "48%", marginLeft:"4%", marginTop: 15 }} defaultValue={relation.role} />
                                </>
                            )
                        })}
                    </>
                }
                
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginTop:15}}>
                    <Button variant='outlined' color={this.props.data?.status === "1" ? 'error' : 'success'} style={{marginRight:"2.5%"}} startIcon={this.props.data?.status === "1" ? <Close/> : <Cached/>} onClick={()=> {this.toggleCaseStatus()}}>{this.props.data?.status === "1" ? "Fall Schließen" : "Fall Öffnen"}</Button>
                    <Button variant='contained' startIcon={<Save/>} onClick={() => {this.save()}}>Speichern</Button>
                </div>
                {/* <CircularProgress size="small"/> */}
            </TabPanel>
        )
    }
}
// {userId, sessionId, createdAt, unreadItems: 0, msgList: [], loadOffset: 0, userInfo: {}, orderInfo: [], supportCases: [] }
function mapStateToProps(state) {
    return {
        userInfo: state.support.activeCaseList[state.support.chatCaseIndex]?.userInfo,
        data: state.support.activeCaseList[state.support.chatCaseIndex],
        currentCaseIndex: state.support.chatCaseIndex,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        changeSupportStatus: (status) => {
            dispatch(changeSupportStatus(status));
        },
        setUserInfo: (index, data) => {
            dispatch(setUserInfoForActiveCase({index: index, data: data}));
        },
        setSupportChatTabAreaLoading: (isActive) => {
            dispatch(setSupportChatTabAreaLoading(isActive));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserInfoTab)
