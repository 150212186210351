import React, { Component } from 'react'
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, fontSize, fontWeight, textAlign } from '@mui/system';
import { Button, CircularProgress, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import './GenericTable.css';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import GTTableHeader from './GTTableHeader';
import GTTableBody from './GTTableBody';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}


export const CELLFORMAT = {
    TEXT: "TEXT",
    SWITCH: "SWITCH",
}
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#b9d3e4',
        // backgroundColor: '#adbdc8',
        color: '#5a5a5a',
        textAlign:"left",
        // fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        textAlign:'left',
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const itemsPerPageOptions = [10, 25, 50, 100];
export class GenericTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            searchText: "",
            pageIndex: 0,
            pageCount: 2,
            itemsPerPage: 10,
            // data: []
        }
    }
    searchText(text) {
        this.setState({ searchText: text });
        // console.log(text);
        if (text.length >= 3) {
            console.log("TextSearch: ", text);
            this.setState({ pageIndex: 0 });
            this.loadAndSetData(text, 0, this.state.itemsPerPage);
        } else {
            this.setState({ pageIndex: 0 });
            this.loadAndSetData(null, 0, this.state.itemsPerPage);
        }
    }
    loadAndSetData(searchText, pageIndex, itemsPerPage) {
        let query = (searchText != null ? "?search=" + searchText : "");
        query += (pageIndex != null ? (query.length > 0 ? "&" : "?") + "page=" + pageIndex : "");
        query += (itemsPerPage != null ? (query.length > 0 ? "&" : "?") + "ipp=" + itemsPerPage : "");
        this.setState({ isLoading: true });
        console.log("Query: ", (this.props.apiEndPoint+query));
        fetch(this.props.apiEndPoint + query, {credentials:"include"})
            .then((response) => {
                console.log("Response: ", response.status);
                return response.json();
            }).then((result) => {
                console.log("Result: ", result);
                console.log("Result: ", JSON.parse(result.data));
                this.props.setTableData(JSON.parse(result.data));
                this.setState({
                    // data: JSON.parse(result.data), 
                    pageCount: Math.ceil(result.totalcount / this.state.itemsPerPage),
                    isLoading: false,
                })
            }).catch((error) => {
                console.log("Error while loading table: ", error);
                this.setState({ isLoading: false });
            });
    }
    onDelete(){

    }
    onEdit(){

    }
    componentDidMount() {
        console.log("API EndPoint: ", this.props.apiEndPoint);
        this.loadAndSetData();
    }
    onNextPage() {
        this.loadAndSetData(null, this.state.pageIndex + 1, this.state.itemsPerPage);
        this.setState({ pageIndex: this.state.pageIndex + 1 })
    }
    onPrevPage() {
        this.loadAndSetData(null, this.state.pageIndex - 1, this.state.itemsPerPage);
        this.setState({ pageIndex: this.state.pageIndex - 1 })
    }
    onIPPChange(value) {
        this.setState({ itemsPerPage: value, pageIndex: 0 });
        this.loadAndSetData(this.state.searchText, 0, value);
    }
    render() {
        return (
            <Paper style={{ height: "100%", display: "flex", flexDirection: "column", margin: this.props.margin && this.props.margin}}>
                <div style={{ flex: 1, maxHeight: 70, flexDirection: "row", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: '#f7fafc', padding: 10, borderTopRightRadius: 4, borderTopLeftRadius: 4 }}>
                    <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                        <TextField size='small' style={{ backgroundColor: "#fff", borderRadius: 4, width: "20%", minWidth: 250 }} onChange={(evt) => { this.searchText(evt.target.value) }} variant='outlined' label="Suche" />
                    </div>
                    <div style={{ flex: 1, display: "flex", justifyContent: "right", alignItems: "center" }}>
                        <Button style={{ height: 40 }}
                            onClick={() => { this.props.addBtnOnClick && this.props.addBtnOnClick() }}
                            variant='outlined'
                            startIcon={this.props.addBtnIcon ? this.props.addBtnIcon : this.props.addBtnLabel && <Add />}>{this.props.addBtnLabel ? this.props.addBtnLabel : this.props.addBtnIcon ? this.props.addBtnIcon : <Add />}</Button>
                    </div>
                </div>
                <TableContainer sx={{ flex: 1, scrollbarWidth: 'none', msOverflowStyle: 'none' }} component={Box}>
                    <Table stickyHeader sx={{ minWidth: 700, }} size='small' aria-label="customized table">
                        <GTTableHeader columns={this.props.config.columns} onEdit={this.props.onEdit} onDelete={this.props.onDelete} activeKey={this.props.activeKey} onSetActive/>
                        <GTTableBody tableData={this.props.tableData} columns={this.props.config.columns} 
                        activeKey={this.props.activeKey} onSetActive={(index, checked) => {this.props.onSetActive(index,checked)}}
                        onEdit={(index, rowId) => {this.props.onEdit(index, rowId)}} onDelete={(index, rowId) => {this.props.onDelete(index,rowId)}}
                        onClick={(index) => {
                            console.log("CLICKED INDEX=",index," DATA: ",this.props.tableData[index]); 
                            // this.props.setSelectedIndex(index); 
                            this.props.onClickRow(index) }}
                        />
                        {/* onClick={() => { this.props.setSelectedIndex(index); this.props.onSelectRow(this.props.tableData[index]); }} */}
                    </Table>
                </TableContainer>
                <div style={{ height:65, borderTop: "0.5px solid #cecece", display: "flex", color: '#676767', justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center", paddingLeft: 20 }}>
                        <Typography >Zeilananzahl:</Typography>
                        <Select onChange={(evt)=> {this.onIPPChange(evt.target.value)}} style={{ marginLeft: 10 }} value={this.state.itemsPerPage} size='small' label="Zeilenanzahl">
                            {itemsPerPageOptions.map((item) => {
                                return <MenuItem key={"ippo_" + item} value={item}>{item}</MenuItem>
                            })}
                        </Select>
                    </div>
                    <div style={{ flex: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <Button onClick={() => { this.onPrevPage() }} disabled={this.state.pageIndex <= 0 || this.state.isLoading}>
                            {'<'}
                        </Button>
                        <span style={{ marginLeft: 10, marginRight: 10 }}>
                            Seite{' '}
                            <strong>
                                {this.state.pageIndex + 1} / {this.state.pageCount}
                            </strong>{' '}
                        </span>
                        <Button onClick={() => { this.onNextPage() }} disabled={this.state.pageIndex + 1 >= this.state.pageCount || this.state.isLoading}>
                            {'>'}
                        </Button>
                    </div>
                    <div style={{ flex: 1 }}>

                    </div>
                </div>
                {this.state.isLoading &&
                    <div style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "50%",
                        backgroundColor: "rgba(0,0,0,0.4)",
                        padding: 10,
                        zIndex: 1, // Ensure it's above other elements
                    }}>
                        <CircularProgress size={25} />
                    </div>
                }
            </Paper>
        );
    }
}
export default GenericTable